import {customElement} from 'lit/decorators.js';
import {ComponentGrid} from '../../../__internal/local/components/component-grid';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {FIRESTORE_COLLECTION_SHOP_PRODUCTS, ShopProductDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-product-description')
class ComponentShopProductDescription extends ComponentGrid {

    @property({type: String})
    productId: string;

    @property({type: Object})
    @computed('productId')
    get product() {
        if (!this.productId) return undefined;

        return new SurrealDocument<ShopProductDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_PRODUCTS}/${this.productId}`],
            {method: FetchMethod.CACHE_FIRST},
        );
    };

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            ul {
                list-style: disc;
            }
        `,
    ];

    @observe('product')
    populateComponents(product?: SurrealDocument<ShopProductDocument>) {
        if (!product) return;

        this.componentExtendedProperties = {
            __product: product.data,
        };

        this.components = product.data?.description || [];
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-product-description': ComponentShopProductDescription;
    }
}