import {customElement} from 'lit/decorators.js';
import {BunnyElement} from './bunny-element.ts';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper.ts';
import {property} from '../helpers/decorators/PropertyDecoratorHelper.ts';
import {Route} from '../../../routing/local/controllers/Route.ts';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper.ts';
import {scss} from '../helpers/StyleHelper';
import {bind} from '../helpers/decorators/BindDecoratorHelper.ts';
import {html} from 'lit';
import {sharedStyles} from '../../../../shared-styles.ts';
import {ComponentFirestoreCollectionList} from './component-firestore-collection-list.ts';

interface Agg {
    value: string;
    label: string;
    count: number;
    field: string;
}

interface AggGroup {
    field: string;
    label: string;
    items: Agg[];
}

@customElement('component-firestore-collection-list-control-aggregations')
class ComponentFirestoreCollectionListControlAggregations extends BunnyElement {

    @property({type: Object})
    aggSpecs: Record<string, { field: string, label: string, comparator: string, comparatorArray: string }> = {};

    @computed('selectedAggs')
    @property({type: Object, notify: true})
    get filters() {
        return Object.fromEntries(
            Object.entries(this.selectedAggs)
                .map(([rawKey, value]) => {
                    let aggFilterSpec = this.aggSpecs[rawKey];
                    let key = aggFilterSpec?.field || rawKey;


                    if (value.length === 1) {
                        if (value[0] === '*') return [key, ['!=', undefined]];

                        return [key, [aggFilterSpec?.comparator || '==', value[0]]];
                    }

                    return [key, [aggFilterSpec?.comparatorArray || 'IN', value]];
                }),
        );
    }

    @property({type: Object})
    selectedAggs: Record<string, string[]> = {};

    @property({type: Object})
    list: ComponentFirestoreCollectionList;

    @property({type: Object})
    aggValues: { [key: string]: string } = {
        '*': 'Yes',
    };

    @property({type: Boolean})
    loadingItems: boolean;

    @property({type: Boolean})
    items: any[] & { _aggs?: Record<string, any> };

    @property({type: Array})
    @((computed as any)('items'))
    get rawAggs(): Agg[] {
        let ret = [];

        let aggs = this.items?._aggs;
        if (aggs) {
            for (let rawAgg in aggs) {
                let aggValue = aggs[rawAgg];
                let isTopLevel = rawAgg.startsWith('^');
                let agg = isTopLevel ? rawAgg.substring(1) : rawAgg;

                let aggLabel = agg.replace(/([a-z])([A-Z0-9])/g, (_m, m1, m2) => `${m1} ${m2.toLowerCase()}`);

                if (Array.isArray(aggValue)) {
                    for (let bucket of aggValue) {
                        ret.push({
                            value: bucket.name,
                            count: bucket.count,
                            label: aggLabel,
                            field: agg,
                        });
                    }

                } else if (typeof aggValue === 'number') {
                    ret.push({
                        value: '*',
                        count: aggValue,
                        label: aggLabel,
                        field: agg,
                    });

                } else {
                    console.error(`Unknown agg handling for ${agg}`, aggValue);
                }
            }
        }


        return ret;
    }

    @property({type: Array})
    excludeAggs: { field: string, value: string }[] = [];

    @property({type: Array})
    @computed('rawAggs')
    get aggs(): Agg[] {
        let flatExcludeAggs = this.excludeAggs.map(_ => `${_.field}:${_.value}`);

        return this.rawAggs.filter(_ => !flatExcludeAggs.includes(`${_.field}:${_.value}`));
    }

    @property({type: Array})
    @computed('aggs')
    get aggGroups() {
        return Object.values(this.aggs.reduce(
            (previousValue, currentValue) => {
                (previousValue[currentValue.label] = previousValue[currentValue.label] || {
                    field: currentValue.field,
                    label: currentValue.label,
                    items: [],
                }).items.push(currentValue);
                return previousValue;
            },
            {} as { [key: string]: AggGroup },
        ));
    }

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Object, notify: true})
    @computed('route')
    get _searchParams() {
        if (!this.route.current?.query) return {};

        return Object.entries(this.route.current?.query)
            .map(_ => {
                let match = _[0].match(/([a-z0-9-_.]+)\[([a-z0-9-_.*]+)\]/i);
                if (!match) return null;

                return {value: _[1], group: match[1], key: match[2]};
            })
            .filter(_ => _ && _.group === 'aggs')
            .reduce((accumulator, value: any) => {
                (accumulator[value.key] = accumulator[value.key] || []).push(value.value);
                return accumulator;
            }, {} as { [key: string]: any });
    }

    validQueryFiltersRoute: string;

    @observe('_searchParams')
    loadSearchParams(_searchParams: any) {
        if (!this.validQueryFiltersRoute) {
            this.validQueryFiltersRoute = this.route.current.path;
        }
        if (this.validQueryFiltersRoute !== this.route.current.path) return;

        if (JSON.stringify(_searchParams) === JSON.stringify(this.selectedAggs)) return;

        this.selectedAggs = _searchParams;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button {
                text-transform: capitalize;
                --padding: 5px 15px;
                min-width: 0;
                background: var(--primary-color);
                margin: 0;

                &[disabled] {
                    background: rgba(128, 128, 128, .4);
                }

                &[active] {
                    background: var(--attention-color);
                    font-weight: normal !important;
                }
            }


            .angledContainer {
                background: var(--primary-text-color);
                color: white;
                padding: 10px 5px;
                box-shadow: 25vw 0 0 var(--primary-text-color), -25vw 0 0 var(--primary-text-color--lighter);
                --angled-indent: -25px;
                padding-bottom: 5px;
            }

            .scrollContainer {
                overflow: auto;
                overflow: overlay !important;
                padding-bottom: 10px;
            }

            .scrollContainer::-webkit-scrollbar {
                height: 3px;
                border-radius: 100%;
                background-color: transparent;
                transition: .125s;
            }

            .scrollContainer:hover::-webkit-scrollbar {
                height: 6px;
                transition: .125s;
            }

            .scrollContainer::-webkit-scrollbar-thumb {
                background: rgba(200, 200, 200, .3);
            }

            .scrollContainer:hover::-webkit-scrollbar-thumb {
                background: var(--attention-color);
            }
        `,
    ];


    override render() {
        let aggGroups = this.aggGroups || [];

        return html`
            <div class="angledContainer forceMobileAngledContainer">
                <div style="display: flex;" class="scrollContainer">
                    ${aggGroups.map(aggGroup => html`
                        <div style="flex-shrink: 0; margin-right: 25px">
                            <h4 style="text-transform: uppercase">
                                ${this.getAggLabel(aggGroup)}
                            </h4>

                            ${aggGroup.items.map(item => html`
                                <a href="${this.generateAggItemLink(aggGroup, item, this.selectedAggs)}">
                                    <component-button raised ?active="${this.isActive(item, this.selectedAggs)}">
                                        ${this.getAggValue(item)}
                                        &middot;
                                        ${item.count}
                                    </component-button>
                                </a>
                            `)}
                        </div>
                    `)}

                    ${this.showPlaceholders(this.loadingItems, aggGroups.length) ? html`
                        <div style="flex-shrink: 0; margin-right: 25px">
                            <h4 style="text-transform: uppercase">
                                Loading
                            </h4>
                            ${new Array(4).fill(html`
                                <component-button disabled style="width: 100px">&nbsp;</component-button>
                            `)}
                        </div>
                    ` : undefined}
                </div>
            </div>
        `;
    }

    showPlaceholders(loadingItems: boolean, aggGroupsLength: number) {
        return loadingItems && !aggGroupsLength;
    }

    getAggLabel(agg: AggGroup) {
        return this.aggSpecs[agg.field]?.label || agg.label;
    }

    getAggValue(agg: Agg) {
        return (this.aggValues[agg.value] || agg.value)
            .replace(/([a-z])([A-Z0-9])/g, (_m, m1, m2) => `${m1} ${m2.toLowerCase()}`);
    }

    isActive(agg: Agg, aggs: any) {
        let fieldAggs = aggs[agg.field];
        if (!fieldAggs) return false;

        if (fieldAggs === agg.value) return true;
        if (fieldAggs.includes(agg.value)) return true;

        return false;
    }

    generateAggItemLink(group: AggGroup, agg: Agg, selectedAggs: any) {
        let urlParts = [];

        if (!this.isActive(agg, selectedAggs)) {
            urlParts.push(`aggs[${group.field}]=${agg.value}`);
        }

        return `?${urlParts.join('&')}#collection-list`;
    }

    connectedCallback() {
        super.connectedCallback();

        this.list.addEventListener('loading-items-changed', this.updateLoadingItemsOnListLoadingItemsChanged);
        this.list.addEventListener('items-changed', this.updateItems);
        this.updateLoadingItemsOnListLoadingItemsChanged();
        this.updateItems();
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.list.removeEventListener('loading-items-changed', this.updateLoadingItemsOnListLoadingItemsChanged);
        this.list.removeEventListener('items-changed', this.updateItems);
    }

    @bind()
    updateLoadingItemsOnListLoadingItemsChanged() {
        this.loadingItems = this.list.loadingItems;
    }

    @bind()
    updateItems() {
        this.items = this.list.items;
    }
}

declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-control-aggregations': ComponentFirestoreCollectionListControlAggregations;
    }
}
