import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {config} from '../../../../config';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {
    CompetitionCharityDocument,
    CompetitionWinnerDocument,
    FIRESTORE_COLLECTION_COMPETITION_CHARITIES,
    FIRESTORE_COLLECTION_COMPETITION_WINNERS,
} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-aspire-comps-charity')
export class ComponentAspireCompsCharity extends BunnyElement {

    @property({type: String})
    charityId: string;

    @property({type: Object})
    @computed('charityId')
    get charity() {
        if (!this.charityId) return undefined;

        return new SurrealDocument<CompetitionCharityDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_COMPETITION_CHARITIES}/${this.charityId}`],
            {method: FetchMethod.CACHE_FIRST},
        );
    }

    @property({type: Object})
    @computed('charity')
    get winners() {
        let suggestedBy = this.charity?.data?.suggestedBy;
        if (!suggestedBy) return undefined;

        return new SurrealCollection<CompetitionWinnerDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                FIRESTORE_COLLECTION_COMPETITION_WINNERS,
                {
                    where: [
                        {fieldPath: 'winnerName', opStr: '==', value: suggestedBy},
                    ],
                    limit: 1,
                },
            ],
            {
                method: FetchMethod.FASTEST_THEN_CLEAN,
            },
        );
        //TODO maybe add this at some point to filter it down, but for now the winner name should be enough
        // {
        //     range: {
        //         donationDate: {
        //             gte: `${winner.drawn.toISOString()}`,
        //             lt: `${winner.drawn.toISOString()}/d+2w`,
        //         },
        //     },
        // },
    }

    @property({type: Object})
    @computed('winners')
    get winner() {
        return this.winners?.data?.[0];
    }

    visibilityObserver: IntersectionObserver;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            h1 {
                background-color: var(--primary-color);
                box-shadow: 25vw 0 0 var(--primary-color), -25vw 0 0 var(--primary-color);
                color: white;
                margin-top: -15px !important;
                line-height: 1.1;
                font-size: 24px;
                font-size: calc(16px + 1vw);
                padding: 10px 35px;
                padding: 10px calc(5px + 2vw);
            }

            component-media-view-image {
                background-color: #f2f2f2;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
                border-radius: 15px;
                overflow: hidden;
            }


            blockquote {
                background: var(--primary-text-color);
                box-shadow: 0 100px 0 var(--primary-text-color);
                margin: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding: 15px 10px !important;
                color: white;
                padding-left: 50px !important;
                padding-bottom: 35px !important;
                position: relative;
                line-height: 1.2;
                margin-bottom: -15px !important;
            }

            blockquote:before {
                color: #ccc;
                content: open-quote;
                font-size: 400%;
                line-height: 1;
                position: absolute;
                top: 15px;
                left: 15px;
            }

            blockquote:after {
                content: '';
                z-index: 1;
                position: absolute;
                top: 100%;
                right: 15%;
                width: 0;
                border-top: 40px solid var(--primary-text-color);
                border-right: 30px solid transparent;
                margin-top: -15px;
                filter: drop-shadow(-1px 6px 3px rgba(255, 255, 255, .6));
            }

            .focusContent.left {
                padding-right: 5%;
                text-align: right;
            }

            .focusContent.right {
                padding-left: 5%;
            }

            .focusContent {
                font-size: 125%;
            }

            .focusContent span {
                font-size: 150%;
                background-color: var(--attention-color);
                color: white;
                border-radius: 8px;
                padding: 2px 20px;
                text-transform: capitalize;
                line-height: 1.3;

                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
            }

            .focusContent span.secondary {
                background-color: var(--primary-color);
            }

            :host(.allowAnimations) .animateIn {
                transition: .375s;
            }

            :host(.allowAnimations) .animateIn.animateInFromLeft:not(.visible) {
                opacity: 0;
                transform: translateX(-25%);
            }

            :host(.allowAnimations) .animateIn.animateInFromRight:not(.visible) {
                opacity: 0;
                transform: translateX(25%);
            }

            :host(.allowAnimations) .animateIn.animateInFromBottom:not(.visible) {
                opacity: 0;
                transform: translateY(25%);
            }
        `];

    override render() {
        let charityData = this.charity?.data;
        let winnerData = this.winner;

        return html`
            <component-loading .loading="${this.charity?.loading ?? true}">
                ${charityData ? html`
                    <div class="gridContainer contentContainer">
                        <h1 style="margin-bottom: 0">
                            Aspire Competitions has donated &pound;${charityData.amountDonated} to the
                            charity ${charityData.charityName} on behalf of ${charityData.suggestedBy}
                        </h1>
                        <ul class="breadcrumbs angledContainer forceMobileAngledContainer">
                            <li class="prev">
                                <a href="/charities-we-support">
                                    Charities
                                </a>
                            </li>
                            <li class="active">
                                <a href="/charities-we-support/${charityData?._ref?.id}">
                                    ${charityData.charityName}
                                </a>
                            </li>
                        </ul>


                        <div style="margin-top: 100px">

                        </div>

                        <div style="--col-md: 6; display: flex; align-items: center" class="focusContent left">
                            <p class="animateIn animateInFromRight">
                                The charity <span>${charityData.charityName}</span> <br>
                                has been nominated by the amazing <br>
                                <a href="/previous-winners/${winnerData?._ref?.id}"><span
                                        class="secondary">${charityData.suggestedBy}</span></a><br>
                                to receive
                                <span>&pound;${charityData.amountDonated}</span>
                                from us at Aspire Competitions as an extra thank you
                            </p>
                        </div>
                        <component-media-view-image type="default-thumbnail" width="400" height="300"
                                                    .media="${charityData.charityImage}"
                                                    style="--col-md: 6; margin-top: auto; margin-bottom: auto"></component-media-view-image>

                        <div style="--col-md: 6">

                        </div>
                        <p style="--col-md: 6; margin-top: 15px" class="animateIn animateInFromBottom">
                            ${charityData.description}

                            ${charityData.charityWebsite ? html`
                                <br>

                                <a href="${charityData.charityWebsite}" class="colorPrimary"
                                   style="word-break: break-all"
                                   target="_blank">
                                    ${charityData.charityWebsite}
                                </a>
                            ` : undefined}
                        </p>


                        <component-loading .loading="${this.winners?.loading ?? true}">
                            ${winnerData ? html`
                                <div>
                                    <div class="gridContainer row">
                                        <div style="margin-top: 100px">

                                        </div>

                                        ${winnerData.winnerQuote ? html`
                                            <blockquote style="--col-md: 6;">
                                                <p style="font-style: italic; white-space: break-spaces;"
                                                   class="animateIn animateInFromBottom">${winnerData.winnerQuote}</p>
                                                <div class="animateIn animateInFromBottom">
                                                    - <strong>${winnerData.winnerName} &middot;
                                                    ${winnerData.location?.city},
                                                    ${winnerData.location?.county}</strong>
                                                </div>
                                            </blockquote>
                                            <div style="--col-md: 6;">

                                            </div>
                                        ` : undefined}

                                        <a href="/previous-winners/${winnerData._ref?.id}"
                                           style="--col-md: 6; margin-top: auto; margin-bottom: auto; padding: 0">
                                            <component-media-view-image type="default-thumbnail" width="400"
                                                                        height="300"
                                                                        .media="${winnerData.winnerImage || winnerData.productImage}"></component-media-view-image>
                                        </a>

                                        <div style="--col-md: 6; display: flex; align-items: center"
                                             class="focusContent right">
                                            <p class="animateIn animateInFromLeft">
                                                And on top of all this <br>
                                                <a href="/previous-winners/${winnerData._ref?.id}"><span>${charityData.suggestedBy}</span></a>
                                                <br>
                                                gets to look awesome with their new <br>
                                                <span class="secondary">${winnerData.productName}</span><br>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ` : undefined}
                        </component-loading>
                    </div>


                    <div style="margin-top: 100px">

                    </div>

                    <div style="text-align: center; margin-top: 25px">
                        Want to support our charities? <br>
                        Every entry to our competitions helps us give back to places like this<br><br>
                        <a href="/shop/products?utm_source=aspire&utm_medium=charityExploreComps&utm_content=Explore our active competitions ending soon">
                            <component-button>
                                Explore our active competitions ending soon
                            </component-button>
                        </a><br>

                        <component-trustpilot-rating
                                style="text-align:center;margin-top:15px"></component-trustpilot-rating>
                    </div>
                ` : undefined}
            </component-loading>


            <div style="margin-top: 100px">

            </div>
        `;
    }


    @observe('charity', 'winners')
    async activateAnimations() {
        if (!('IntersectionObserver' in window)) return;
        await delayPromise();

        if (!this.visibilityObserver) {
            this.classList.add('allowAnimations');
            this.visibilityObserver = new IntersectionObserver((entries) => {
                for (let entry of entries) {
                    if (!entry.intersectionRatio) continue;

                    entry.target.classList.add('visible');
                    this.visibilityObserver.unobserve(entry.target);
                }
            }, {
                root: document.querySelector('#scrollArea'),
                rootMargin: '0px',
                threshold: 1.0,
            });

        } else {
            this.visibilityObserver.disconnect();
        }


        this.shadowRoot?.querySelectorAll('.animateIn:not(.visible)').forEach(_ => {
            this.visibilityObserver.observe(_);
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        if (this.visibilityObserver) {
            this.visibilityObserver.disconnect();
        }
    }

    //TODO remove this and place with proper firestore routing resolvers/preresolvers
    @observe('charity')
    populatePageTitle(charity?: SurrealDocument<CompetitionCharityDocument>) {
        if (!charity?.data) return;

        document.title = [
            `${charity.data.charityName || ''} nominated by ${charity.data.suggestedBy || ''} to receive a £${charity.data.amountDonated || ''} donation from us at Aspire Competitions`,
            'Charities',
            config.title,
        ].filter(_ => _).join(' · ');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-charity': ComponentAspireCompsCharity;
    }
}