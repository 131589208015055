import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {AccountShopContactDetailDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-customer-contact-card-collection')
class ComponentShopCustomerContactCardCollection extends BunnyElement {

    @property({type: Array})
    contacts: AccountShopContactDetailDocument[];

    @property({type: Object, notify: true})
    selectedContact: AccountShopContactDetailDocument;

    @property({type: Boolean})
    viewingOnly: boolean = false;

    @property({type: Boolean})
    @computed('contacts', 'selectedContact')
    get displayCreateNewContact() {
        if (!this.contacts) return false;
        if (!this.contacts.length) return true;
        if (this.selectedContact && !this.selectedContact._ref) return true;

        return false;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .contactDetailsContainer {
                display: flex;
                flex-wrap: wrap;
                margin-left: -15px;
                margin-right: -15px;
            }
        `,
    ];

    override render() {
        return html`
            ${this.contacts?.length ? html`
                <div class="contactDetailsContainer">
                    ${this.contacts.map(item => html`
                        <component-shop-customer-contact-card
                                .contact="${item}"
                                ?active="${item === this.selectedContact}"
                                .viewingOnly="${this.viewingOnly}"
                        ></component-shop-customer-contact-card>
                    `)}

                    ${!this.viewingOnly ? html`
                        <component-shop-customer-contact-card
                                .active="${this.displayCreateNewContact}"
                                @click="${this.scrollNewContactInfoView}"
                        ></component-shop-customer-contact-card>
                    ` : undefined}
                </div>
            ` : undefined}

            ${this.displayCreateNewContact ? html`
                <slot name="createNew"></slot>
            ` : undefined}
        `;
    }

    scrollNewContactInfoView() {
        setTimeout(() => {
            let yOffset = -75;
            let y = this.children[0].getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }, 250);
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-customer-contact-card-collection': ComponentShopCustomerContactCardCollection;
    }
}