// import {prepareFunction} from '../../../__internal/local/helpers/FirebaseFunctionHelper';
import {debouncePromise} from '../../../__internal/shared/helpers/DebounceHelper';
import {Auth} from '../../../auth/local/controllers/Auth.ts';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper.ts';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper.ts';
import {AccountShopContactDetailDocument} from '../../../../utils/DatabaseTypes.ts';

const internalShopCall = async (action: string, args: any) => {
    let auth = Auth.getInstance().getIdToken() as string;
    let response = await (await fetch(`/_/ordercall`, {
        headers: {
            ...(auth ? {'X-Auth': auth as string} : undefined),
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSONStringify({
            action: action,
            args: args,
        }),
    })).json();
    if (response.status === 'error') {
        let responseError = new FriendlyMessage(response._messages[0]);
        (responseError as any)._response = response;
        throw responseError;
    }

    return response;
};


export const shopCall = {
    startCheckout(cartId: string, fallbackOrderId: string, clientMeta?: Record<string, any>) {
        return debouncePromise(`shop-startCheckout-cartId:${cartId}`, async () => {
            return internalShopCall('startCheckout', {
                cartId: cartId,
                fallbackOrderId: fallbackOrderId,
                clientMeta: clientMeta,
            });
        });
    },
    placeOrder(orderId: string, paymentHandler: string, billingContact: AccountShopContactDetailDocument, shippingContact: AccountShopContactDetailDocument) {
        return debouncePromise(`shop-placeOrder-orderId:${orderId}`, async () => {
            return internalShopCall('placeOrder', {
                orderId: orderId,
                paymentHandler: paymentHandler,
                billingContact: billingContact,
                shippingContact: shippingContact,
            });
        });
    },
    attemptOrderCompletion(orderId: string, queryParams: any) {
        return internalShopCall('attemptOrderCompletion', {
            orderId: orderId,
            queryParams: queryParams,
        });
    },
    fbConversion(orderId: string, browserEventSourceUrl: string) {
        return internalShopCall('fbConversion', {
            orderId: orderId,
            browserEventSourceUrl: browserEventSourceUrl,
        });
    },
    cancelDraftOrder(orderId: string) {
        return internalShopCall('cancelDraftOrder', {
            orderId: orderId,
        });
    },
    async calculateLocalTimeOffset() {
        let fetchTimeStart = Date.now();
        let serverTime = (await internalShopCall('getServerTime', {})).serverTime;

        let fetchTimeDuration = Date.now() - fetchTimeStart;
        let fetchTimeDurationHalf = fetchTimeDuration / 2;

        let localTime = Date.now();
        return (serverTime + fetchTimeDurationHalf) - localTime;
    },
    paymentHandlerCustomCallback(orderId: string, transactionId: string, customCallbackSignature: string, customCallback: string, data: any) {
        return debouncePromise(`shop-paymentHandlerCustomCallback-orderId:${orderId}-transactionId:${transactionId}`, async () => {
            return internalShopCall('paymentHandlerCustomCallback', {
                orderId: orderId,
                transactionId: transactionId,
                customCallback: customCallback,
                customCallbackSignature: customCallbackSignature,
                data: data,
            });
        });
    },
};