import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {calculateDocumentPath} from '@lupimedia/firebase-polyfill/src/firestore/helpers/FirestoreHelper.ts';
import {ShopOrderDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-data-collection-list-item-cell-admin-order-transaction-actions')
class ComponentDataCollectionListItemCellAdminOrderTransactionActions extends ComponentFirestoreCollectionListItemCell {

    @property({type: Boolean})
    showRefundDialog: boolean = false;

    @property({type: String})
    refundMessage: string = '';

    @property({type: String})
    refundValue: number = 0.00;

    @property({type: Array})
    refundItems: { name: string, quantity: number, refundQuantity: number, productPrice: number, itemId: string }[];

    @property({type: Object})
    @computed('showRefundDialog', 'item')
    get order() {
        if (!this.showRefundDialog) return undefined;
        if (!this.item._ref.surrealId) return undefined;

        return new SurrealDocument<ShopOrderDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [calculateDocumentPath(this.item._ref.surrealId.id.parent)],
            {method: FetchMethod.CACHE_FIRST},
        );
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${this.item.type === 'payment' && this.item.state === 'completed' ? html`
                <component-button @click="${this.toggleRefundDialog}">
                    Refund
                </component-button>
            ` : undefined}

            ${this.showRefundDialog ? html`
                <component-dialog .opened="${this.bind.showRefundDialog}" style="width: 400px; max-width: 100%;">
                    <h2 slot="heading">
                        Refunding ${this.item._ref.id}
                    </h2>

                    <div>
                        <div>
                            <h3>Products</h3>
                            ${this.refundItems?.map(item => html`
                                <component-input-number
                                        .label="${item.name}"
                                        .min="${0}"
                                        .max="${item.quantity}"
                                        .value="${item.refundQuantity}"
                                        @value-changed="${(e: CustomEvent) => {
                                            if (item.refundQuantity === e.detail.value) return;
                                            item.refundQuantity = e.detail.value;
                                            this.requestUpdate('refundItems');
                                        }}"></component-input-number>
                            `)}
                        </div>

                        <h3>Details</h3>
                        <component-input-currency label="Refund amount" .value="${this.bind.refundValue}"
                                                  min="0"></component-input-currency>
                        <component-input label="Customer message" .value="${this.bind.refundMessage}"></component-input>
                    </div>


                    <component-button slot="footer" @click="${this.refund}">
                        Refund
                    </component-button>
                </component-dialog>
            ` : undefined}
        `;
    }

    @observe('order')
    populateRefundItems(order?: SurrealDocument<ShopOrderDocument>) {
        if (!order?.data) return;

        this.refundItems = Object.entries(order.data.items || {}).map(([index, value]) => ({
            itemId: index,
            quantity: value.quantity,
            productPrice: value.productPrice,
            refundQuantity: 0,
            name: value.name,
        }));
    }

    @observe('refundItems')
    autoUpdateRefundValue() {
        this.refundValue = this.refundItems.reduce((previousValue, currentValue) => previousValue + parseInt(`${currentValue.refundQuantity}`, 10) * currentValue.productPrice, 0);
    }

    toggleRefundDialog() {
        this.showRefundDialog = !this.showRefundDialog;
    }

    @toastProgressWrapper({
        progressMessage: 'Refunding',
        successMessage: 'Refunded',
        failedMessage: 'Failed refunding: {{e}}',
    })
    @confirmationDialog({
        body: function (this: ComponentDataCollectionListItemCellAdminOrderTransactionActions) {
            return `Are you sure you want to refund ${this.refundValue} on the transaction ${this.item.provider}:${this.item.type}`;
        },
    })
    async refund() {
        let itemRef = this.item._ref;
        await adminCall.shopRefundTransaction({
            orderId: itemRef.surrealId.id.parent.id,
            transactionId: itemRef.id,
            value: this.refundValue,
            message: this.refundMessage,
            items: this.refundItems
                .filter(_ => _.refundQuantity)
                .map(_ => ({
                    id: _.itemId,
                    quantity: _.refundQuantity,
                })),
        });

        this.showRefundDialog = false;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-data-collection-list-item-cell-admin-order-transaction-actions': ComponentDataCollectionListItemCellAdminOrderTransactionActions;
    }
}