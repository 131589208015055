import {customElement} from 'lit/decorators.js';
import {ComponentInputSelectMultiple} from './component-input-select-multiple.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {SurrealData} from '../../../__internal/local/controllers/SurrealData.ts';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';
import {ReffableDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-input-surreal-select-multiple')
export class ComponentInputSurrealSelectMultiple extends ComponentInputSelectMultiple {

    @property({type: String})
    namedQuery = '__internal::loadFirestoreCollection';

    @property({type: String})
    path: string;

    @property({type: String})
    fetchMethod: string = 'networkFirst';

    @property({type: Object})
    @computed('namedQuery', 'path', 'fetchMethod')
    get dynamicOptions() {
        return new SurrealCollection<{ name: string } & ReffableDocument>(
            this,
            this.namedQuery,
            [
                this.path,
            ],
            {
                method: SurrealData.resolveFetchMethod(this.fetchMethod),
            },
        );
    }

    @observe('dynamicOptions')
    async populateOptionsFromDynamicOptions(dynamicOptions: SurrealCollection<{ name: string } & ReffableDocument>) {
        //TODO make value property dynamic
        //TODO make name property dynamic
        this.options = dynamicOptions.data?.map(_ => ({
            value: _._ref?.surrealId,
            label: _.name,
        })) || [];

        await delayPromise();
        this.requestUpdate('value');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-surreal-select-multiple': ComponentInputSurrealSelectMultiple;
    }
}