import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {FieldTypeInputLocation} from '../../shared/FirestoreHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {config} from '../../../../config';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {dotPut} from '../../../__internal/shared/helpers/DotHelper';

@customElement('component-input-location')
class ComponentInputLocation extends BunnyElement {

    @property({type: Object, notify: true})
    value?: FieldTypeInputLocation = undefined;

    @property({type: String})
    label: string;

    @property({type: Boolean})
    displayLocation: boolean = false;

    @property({type: Boolean})
    editMode: boolean = false;

    @property({type: Object})
    placeResponse: object;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                padding: 8px 0;
            }

            label {
                display: block;
                font-family: var(--paper-font-caption_-_font-family);
                font-size: var(--paper-font-caption_-_font-size);
                font-weight: var(--paper-font-caption_-_font-weight);
                letter-spacing: var(--paper-font-caption_-_letter-spacing);
                line-height: var(--paper-font-caption_-_line-height);
                color: var(--paper-input-container-color, var(--secondary-text-color));
            }
        `,
    ];

    override render() {
        return html`
            ${this.label ? html`
                <label>
                    ${this.label}
                    <small>
                        <component-button-toggle
                                style="line-height: 1; min-width: 0; margin-left: 15px;"
                                .active="${this.bind.editMode}">
                            Edit
                        </component-button-toggle>
                    </small>
                </label>
            ` : undefined}

            <div>
                ${this.editMode ? html`
                    ${this.displayLocation ? html`
                        <component-input-google-place label="Search"
                                                      placeholder="aa"
                                                      .apiKey="${config.google.maps.key}"
                                                      .place="${this.bind.placeResponse}"></component-input-google-place>

                        <div style="display: flex; margin-left: 15px">
                            <component-input-number label="Lat"
                                                    .value="${this.bindDeep('value.lat')}"
                                                    style="margin-right: 5px"></component-input-number>
                            <component-input-number label="Long"
                                                    .value="${this.bindDeep('value.long')}"></component-input>
                        </div>
                    ` : undefined}
                ` : html`
                    <ul id="locationData" style="list-style: disc; padding-left: 25px; margin-top: 15px">
                        ${this.value?.search ? html`
                            <li>
                                Search: ${this.value.search}
                            </li>
                        ` : undefined}
                        ${this.value?.name ? html`
                            <li>
                                Name: ${this.value.name}
                            </li>
                        ` : undefined}
                        ${this.value?.address ? html`
                            <li>
                                Address: ${this.value.address}
                            </li>
                        ` : undefined}
                        ${this.value?.city ? html`
                            <li>
                                City: ${this.value.city}
                            </li>
                        ` : undefined}
                        ${this.value?.county ? html`
                            <li>
                                County: ${this.value.county}
                            </li>
                        ` : undefined}
                        ${this.value?.postCode ? html`
                            <li>
                                Post code: ${this.value.postCode}
                            </li>
                        ` : undefined}
                        ${this.value?.state ? html`
                            <li>
                                State: ${this.value.state}
                            </li>
                        ` : undefined}
                        ${this.value?.country ? html`
                            <li>
                                Country: ${this.value.country}
                            </li>
                        ` : undefined}
                        ${this.value?.phoneNumber ? html`
                            <li>
                                Phone number: ${this.value.phoneNumber}
                            </li>
                        ` : undefined}
                        ${this.displayLocation ? html`
                            <li>
                                Location: ${this.value?.lat}, ${this.value?.long}
                            </li>
                        ` : undefined}
                    </ul>
                `}
            </div>
        `;
    }

    @observe('editMode')
    async _updateSearchValueOnEditMode(editMode: boolean) {
        if (!editMode) return;
        if (!this.value?.search) return;


        await delayPromise();

        if (!this.value?.search) return;
        let editPlace = this.shadowRoot?.querySelector('component-input-google-place') as any;
        if (!editPlace) return;

        editPlace._value = this.value.search;
    }

    @((observe as any)('value'))
    populateInitialValue(value: any) {
        if (value === undefined) {
            this.value = {
                search: null,
                formattedAddress: null,
                address: null,
                city: null,
                country: null,
                name: null,
                postCode: null,
                route: null,
                county: null,
                phoneNumber: null,
                placeId: null,
                lat: 0,
                long: 0,
            } as FieldTypeInputLocation;

            this.editMode = true;
        }
    }

    @observe('placeResponse')
    placeSelected(placeResponse: any) {
        if (!Object.keys(placeResponse).length) return;

        let administrative_area_level_2 = placeResponse.placeDetails.address_components.filter((_: any) => _.types.indexOf('administrative_area_level_2') !== -1)[0];

        this.placeResponse = {};
        this.editMode = false;
        this.value = this.value || {} as FieldTypeInputLocation;
        this.value.search = placeResponse.search;
        this.value.formattedAddress = placeResponse.formatted_address;
        this.value.address = placeResponse.basic.address;
        this.value.city = placeResponse.basic.city;
        this.value.country = placeResponse.basic.country;
        this.value.name = placeResponse.basic.name;
        this.value.postCode = placeResponse.basic.postalCode;
        this.value.route = placeResponse.basic.route;
        this.value.state = placeResponse.basic.state;
        this.value.county = administrative_area_level_2?.long_name;
        this.value.phoneNumber = placeResponse.basic.phone;
        this.value.placeId = placeResponse.place_id;
        this.value.lat = placeResponse.latLng.lat;
        this.value.long = placeResponse.latLng.lng;
        this.value = {...this.value};
    }

    // @propertyProcessor(['value.lat', 'value.long']) //TODO maybe look into making this
    // return parseInt(value);
    @((observe as any)('value'))
    propertyToInt(value: any) {
        if (!['value.lat', 'value.long'].includes(value.path)) return;
        let newValue = parseInt(value.value, 10);

        if (value.value === newValue) return;


        let valueContainer = {
            value: this.value,
        };
        dotPut(value.path, newValue, valueContainer);
        debugger;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-location': ComponentInputLocation;
    }
}