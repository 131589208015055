import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper';
import {ComponentGoogleMap} from '../../../google/local/components/component-google-map';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
//@ts-ignore
import googleMapsClusterURL from '../../../../../public/js/googleMapsCluster.js?url';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {CompetitionWinnerDocument, FIRESTORE_COLLECTION_COMPETITION_WINNERS} from '../../../../utils/DatabaseTypes.ts';

const EXCLUDED_CATEGORIES = ['Early badger', 'Auto draw'];

let loadMapCusterPromise: any;
const loadMapCluster = (): Promise<any> => {
    if (!loadMapCusterPromise) {
        loadMapCusterPromise = new Promise(async (s) => {
            await loadTrackingLibrary(googleMapsClusterURL);
            s((window as any).MarkerClusterer);
        });
    }

    return loadMapCusterPromise;
};

@customElement('component-aspire-comps-winners-map')
class ComponentAspireCompsWinnersMap extends BunnyElement {

    @property({type: Boolean})
    loadingItems: boolean;

    clusterer: any;

    @property({type: Object})
    params: Record<string, any> = {};

    @property({type: Object})
    @computed('params')
    get items() {
        return new SurrealCollection<CompetitionWinnerDocument>(
            this,
            'aspireCompsCompetitions::winnersMap',
            [
                this.params,
            ],
            {
                method: FetchMethod.FASTEST_THEN_CLEAN,
            },
        );
    }

    @property({type: Object})
    map: any;

    @property({type: Object})
    api: any;

    @property({type: Array})
    markers: any[] = [];

    @property({type: Array})
    moreInfoMarkers: any[] = [];

    @property({type: Object})
    @computed('moreInfoMarkers')
    get moreInfoItems() {
        let moreInfoMarker = this.moreInfoMarkers[0];
        if (!moreInfoMarker) return undefined;

        return new SurrealCollection<CompetitionWinnerDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                FIRESTORE_COLLECTION_COMPETITION_WINNERS,
                {
                    where: [
                        {fieldPath: 'winnerLocation', opStr: '==', value: moreInfoMarker._doc.winnerLocation},
                    ],
                    limit: 999,
                },
            ],
            {
                method: FetchMethod.FASTEST_THEN_CLEAN,
            },
        );
    };

    @property({type: Object})
    @computed('moreInfoItems', 'activeInfoMarkersIndex')
    get activeInfoMarker() {
        let moreInfoItems = this.moreInfoItems?.data;
        if (!moreInfoItems?.length) return undefined;

        return moreInfoItems[this.activeInfoMarkersIndex - 1];
    };

    @property({type: Number})
    activeInfoMarkersIndex: number = 1;

    @property({type: Array})
    mapCenter: number[] = [53.3196524, -2.4183089];

    @property({type: Boolean})
    autoCenterOnFirstResult = false;

    @query('#map')
    mapElement: ComponentGoogleMap;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-aspire-comps-winner-item {
                width: 500px;
                max-width: 100%;
            }

            component-dialog {
                --content-padding: 0;
                --header-display: none;
            }
        `,
    ];

    override render() {
        let activeInfoMarkersIndex = this.activeInfoMarkersIndex;
        let moreInfoItemsCount = this.moreInfoItems?.data?.length ?? 0;

        return html`
            <component-google-map id="map" .map="${this.bind.map}" .api="${this.bind.api}" .maxZoom="${15}"
                                  .center="${this.bind.mapCenter}"
                                  .dynamicCentering="${true}">
                <component-dialog .opened="${!!this.moreInfoMarkers.length}"
                                  @opened-changed="${(_: CustomEvent) => {
                                      if (_.detail.oldValue === true && _.detail.value === false) {
                                          this.closeActiveMarker();
                                      }
                                  }}"
                                  style="width: 400px; max-width: 95vw; top: 50px; top: 5vh; max-height: 500px; max-height: 90vh">
                    <component-button
                            style="position:absolute; top: 0; right: 0; margin: 0; --padding: 10px; min-width: 0; color: white; box-shadow: -25px 0 25px var(--primary-color)"
                            @click="${this.closeActiveMarker}">
                        <component-icon icon="icons:close"></component-icon>
                    </component-button>

                    <component-loading .loading="${this.moreInfoItems?.loading}" style="margin: 0; padding: 0;">
                        ${this.activeInfoMarker ? html`
                            <component-aspire-comps-winner-item
                                    .item="${this.activeInfoMarker}"></component-aspire-comps-winner-item>
                        ` : undefined}
                    </component-loading>

                    ${moreInfoItemsCount > 1 ? html`
                        <div style="text-align: center; font-weight: bolder; width: 100%"
                             slot="footer">
                            <component-button style="--padding: 3px; min-width: 0;"
                                              @click="${(_: MouseEvent) => this.navigateActiveMarker(-1)}"
                                              ?disabled="${activeInfoMarkersIndex === 1}">
                                <component-icon icon="icons:chevron-left"></component-icon>
                            </component-button>

                            <span style="width: 70px; display: inline-block">${activeInfoMarkersIndex} of ${moreInfoItemsCount}</span>

                            <component-button style="--padding: 3px; min-width: 0;"
                                              @click="${(_: MouseEvent) => this.navigateActiveMarker(1)}"
                                              ?disabled="${activeInfoMarkersIndex === moreInfoItemsCount}">
                                <component-icon icon="icons:chevron-right"></component-icon>
                            </component-button>
                        </div>
                    ` : undefined}
                </component-dialog>
            </component-google-map>
        `;
    }

    closeActiveMarker() {
        this.moreInfoMarkers = [];
    }

    navigateActiveMarker(value: number) {
        this.activeInfoMarkersIndex += value;
    }

    @observe('moreInfoMarkers')
    resetActiveInfoMarkersIndex() {
        this.activeInfoMarkersIndex = 1;
    }

    @observe('map', 'api', 'markers')
    async initClustering(map: any, api: any, markers: any[]) {
        if (!map || !api || !markers.length) return;

        let MarkerClusterer = await loadMapCluster();
        let defaultStyle = {
            height: 50,
            width: 50,
            anchorText: [18.5, -11.5],
            className: 'clusterMarker',
            textSize: 14,
            textColor: 'white',
        };
        let clusteringOptions = {
            styles: ['var1-orange', 'var1-green', 'var2-red'].map(image => ({
                url: `/images/winnerMapClusterIcons/cluster-${image}.png`,
                ...defaultStyle,
            })),
            gridSize: window.innerWidth <= 768 ? 30 : 20,
        };
        let clusterer = this.clusterer = new MarkerClusterer(map, markers, clusteringOptions);

        api.event.addListener(clusterer, 'click', (cluster: any) => {
            let markers = cluster.getMarkers();
            let firstPosition = markers[0].position;

            let maxDistance = Math.max(...markers.map((_: any) => Math.hypot(_.position.lng() - firstPosition.lng(), _.position.lat() - firstPosition.lat())));
            if (maxDistance > 0.0005) return;


            this.moreInfoMarkers = markers;

            //prevent zooming
            clusterer.setZoomOnClick(false);
            (window.requestAnimationFrame as any || setTimeout)(() => {
                clusterer.setZoomOnClick(true);
            });
        });
    }

    connectedCallback() {
        super.connectedCallback();

        loadMapCluster();
    }

    private categoryToImage(category: string | (string | undefined)[] | undefined) {
        if (!Array.isArray(category)) {
            category = [category];
        }

        category = category?.find(_ => !EXCLUDED_CATEGORIES.includes(_ as string));

        let filename = category?.toLowerCase().replace(/ ([a-z])/, (_m, m1) => m1.toUpperCase());

        return `/images/winnerMapIcons/${filename || 'default'}.png`;
    }

    @observe('map', 'api', 'items')
    async renderWinners(map: any, api: any, items: SurrealCollection<CompetitionWinnerDocument>) {
        if (!map) return;
        if (!api) return;

        this.markers.forEach(_ => _.setMap(null));

        let markers = [];

        if (items.data) {
            await loadMapCluster();

            let iconSize = new api.Size(30, 30);
            for (let item of items.data) {
                let winnerLocation = item.winnerLocation;
                let marker = new api.Marker({
                    position: winnerLocation ? {
                        lat: +winnerLocation.point[0],
                        lng: +winnerLocation.point[1],
                    } : undefined,
                    map: map,
                    ...(item.productCategory ? {
                        icon: {
                            url: this.categoryToImage(item.productCategory),
                            scaledSize: iconSize,
                            size: iconSize,
                        },
                    } : undefined),
                });
                marker._doc = item;

                api.event.addListener(marker, 'click', () => {
                    this.moreInfoMarkers = [marker];
                });

                markers.push(marker);
            }
        }

        this.markers = markers;
    }

    @observe('autoCenterOnFirstResult', 'markers')
    applyAutoCenterOnFirstResult(autoCenterOnFirstResult: boolean, markers: any[]) {
        if (!autoCenterOnFirstResult) return;
        if (!markers.length) return;

        let markerPosition = markers[0].position;
        this.mapCenter = [markerPosition.lat(), markerPosition.lng()];
        this.mapElement.updateCenter();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-winners-map': ComponentAspireCompsWinnersMap;
    }
}
