import {CONTROL_MAPPING} from '../components/component-page-editor-content-editor';
import {ComponentSpec, NEW_COMPONENTS} from '../components/component-page-editor-content-add';
import {FormElementDocument} from '../../../../utils/DatabaseTypes.ts';

function internalRegisterProperty(_class: string, name: string, spec: Partial<FormElementDocument>) {
    let classObj = CONTROL_MAPPING[_class] = CONTROL_MAPPING[_class] || {};

    classObj[name] = classObj[name] || spec;
}

export function registerEditorElement(spec: ComponentSpec, properties: {
    [key: string]: Partial<FormElementDocument>
} = {}) {
    return (_class_: Object) => {
        NEW_COMPONENTS.push(spec);

        for (let property in properties) {
            internalRegisterProperty((_class_ as any).name, property, properties[property]);
        }
    };
}

export function registerEditorElementProperty(property: Partial<FormElementDocument> | undefined = undefined): any {
    return (_proto: any, _propName: string, descriptor: PropertyDescriptor) => {
        if (property) {
            internalRegisterProperty((_proto.constructor as any).name, _propName, property);
        }

        if (descriptor) return descriptor;


        return undefined;
    };
}