import {customElement} from 'lit/decorators.js';
import {ComponentInputSelect} from './component-input-select.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {SurrealData} from '../../../__internal/local/controllers/SurrealData.ts';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';
import {quickDotGet} from '../../../__internal/shared/helpers/DotHelper.ts';
import {ReffableDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-input-surreal-select')
export class ComponentInputSurrealSelect extends ComponentInputSelect {

    @property({type: String})
    namedQuery = '__internal::loadFirestoreCollection';

    @property({type: String})
    path: string;

    @property({type: Array})
    fetches: string[];

    @property({type: String})
    fetchMethod: string = 'networkFirst';

    @property({type: String})
    valueField = '_ref.surrealId';

    @property({type: Array})
    nameFields: string[] = ['name'];

    @property({type: Object})
    @computed('namedQuery', 'path', 'fetchMethod', 'fetches')
    get dynamicOptions() {
        return new SurrealCollection<{ name: string } & ReffableDocument>(
            this,
            this.namedQuery,
            [
                this.path,
                {
                    fetch: this.fetches,
                },
            ],
            {
                method: SurrealData.resolveFetchMethod(this.fetchMethod),
            },
        );
    }

    @observe('dynamicOptions')
    async populateOptionsFromDynamicOptions(dynamicOptions: SurrealCollection<{ name: string } & ReffableDocument>) {
        const getValue = (value: any, fields: string[]) => {
            return fields.map(field => quickDotGet(field, value))
                .filter(_ => _);
        };

        this.options = dynamicOptions.data?.map(_ => ({
            value: getValue(_, [this.valueField])[0],
            label: getValue(_, this.nameFields)
                .join(' '),
        })) || [];

        await delayPromise();
        this.requestUpdate('value');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-surreal-select': ComponentInputSurrealSelect;
    }
}