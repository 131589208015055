import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {MediaReferenceField} from '../../shared/helpers/FirebaseHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {MediaDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-input-media-file-info')
class ComponentInputMediaFileInfo extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Object})
    value: any;

    @property({type: Object})
    @computed('value')
    get media() {
        let media = this.value?.media;
        if (!media) return undefined;

        return new SurrealDocument<MediaDocument>(this, '__internal::getDoc', [media], {method: FetchMethod.LIVE});
    };

    @property({type: Boolean})
    infoDialogOpened = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                padding: 8px 0;
                word-wrap: break-word;
                display: inline-block;
            }

            .label {
                font-family: var(--paper-font-caption_-_font-family);
                -webkit-font-smoothing: var(--paper-font-caption_-_-webkit-font-smoothing);
                white-space: var(--paper-font-caption_-_white-space);
                overflow: var(--paper-font-caption_-_overflow);
                text-overflow: var(--paper-font-caption_-_text-overflow);
                font-size: var(--paper-font-caption_-_font-size);
                font-weight: var(--paper-font-caption_-_font-weight);
                letter-spacing: var(--paper-font-caption_-_letter-spacing);
                line-height: var(--paper-font-caption_-_line-height);
                margin-bottom: 5px;
            }


            .previewContainer {
                width: 100%;
                border-radius: 3px;
                border: solid rgba(32, 32, 32, .1) 1px;
                display: inline-block;
            }

            .previewContainerInner {
                padding-top: 75%;
                position: relative;
            }

            .previewContainerInner > * {
                position: absolute;
                background-color: rgba(32, 32, 32, .4);
                color: white;
                padding: 3px;
                text-transform: capitalize;
            }
        `,
    ];

    override render() {
        return html`
            ${this.label ? html`
                <div class="label">
                    ${this.label}
                </div>
            ` : undefined}


            ${this.value ? html`
                <component-loading .loading="${this.media?.loading ?? true}">
                    ${this.media?.data ? html`
                        <div class="previewContainer"
                             title="${this.media.data.originalFile}
        Type: ${this.media.data.type}
        Created: ${RenderingHelper._dateFormat(this.media.data.created as Date, 'dd/MM/yyyy HH:mm')}">
                            <div class="previewContainerInner">
                                <div style="top: 50%; left: 5%; right: 5%; transform: translateY(-50%); word-break: break-all; text-align: center">
                                    ${this.media.data.type}
                                </div>

                                <component-media-view-image type="default-thumbnail"
                                                            width="400"
                                                            height="300"
                                                            .media="${this._wrapMediaToReference(this.media.data, this.value)}"
                                                            style="top: 0; left: 0; right: 0; bottom: 0;"></component-media-view-image>


                                ${this.media.data.state !== 'processed' ? html`
                                    <div style="top: 0; left: 0; max-width: 100%;">
                                        ${this.media.data.state}
                                    </div>
                                ` : undefined}
                                ${this.media.data.meta?.name ? html`
                                    <div style="bottom: 0; left: 0; right: 0;">Name: ${this.media.data.meta?.name}</div>
                                ` : undefined}
                                <component-button-toggle .active="${this.bind.infoDialogOpened}"
                                                         style="top: 0; right: 0; width: 15px; height: 15px; line-height: 15px; min-width: 0; margin: 0; --padding: 0">
                                    I
                                </component-button-toggle>

                                <slot></slot>
                            </div>
                        </div>
                    ` : undefined}
                </component-loading>
            ` : html`
                No file found
            `}


            <component-dialog id="infoDialog" .opened="${this.bind.infoDialogOpened}">
                <h2 slot="heading">Info</h2>
                <ul style="list-style: disc; margin-top: 0; padding-left: 25px">
                    ${this.media?.data ? html`
                        ${this.media.data.meta?.name ? html`
                            <li>Name: ${this.media.data.meta?.name}</li>
                        ` : undefined}
                        <li>Original file: ${this.media.data.originalFile}</li>
                        <li>State: ${this.media.data.state}</li>
                        <li>Type: ${this.media.data.type}</li>
                        <li>
                            Created: <span
                                title="${RenderingHelper._dateFormat(this.media.data.created as Date, 'dd/MM/yyyy HH:mm')}">${RenderingHelper._dateFromNow(this.media.data.created as Date)}</span>
                        </li>
                        <li>
                            Variants:<br>
                            ${Object.entries(this.media.data.items).map(([key, item]) => html`
                                <div style="margin-top: 15px">
                                    <a href="${item}">${key}</a>
                                    ${this.media?.data.type === 'Image' ? html`
                                        <br>
                                        <img style="max-width: 160px; max-height: 100px" src="${item}">
                                    ` : undefined}
                                </div>
                            `)}
                        </li>
                    ` : undefined}
                </ul>
            </component-dialog>
        `;
    }

    @observe('value')
    async _valueWaitForMedia(value: any) {
        if (!value) return;
        if (value.media) return;

        if (!value._waitForMedia) return;

        await value._waitForMedia;
        if (this.value !== value) return;

        this.requestUpdate('value');
    }

    _wrapMediaToReference(media: MediaDocument, value: any): MediaReferenceField | undefined {
        if (!media) return;
        if (!value?.media) return;

        return {
            media: value?.media,
            type: media.type,
        };
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-media-file-info': ComponentInputMediaFileInfo;
    }
}