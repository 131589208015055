import {customElement} from 'lit/decorators.js';
import {ComponentShopCheckout} from './component-shop-checkout';
import '../../../auth/local/components/component-login';
import '../../../auth/local/components/component-signup';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {UnshadowStyles} from '../../../__internal/local/controllers/UnshadowStyles';
import {Auth} from '../../../auth/local/controllers/Auth';
import {ComponentShopCartInternal} from './component-shop-cart-internal';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {ShopCartDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-checkout-login')
class ComponentShopCheckoutLogin extends ComponentShopCheckout {

    //@ts-ignore
    private _unshadowStyles = new UnshadowStyles(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    @computed('route')
    get action() {
        return this.route?.current?.query?.action || 'login';
    }

    private _validRenderForPath: string;

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            component-shop-checkout-login {
                component-login, component-signup {
                    max-width: 400px;
                    margin: auto;
                }
            }
        `,
    ];

    override render() {
        if (this._validRenderForPath !== this.route.current.path) return super.render(); //shit fix for component responding to a page before it

        return html`
            ${super.templateBase()}

            <div class="gridContainer" style="margin-top: 50px">
                <div style="--col-md: 6" class="contentContainer">
                    <h1>
                        Just a moment
                    </h1>
                    <p>
                        Hey, to keep things fair for everyone we require you to log in so that we can apply a max number
                        of tickets per person.
                    </p>
                    <p style="margin-top: 25px">
                        ${this.action === 'login' ? html`
                            Don't have an account yet? No problem, we can get you signed up and on your way in a few moments.
                            <a href="/shop/checkout/${this.cartId}/login?action=signup">Register here</a>
                        ` : undefined}

                        ${this.action === 'signup' ? html`
                            Already have an account? Awesome, you can login with your email/password or via a 3rd party service below.
                            <a href="/shop/checkout/${this.cartId}/login?action=login">Login here</a>
                        ` : undefined}
                    </p>
                </div>

                <div style="--col-md: 6;">
                    ${this.action === 'login' ? html`
                        <component-login .returnUrl="/shop/checkout/${this.cartId}"
                                         .signupUrl="/shop/checkout/${this.cartId}/login?action=signup"
                        ></component-login>
                    ` : undefined}

                    ${this.action === 'signup' ? html`
                        <component-signup .returnUrl="/shop/checkout/${this.cartId}"
                                          .loginUrl="/shop/checkout/${this.cartId}/login?action=login"
                                          .termsUrl="${'/terms-conditions'}"
                        ></component-signup>
                    ` : undefined}
                </div>

                <div class="contentContainer">
                    <h3 style="margin-top: 50px">
                        Have questions about logging in or registering?
                    </h3>
                    <p>
                        <a href="/contact-us">
                            Contact us for support
                        </a>
                    </p>
                </div>
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this._validRenderForPath = this.route.current.path; //shit fix for component responding to a page before it
    }

    createRenderRoot() {
        return this;
    }

    loadInitialStep(_step: string, auth: Auth | null, cartId: string, _cart: SurrealDocument<ShopCartDocument> | undefined, _internalCart: ComponentShopCartInternal) {
        if (auth?.user) {
            HistoryHelper.replaceState(`/shop/checkout/${cartId}/your-details`);
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-checkout-login': ComponentShopCheckoutLogin;
    }
}

