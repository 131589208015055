import {customElement} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element.ts';
import {sharedStyles} from '../../../../shared-styles.ts';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';
import {ENV} from '../../../../config.ts';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper.ts';

enum CONNECTION_STATUS {
    PENDING,
    FAILED,
    SUCCESS,
}

const connectionCheck = async (callback: (status: CONNECTION_STATUS, time: number) => void, reportEvery = 500, timeout = 5000) => {
    let start = Date.now();
    let abortTimeoutId = 0;
    let reportIntervalId = 0;

    const reportStatus = (status: CONNECTION_STATUS) => {
        callback(status, Date.now() - start);
    };


    try {
        let abortController = 'AbortController' in window ? new AbortController() : undefined;
        abortTimeoutId = window.setTimeout(() => {
            reportStatus(CONNECTION_STATUS.FAILED);
            if (abortController) {
                abortController.abort();
            }
        }, timeout);
        reportIntervalId = window.setInterval(() => {
            reportStatus(CONNECTION_STATUS.PENDING);
        }, reportEvery);


        try {
            await fetch(ENV === 'local' ? '/' : '/robots.txt', {signal: abortController?.signal as any});
            reportStatus(CONNECTION_STATUS.SUCCESS);

        } catch (e) {
            reportStatus(CONNECTION_STATUS.FAILED);
        }

    } finally {
        clearTimeout(abortTimeoutId);
        clearInterval(reportIntervalId);
    }
};

@customElement('component-routing-error')
class ComponentRoutingError extends BunnyElement {

    @property({type: Object})
    loadingError: Error;

    @property({type: Boolean, reflect: true})
    isOnline: boolean;

    @property({type: Number})
    connectionStatus: CONNECTION_STATUS;

    @property({type: Number})
    connectionTime: number;

    lastConnectionSpeedDurationRender: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
                text-align: center;
            }

            .currentOnlineState span {
                background: var(--attention-color);
                color: white;
                display: inline-block;
                padding: 3px 6px;
                border-radius: 6px;
            }

            :host([is-online]) .currentOnlineState span {
                background: var(--primary-color);
            }
        `,
    ];

    override render() {
        return html`
            <h1 style="text-align: center; line-height: 1">
                Whoops something went wrong
            </h1>

            <p>
                Please check your internet connection and try again
            </p>

            <h2 style="margin-top: 75px">
                Connection status
            </h2>
            <p class="currentOnlineState">
                ${this.rawRenderSpeedLabel(this.connectionStatus, this.connectionTime)} <br><br>
                ${this.renderSpeedDuration(this.connectionStatus, this.connectionTime)}
            </p>

            <component-button @click="${this.doRefresh}">
                Refresh
            </component-button>


            <h2 style="margin-top: 75px">
                Still having issues?
            </h2>
            <p class="contentContainer" style="margin: 0">
                Please don't hesitate to contact us at <a href="mailto:support@aspirecomps.co.uk">support@aspirecomps.co.uk</a>
                <br>
                You can also include the following information to help us resolve this issue for you
            </p>
            <component-input-textarea label="Tech badger things"
                                      .value="${this.loadingError}"
                                      style="width: 300px; display: inline-block"></component-input-textarea>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.startConnectionChecker();
    }


    renderSpeedDuration(connectionStatus: CONNECTION_STATUS, time: number) {
        if (connectionStatus !== CONNECTION_STATUS.PENDING || !this.lastConnectionSpeedDurationRender) {
            this.lastConnectionSpeedDurationRender = this.rawRenderSpeedDuration(connectionStatus, time);
        }

        return this.lastConnectionSpeedDurationRender;
    }

    rawRenderSpeedDuration(connectionStatus: CONNECTION_STATUS, time: number) {
        if (connectionStatus === CONNECTION_STATUS.FAILED) {
            if (time < 50) {
                return html`Check failed after ${time}ms, <br>
                which would indicate you have no internet connection`;
            }

            return html`Check took a long time to fail after ${time}ms, <br>
            which would indicate you have a dodgy internet connection, maybe bad signal where you currently are?`;

        } else if (connectionStatus === CONNECTION_STATUS.SUCCESS) {
            if (time < 150) return html`Your connection seems stable, please refresh and try again`;
            if (time < 1000) return html`Your connection seems ok, please refresh and try again`;
            if (time < 2000) return html`Your connection seems a little slow, please refresh and try again`;

            return html`Your connection seems very slow currently, <br>please refresh and try again,<br>
            or try moving to an area with better signal?`;
        }


        return undefined;
    }

    rawRenderSpeedLabel(connectionStatus: CONNECTION_STATUS, time: number) {
        if (connectionStatus === CONNECTION_STATUS.PENDING) {
            return html`<span>Checking &middot; ${time}ms</span>`;

        } else if (connectionStatus === CONNECTION_STATUS.FAILED) {
            if (time < 50) return html`<span>Offline</span>`;

            return html`<span>Offline</span>`;

        } else if (connectionStatus === CONNECTION_STATUS.SUCCESS) {
            if (time < 150) return html`<span>Online &middot; Fast</span>`;
            if (time < 1000) return html`<span>Online &middot; Ok</span>`;
            if (time < 2000) return html`<span>Online &middot; Slow</span>`;

            return html`<span>Online &middot; Very slow</span>`;
        }


        return undefined;
    }

    async startConnectionChecker() {
        while (this.isConnected) {
            await connectionCheck((status, time) => {
                if (status !== CONNECTION_STATUS.PENDING) {
                    this.isOnline = status === CONNECTION_STATUS.SUCCESS;
                }

                this.connectionStatus = status;
                this.connectionTime = time;
            });

            await delayPromise(2500);
        }
    }

    async doRefresh() {
        await HistoryHelper.refresh();
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-routing-error': ComponentRoutingError;
    }
}