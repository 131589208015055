import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ShopProductPrice} from '../../../shop/shared/helpers/ShopProductPriceHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {ShopProductDocument, ShopProductVariantDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-aspire-comps-product-display')
class ComponentAspireCompsProductDisplay extends BunnyElement {

    @property({type: Object})
    product: ShopProductDocument;

    @property({type: Array})
    productVariants: ShopProductVariantDocument[];

    @property({type: Object})
    selectedVariant: ShopProductVariantDocument;

    @property({type: Object})
    selectedProductVariantStockLockState: any;

    @property({type: Object})
    formData: any;

    @property({type: Object})
    @computed('product')
    get productFinishedDate() {
        return this.product?.finished;
    }

    @property({type: Array})
    quantityOptions: number[] = [1, 5, 10];

    @property({type: Boolean, reflect: true})
    showFreePostalRoute: boolean;

    @property({type: Object})
    @computed('selectedVariant', 'formData', 'product', 'selectedProductVariantStockLockState')
    get productInfo() {
        let selectedProductVariantStockLockState = this.selectedProductVariantStockLockState;
        let price = null;
        let originalPrice = null;
        let isOnSale = false;
        if (this.product && this.selectedVariant && selectedProductVariantStockLockState) {
            let productPrice = new ShopProductPrice(this.product, this.selectedVariant, selectedProductVariantStockLockState);
            price = productPrice.getPrice();
            originalPrice = productPrice.getOriginalPrice();
            isOnSale = productPrice.isSale();
        }


        let stock = selectedProductVariantStockLockState?.stock ?? -1;
        let stockLock = selectedProductVariantStockLockState?.stockLock ?? -1;
        let realStock = selectedProductVariantStockLockState ? stock - stockLock : -1;
        let maxStock = this.selectedVariant?.maxStock ?? -1;
        if (stock > -1 && (stock / maxStock < .1 || realStock < 50)) {
            //when at 10% real stock show the real stock level
            stock = realStock;
        }

        return {
            product: {
                drawDate: this.product.drawDate,
            },
            price: price,
            originalPrice: originalPrice,
            isOnSale: isOnSale,
            quantity: Number(this.formData.quantity),
            maxStock: maxStock,
            maxPerPerson: this.selectedVariant?.maxPerPerson ?? null,
            stock: stock,
            stockLock: stockLock,
        };
    }

    @property({type: Number, reflect: true})
    @computed('productInfo')
    get productPrice() {
        return this.productInfo.price;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host([show-free-postal-route]) .hideOnFreePostalRoute {
                display: none;
            }

            :host([product-price="0"]) .hideOnFreePrice {
                display: none;
            }
        `,
    ];

    override render() {
        if (this.hasFinished(this.productFinishedDate, this.selectedProductVariantStockLockState?.stock)) {
            return html`
                <h3 style="text-align: center; margin-top: 50px; margin-bottom: 50px;">
                    Sales have closed<br>This competition will soon be available on our entry list
                </h3>
                <component-aspire-comps-guaranteed-draw
                        .drawAt="${this.product.drawDate}"></component-aspire-comps-guaranteed-draw>
                <component-trustpilot-rating style="text-align: center"></component-trustpilot-rating>
            `;
        }


        return html`
            <component-aspire-comps-product-countdown-timer
                    .endDate="${this.productFinishedDate}"
                    style="margin-bottom: 40px; margin-left: -15px; margin-right: -15px"></component-aspire-comps-product-countdown-timer>

            <component-shop-price
                    class="hideOnFreePostalRoute hideOnFreePrice"
                    style="text-align: center; color: var(--primary-color); font-weight: bold; font-size: 27px; text-transform: uppercase; margin: 25px auto;"
                    .value="${this.productInfo?.price}"
                    .originalValue="${this.productInfo?.originalPrice}">
                <span slot="prefix">Just</span>
                <span slot="suffix"
                      style="display:inline-block; font-size: 60%; color: var(--secondary-text-color); font-weight: normal; padding: 20px 43px 20px 0; background: url('/images/aspire-star.svg') no-repeat right; background-size: 63px;">per ticket</span>
            </component-shop-price>

            <component-shop-entry-route-type
                    .showFreePostalRoute="${this.bind.showFreePostalRoute}"></component-shop-entry-route-type>

            ${this.isEarlyBadgerPurchasing(this.productInfo?.isOnSale || false, this.product) ? html`
                <div style="text-align: center; margin-top: 30px;" class="hideOnFreePostalRoute">
                    <strong>EARLY BADGER DISCOUNT LIVE</strong>
                    <p style="margin: 0">
                        Enter during this discount for a chance to win ${this.formData.quantity} additional FREE
                            ticket${this.formData.quantity === 1 ? '' : 's'} for the main draw.
                        <component-dialogable-link style="color: var(--primary-color); display: inline-block"
                                                   href="/faq#early-badger">
                            More info
                        </component-dialogable-link>
                    </p>
                </div>
            ` : undefined}

            <div style="text-align: center; margin-top: 30px;" class="hideOnFreePostalRoute">
                <component-aspire-comps-product-info>
                    ${this.productInfo?.maxStock} Tickets available
                </component-aspire-comps-product-info>

                <component-aspire-comps-product-info>
                    ${this.productInfo?.maxPerPerson} max tickets per person
                </component-aspire-comps-product-info>
            </div>

            <component-shop-stock-remaining-bar style="margin-top: 9px"
                                                class="hideOnFreePostalRoute"
                                                label="${this.productInfo?.stock} tickets remaining"
                                                value="${this.productInfo?.stock}"
                                                max="${this.productInfo?.maxStock}"
                                                update-mode="animated"></component-shop-stock-remaining-bar>
            ${this.productInfo?.stock === 0 && this.productInfo?.stockLock !== 0 ? html`
                <p style="text-align: center; font-size: 80%; color: var(--attention-color); margin-top: 5px; margin-bottom: 25px">
                    ${this.finalTicketsCountMessage(this.productInfo.stockLock)},<br>
                    These may become available again if unwanted.
                </p>
            ` : undefined}

            <component-aspire-comps-guaranteed-draw
                    class="hideOnFreePostalRoute"
                    style="margin-top: 15px"
                    .drawAt="${this.product.drawDate}"></component-aspire-comps-guaranteed-draw>

            <component-aspire-comps-product-points class="hideOnFreePostalRoute hideOnFreePrice"
                                                   .productPrice="${this.productInfo?.price}"
                                                   .quantity="${this.formData.quantity}"
                                                   style="margin-bottom: 54px; margin-top: 15px"></component-aspire-comps-product-points>


            <div style="padding: 0 15px 15px;" class="hideOnFreePostalRoute">
                ${this.productVariants.length > 1 ? html`
                    <h3>Styles</h3>
                    <div>
                        ${this.productVariants.map(item => html`
                            <component-button @click="${(_: MouseEvent) => this.selectVariant(item)}"
                                              data-variant-id="${item._ref?.id}">
                                ${item.name} -
                                ${item.price}
                            </component-button>
                        `)}
                    </div>
                    <h3>Selected style</h3>
                ` : undefined}

                ${this.selectedVariant ? html`
                    <div>
                        ${this.productVariants.length > 1 ? html`
                            <h4>${this.selectedVariant.name}</h4>
                        ` : undefined}

                        <div style="overflow: hidden; margin: 25px -15px 15px;">
                            <h3 class="angledContainer"
                                style="text-align: center; background: #505050; color: white;">
                                <span class="unangled"
                                      style="padding: 5px 45px; background: url(/images/aspire-star.svg#white) no-repeat right center; background-size: 45px; display: inline-block;">
                                    TICKET QUANTITY
                                </span>
                            </h3>
                        </div>

                        <component-aspire-comps-shop-purchase-quantity
                                .value="${this.bindDeep('formData.quantity')}"
                                .options="${this.quantityOptions}"
                                .max="${Math.min(this.productInfo.stock, this.productInfo.maxPerPerson as number)}"
                                .maxStock="${this.productInfo.maxStock}"></component-aspire-comps-shop-purchase-quantity>
                    </div>
                    <component-button
                            @click="${this.addToCart}"
                            class="addToCart"
                            style="transform: translateZ(0); display: block; margin: 25px 0 0; background: var(--primary-color); color: var(--secondary-color); text-align: center; font-size: 20px">
                        Add to basket
                    </component-button>
                ` : undefined}
            </div>

            <div style="font-size: 20px; font-weight: bold; text-align: center" class="hideOnFreePostalRoute">
                100% Guaranteed Draw - No Rollovers - No Extensions!
            </div>
            <component-trustpilot-rating class="hideOnFreePostalRoute"
                                         style="text-align: center; margin-top: 15px"></component-trustpilot-rating>
        `;
    }


    hasFinished(productFinishedDate?: Date, stock?: number) {
        if (!productFinishedDate || !stock) return true;

        return productFinishedDate <= new Date() || stock <= 0;
    }

    private _checkSalesEndIntervalId: number;

    disconnectedCallback() {
        super.disconnectedCallback();

        if (this._checkSalesEndIntervalId) {
            clearInterval(this._checkSalesEndIntervalId);
            this._checkSalesEndIntervalId = 0;
        }
    }

    @observe('productFinishedDate', 'selectedProductVariantStockLockState')
    _trackProductEndDate(productFinishedDate: Date, selectedProductVariantStockLockState: any) {
        if (!productFinishedDate) return;
        if (!selectedProductVariantStockLockState) return;


        if (this._checkSalesEndIntervalId) return;
        if (this.hasFinished(productFinishedDate, selectedProductVariantStockLockState.stock)) return;


        this._checkSalesEndIntervalId = window.setInterval(() => {
            if (!this.hasFinished(productFinishedDate, selectedProductVariantStockLockState.stock)) return;

            this.requestUpdate('product');

            clearInterval(this._checkSalesEndIntervalId);
            this._checkSalesEndIntervalId = 0;
        }, 1000);
    }

    @toastProgressWrapper({progressMessage: false, successMessage: false, failedMessage: 'Error: {{e}}'})
    async addToCart() {
        if (!this.selectedVariant) return;


        let formData = {...this.formData};
        let quantity = Number(formData.quantity);
        let productPrice = new ShopProductPrice(this.product, this.selectedVariant, this.selectedProductVariantStockLockState);

        this.dispatchEvent(new CustomEvent('shop-product-add', {
            bubbles: true,
            detail: {
                product: this.product,
                variant: this.selectedVariant,
                quantity: quantity,
                price: productPrice.getPrice() / 100,
                stockLock: this.selectedProductVariantStockLockState,
                isOnSale: productPrice.isSale(),
            },
            composed: true,
        }));
    }

    isEarlyBadgerPurchasing(isOnSale: boolean, product: ShopProductDocument) {
        if (!isOnSale) return false;

        let category = product.category;
        if (!category) return false;

        return !!category.find(_ => _.content === 'Early badger');
    }

    finalTicketsCountMessage(stock: number) {
        if (stock > 1) {
            return `The final ${stock} tickets are being purchased`;

        } else {
            return 'The final ticket is being purchased';
        }
    }

    selectVariant(variant: ShopProductVariantDocument) {
        this.dispatchEvent(new CustomEvent('select-variant', {
            bubbles: true,
            composed: true,
            detail: {
                variant: variant,
            },
        }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-product-display': ComponentAspireCompsProductDisplay;
    }
}
