import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {Route} from '../../../routing/local/controllers/Route';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {ShopPointDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-points-side-bar')
class ComponentShopPointsSideBar extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Boolean, reflect: true})
    showSideBar: boolean;

    @property({type: Boolean})
    showPopup: boolean = false;

    @property({type: Object})
    shopPoints: ShopPointDocument;

    @property({type: Array})
    urlBlacklists: string[] = [];

    @property({type: Object})
    @computed('urlBlacklists')
    get generatedUrlBlacklist() {
        let urlBlacklists = this.urlBlacklists;
        if (!urlBlacklists.length) return null;

        return new RegExp(urlBlacklists.join('|'));
    }

    @property({type: Boolean})
    @computed('route', 'generatedUrlBlacklist')
    get shouldShow() {
        let url = this.route.current.path;

        let blacklist = this.generatedUrlBlacklist;
        if (!blacklist) return true;

        return !blacklist.exec(url);
    }

    private runningSidebarShower: number;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            #sideBar {
                position: fixed;
                bottom: 110px;
                left: 0;
                background: url(/images/aspire-star.svg#white) var(--attention-color) no-repeat right -10px center;
                background-size: 50px;
                color: white;
                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
                padding-right: 45px;
                z-index: 1;
                transition: .3s;
                transform: translateX(-110%);
                opacity: 0;
                margin: 0;
                height: 40px;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                min-width: 0;
                overflow: hidden;
                --padding: 0 9px;
            }

            @keyframes showSideBar {
                0% {
                    transform: translateX(-110%);
                    opacity: 0;
                }
                20%, 90% {
                    transform: translateX(0);
                    opacity: 1;
                }
                100% {
                    transform: translateX(calc(-100% + 40px));
                    opacity: 1;
                }
            }

            @media screen and (min-height: 500px) {
                :host([show-side-bar]) #sideBar {
                    animation: showSideBar 4s;
                    animation-fill-mode: forwards;
                }
            }

            #sideBar:before {
                content: attr(data-points);
                position: absolute;
                display: block;
                top: 0;
                right: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                text-align: center;
                font-size: 12px;
                background: rgba(229, 111, 4, .5);
                font-weight: bold;
                text-shadow: 0 0 3px var(--attention-color), 0 0 6px var(--attention-color), 0 0 9px var(--attention-color), 0 0 12px var(--attention-color), 0 0 15px var(--attention-color)
            }


            component-shop-points-panel {
                overflow: auto;
                padding: 0;
                max-height: 400px;
                max-height: 80vh;
                max-width: 600px;
                margin-top: 0;
            }

            component-dialog {
                --content-padding: 0;
                --footer-display: none;
            }
        `,
    ];

    override render() {
        return html`
            <component-button-toggle id="sideBar" .active="${this.bind.showPopup}"
                                     data-points="${this.toShortHumanReadableNumber(this.shopPoints?.points || 0)}">
                Aspire points
            </component-button-toggle>

            <component-dialog .opened="${this.bind.showPopup}" with-backdrop>
                <h2 slot="heading">Aspire points</h2>

                <component-shop-points-panel .shopPoints="${this.bind.shopPoints}" id="panel"
                                             @link-clicked="${this.closePopup}"></component-shop-points-panel>
            </component-dialog>
        `;
    }

    closePopup() {
        this.showPopup = false;
    }

    @observe('shouldShow')
    testUrlForShowing() {
        let shouldShow = this.shouldShow;
        if (shouldShow === this.showSideBar) return;


        clearTimeout(this.runningSidebarShower);
        if (shouldShow) {
            this.runningSidebarShower = window.setTimeout(() => {
                this.showSideBar = true;
            }, 2000);

        } else {
            this.showSideBar = false;
        }
    }

    toShortHumanReadableNumber(value: number) {
        value ??= 0;
        let char = '';

        if (value >= 1000000) {
            value /= 1000000;
            char = 'M';

        } else if (value >= 1000) {
            value /= 1000;
            char = 'K';
        }

        return value.toFixed(1).replace('.0', '') + char;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-points-side-bar': ComponentShopPointsSideBar;
    }
}