import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {html} from 'lit';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';
import {NotificationCampaignDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-firestore-collection-list-item-cell-notification-campaign-stats')
class ComponentFirestoreCollectionListItemCellNotificationCampaignStats extends ComponentFirestoreCollectionListItemCell {

    private updateRenderTimeoutId? = 0;

    static override styles = [
        ...ComponentFirestoreCollectionListItemCell.styles,
        // language=SCSS
        scss`
            component-progress {
                display: flex;
                padding: 3px;
                margin: 1px;

                span {
                    margin-left: auto
                }
            }
        `,
    ];

    override render() {
        let item = (this.item as NotificationCampaignDocument);
        let stats = item.stats;
        let statsTotal = stats?.total ?? 0;
        let sent = this.getSentCount(item.createAt as Date, item?.sending?.lastScheduledSend, statsTotal || 1);
        if (item.state === 'complete') {
            sent = statsTotal;
        }

        return html`
            ${item.state === 'creating' ? html`
                lastProcessedRecord: ${JSON.stringify(item.sending?.lastProcessedRecord)} <br>
            ` : undefined}
            <component-progress .max="${statsTotal}"
                                .value="${sent}" style="display: flex">
                <strong>${item.state === 'complete' ? 'Sent' : 'Sending'}</strong>
                <span>${sent} / ${statsTotal}</span>
            </component-progress>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.doUpdateRenderSchedule();
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        delete this.updateRenderTimeoutId;
        clearTimeout(this.updateRenderTimeoutId);
    }

    @observe('item')
    private doUpdateRenderSchedule() {
        let lastScheduledSend = this.item.stats?.lastScheduledSend;
        if (!lastScheduledSend) return;
        if (lastScheduledSend.getTime() < Date.now()) return;
        if (this.updateRenderTimeoutId) return;

        this.updateRenderTimeoutId = window.setTimeout(() => {
            delete this.updateRenderTimeoutId;
            this.requestUpdate('item');
            this.doUpdateRenderSchedule();
        }, 2000);
    }

    getSentCount(createAt: Date, lastScheduledSend?: Date, total?: number) {
        if (!lastScheduledSend) return 0;


        let start = createAt.getTime();
        let now = Date.now();
        let end = lastScheduledSend.getTime();

        let progress = (now - start) / (end - start);
        if (progress > 1) {
            progress = 1;
        }

        return Math.ceil((total || 0) * progress);
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list-item-cell-notification-campaign-stats': ComponentFirestoreCollectionListItemCellNotificationCampaignStats;
    }
}