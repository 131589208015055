import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {ComponentShopCartInternal} from './component-shop-cart-internal';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {FIRESTORE_COLLECTION_SHOP_PRODUCTS, ShopCartDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-cart')
class ComponentShopCart extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Object})
    cart: ShopCartDocument;

    @property({type: Array})
    @computed('cart')
    get cartItems() {
        return Object.values(this.cart?.items || {})
            .sort((a, b) => {
                return a.created.getTime() - b.created.getTime();
            });
    };

    @property({type: Object})
    shopCartInternal = ComponentShopCartInternal.getInstance();

    @property({type: Array})
    get itemsFields() {
        return [
            {
                header: false,
                cell: {
                    component: 'component-data-collection-list-item-cell-cart-item',
                },
            },
        ];
    }

    @property({type: Boolean})
    pendingCartUpdate: boolean = false;

    @property({type: Object})
    @computed('cartItems')
    get youMayAlsoLikeFilters() {
        if (!this.cartItems || !this.cartItems.length) return undefined;

        return {
            _crossSale: ['IN', this.cartItems.map(_ => _.product)],
        };
    }

    @property({type: Array})
    get youMayAlsoLikeFields() {
        return [
            {
                cellProperties: {
                    style: '--col-sm: 6; --col-lg: 4;',
                    field: 'name',
                    type: 'text',
                },
                header: false,
                cell: {
                    component: 'component-shop-collection-list-item',
                    properties: {
                        href: '/shop/products/',
                    },
                },
            },
        ];
    }

    private __isCheckingRedirectOnEmptyCart = false;

    @property({type: Array})
    youMayAlsoLikeItems: any[] = [];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                padding: 0 !important;
            }

            .checkoutPanelWeAccept {
                text-align: center;
                padding: 25px 5px;
            }

            .checkoutPanelWeAccept img {
                height: 40px;
                width: 63px;
                box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);
                border-radius: 3px;
            }

            h2, h3 {
                background-color: var(--primary-text-color);
                color: white;
                margin-left: -15px;
                margin-right: -15px;
                margin-top: -25px !important;
                padding: 15px;
                text-align: center;
            }


            .checkoutPanelWeAccept h3 {
                margin-left: -5px;
                margin-right: -5px;
                padding: 7px;
            }

            [data-processing="1"] {
                position: relative;
            }

            [data-processing="1"]:after {
                content: 'Updating...';
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(255, 255, 255, .85);
                display: flex;
                align-items: center;
                justify-items: center;
                text-align: center;
                flex-direction: column;
                font-size: 20px;
                text-transform: uppercase;
                color: black;
                text-shadow: 0 0 4px white, 0 0 5px white, 0 0 6px white;
                line-height: 58px;
            }

            .youMayAlsoLikeSection {
                display: none;
            }

            .youMayAlsoLikeSection[data-visible="1"] {
                display: block;
            }

            @media screen and (max-width: 1024px) {
                .desktopOnly {
                    display: none !important;
                }
            }
        `,
    ];

    override render() {
        return html`
            <div class="gridContainer">
                <div style="--col-md: 9; ">
                    <div class="contentStep" style="margin-bottom: 1em; padding: 0; padding-top: 15px">
                        <component-firestore-collection-list .items="${this.cartItems || []}"
                                                             .fields="${this.itemsFields}"
                                                             fetch-method="live"
                                                             data-processing="${this.pendingCartUpdate ? 1 : 0}">
                        </component-firestore-collection-list>
                    </div>

                    <div style="text-align: right; margin-bottom: 1em">
                        <a href="/contact-us">
                            <small>
                                Need help?
                            </small>
                        </a>
                    </div>

                    <div class="contentStep youMayAlsoLikeSection desktopOnly" style="margin-top: 50px; padding: 0;"
                         data-visible="${this.youMayAlsoLikeItems.length ? 1 : 0}">
                        <h3 style="margin: 0; margin-bottom: 15px">You may also like</h3>

                        ${this.youMayAlsoLikeFilters ? html`
                            <component-firestore-collection-list .path="${FIRESTORE_COLLECTION_SHOP_PRODUCTS}"
                                                                 .namedQuery="${'shop::getCrossSaleProducts'}"
                                                                 .filters="${this.youMayAlsoLikeFilters}"
                                                                 .fields="${this.youMayAlsoLikeFields}"
                                                                 .items="${this.bind.youMayAlsoLikeItems}">
                            </component-firestore-collection-list>
                        ` : undefined}
                    </div>
                </div>

                <div style="--col-md: 3">
                    <div class="contentStep"
                         data-processing="${this.pendingCartUpdate ? 1 : 0}">
                        <h2>Order summary</h2>
                        <component-shop-price
                                style="font-size: 2em; color: var(--primary-color); display: flex; align-items: center"
                                value="${this.cart?.price?.subTotal}">
                            <span slot="prefix" style="color: var(--primary-text-color); font-size: 0.5em; flex: 1">Subtotal: </span>
                        </component-shop-price>
                        ${this.cart?.discounts?.length ? html`
                            Discounts:
                            <ul style="margin-top: 5px; margin-bottom: 5px; padding-left: 0px">
                                ${this.cart.discounts.map(item => html`
                                    <li>
                                        <component-shop-price
                                                style="display: flex; align-items: center"
                                                value="${item.value}">
                                            <span slot="prefix" style="flex: 1">
                                                <component-button @click="${this.removeDiscount}"
                                                                  .discount="${item.discount}"
                                                                  style="width: 25px; height: 25px; --padding: 0; vertical-align: top; min-width: 0; background: none; color: var(--primary-text-color); line-height: 1;"
                                                                  .raised="${false}">
                                                    <component-icon icon="icons:close"
                                                                    style="--icon-width: 19px; --icon-height: 19px;"></component-icon>
                                                </component-button>
                                                ${item.name} &middot; 
                                            </span>
                                        </component-shop-price>
                                    </li>
                                `)}
                            </ul>
                        ` : undefined}
                        <component-shop-price
                                style="font-size: 2em; color: var(--primary-color); display: flex; align-items: center; font-weight: bolder"
                                value="${this.cart?.price?.total}">
                            <span slot="prefix" style="color: var(--primary-text-color); font-size: 0.5em; flex: 1">Total: </span>
                        </component-shop-price>

                        <div style="margin-top: 15px; line-height: 1.2;">
                            <small>Stock will only be reserved when you complete the order</small>
                        </div>
                        <a href="/shop/checkout/${this.cart?._ref?.id}">
                            <component-button
                                    style="background-color: var(--primary-color); display: block; text-align: center; color: white; margin: 25px -15px -25px -15px; font-size: 20px"
                                    ?disabled="${!this.cart}">
                                Checkout
                            </component-button>
                        </a>
                    </div>

                    <div class="contentStep checkoutPanelWeAccept">
                        <h3>
                            <small>We accept</small>
                        </h3>
                        <img src="/images/payment-logo--visa.svg" alt="Visa">
                        <img src="/images/payment-logo--mastercard.svg" alt="Mastercard">
                        <img src="/images/payment-logo--maestro.svg" alt="Maestro">
                        <img src="/images/payment-logo--amex.svg" alt="Amex" style="background-color: #0079c1">
                        <!--                    <img src="/images/payment-logo&#45;&#45;google-pay.svg" alt="Google Pay">-->
                        <img src="/images/payment-logo--apple-pay.svg" alt="Apple Pay">
                    </div>

                    <div class="contentStep" style="padding: 15px">
                        <component-trustpilot-rating
                                style="width: 130px; margin: auto; text-align: center"></component-trustpilot-rating>
                    </div>
                </div>
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.shopCartInternal?.addEventListener('pending-cart-update-changed', this.reflectPendingCartUpdated);
        this.shopCartInternal?.addEventListener('cart-changed', this.reflectCart);
        this.pendingCartUpdate = this.shopCartInternal.pendingCartUpdate;
        this.cart = this.shopCartInternal.cart?.data as ShopCartDocument;

        if (!this.cart && !this.shopCartInternal.pendingCartUpdate) {
            this.redirectOnEmptyCart(this.cart);
        }
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        this.shopCartInternal?.removeEventListener('pending-cart-update-changed', this.reflectPendingCartUpdated);
        this.shopCartInternal?.removeEventListener('cart-changed', this.reflectCart);
    }

    @bind()
    reflectPendingCartUpdated() {
        this.pendingCartUpdate = this.shopCartInternal.pendingCartUpdate;
    }

    @bind()
    reflectCart() {
        this.cart = this.shopCartInternal.cart?.data as ShopCartDocument;
    }

    removeDiscount(e: MouseEvent) {
        let discount = (e.currentTarget as any).discount;

        this.dispatchEvent(new CustomEvent('shop-discount-remove', {
            bubbles: true,
            detail: {discount: discount},
            composed: true,
        }));
    }

    @observe('cart')
    async redirectOnEmptyCart(cart: ShopCartDocument) {
        //TODO no idea why the cart is true
        if (cart as any === true) return;


        try {
            if (this.__isCheckingRedirectOnEmptyCart) {
                console.warn('[ShopCart] already checking if it needs to redirect');
                return;
            }

            this.__isCheckingRedirectOnEmptyCart = true;

            if (cart && cart.items && Object.keys(cart.items).length) return;


            await this.shopCartInternal.waitForPendingModifies();
            await delayPromise(100);

            cart = this.shopCartInternal.cart?.data as ShopCartDocument;
            if (cart && cart.items && Object.keys(cart.items).length) return;


            showToast('Your cart is empty, browse what we have for you');
            HistoryHelper.pushState('/shop/products');

        } finally {
            this.__isCheckingRedirectOnEmptyCart = false;
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-cart': ComponentShopCart;
    }
}
