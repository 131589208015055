import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {config} from '../../../../config';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {
    CompetitionCharityDocument,
    CompetitionWinnerDocument,
    FIRESTORE_COLLECTION_COMPETITION_CHARITIES,
    FIRESTORE_COLLECTION_COMPETITION_WINNERS,
    FIRESTORE_COLLECTION_SHOP_PRODUCTS,
} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-aspire-comps-winner')
export class ComponentAspireCompsWinner extends BunnyElement {

    @property({type: String})
    winnerId: string;

    @property({type: Object})
    @computed('winnerId')
    get winner() {
        if (!this.winnerId) return undefined;

        return new SurrealDocument<CompetitionWinnerDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [
                `${FIRESTORE_COLLECTION_COMPETITION_WINNERS}/${this.winnerId}`,
                {
                    fetch: ['productCategory'],
                },
            ],
            {method: FetchMethod.CACHE_FIRST},
        );
    }

    @property({type: Boolean})
    winnerLoading = true;

    @property({type: String})
    @computed('winner')
    get cleanedProductName() {
        return this.winner?.data?.productName?.replace(/ ?-? ?\d{1,2}(st|nd|rd|th) [a-z]+/i, '') || '';
    }

    @property({type: String})
    @computed('cleanedProductName')
    get condensedProductName() {
        let productNameParts = this.cleanedProductName.split(' - ')
            .sort((a, b) => b.length - a.length);

        return productNameParts[0];
    }

    @property({type: Object})
    @computed('winner')
    get charities() {
        let winnerName = this.winner?.data?.winnerName;
        if (!winnerName) return undefined;

        return new SurrealCollection<CompetitionCharityDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                FIRESTORE_COLLECTION_COMPETITION_CHARITIES,
                {
                    aggs: ['^count', '^amountDonated'],
                    where: [
                        {fieldPath: 'suggestedBy', opStr: '==', value: winnerName},
                    ],
                    limit: 1,
                },
            ],
            {
                method: FetchMethod.FASTEST_THEN_CLEAN,
            },
        );
        //TODO maybe add this at some point to filter it down, but for now the winner name should be enough
        // {
        //     range: {
        //         donationDate: {
        //             gte: `${winner.drawn.toISOString()}`,
        //             lt: `${winner.drawn.toISOString()}/d+2w`,
        //         },
        //     },
        // },
    }

    @property({type: Object})
    @computed('charities')
    get charity() {
        return this.charities?.data?.[0];
    }

    @property({type: Number})
    @computed('charities')
    get charityTotalDonated() {
        return this.charities?.data?._aggs?.['^amountDonated'];
    }

    @property({type: Number})
    @computed('charities')
    get charityTotalCount() {
        return this.charities?.data?._aggs?.['^count'];
    }

    @property({type: Array})
    upsaleProductFields = [
        {
            cellProperties: {
                style: '--col-sm: 6; --col-lg: 4;',
                field: 'name',
                type: 'text',
            },
            header: false,
            cell: {
                component: 'component-shop-collection-list-item',
                properties: {
                    href: '/shop/products/',
                },
            },
        },
    ];

    @property({type: Object})
    @computed('winner')
    get upsaleProductFilters() {
        let productCategory = this.winner?.data?.productCategory;
        if (!productCategory) return undefined;

        productCategory = productCategory.filter(_ => !['Early badger', 'Auto draw'].includes(_.name));
        if (!productCategory.length) return undefined;

        return [
            {
                fieldPath: 'productCategory.name',
                opStr: 'CONTAINSANY',
                value: productCategory.map(_ => _._ref.surrealId),
            },
        ];
    }

    visibilityObserver: IntersectionObserver;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            h1 {
                background-color: var(--primary-color);
                box-shadow: 25vw 0 0 var(--primary-color), -25vw 0 0 var(--primary-color);
                color: white;
                margin-top: -15px !important;
                line-height: 1.1;
                font-size: 24px;
                font-size: calc(16px + 1vw);
                padding: 10px 35px;
                padding: 10px calc(5px + 2vw);
            }

            component-media-view-image {
                background-color: #f2f2f2;
                box-shadow: 0 1px 0 0 rgba(142, 146, 135, .3);
                border-radius: 15px;
                overflow: hidden;
            }

            blockquote {
                background: var(--primary-text-color);
                box-shadow: 0 100px 0 var(--primary-text-color);
                margin: 0;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                padding: 15px 10px !important;
                color: white;
                padding-left: 50px !important;
                padding-bottom: 35px !important;
                position: relative;
                line-height: 1.2;
                margin-bottom: -15px !important;
            }

            blockquote:before {
                color: #ccc;
                content: open-quote;
                font-size: 400%;
                line-height: 1;
                position: absolute;
                top: 15px;
                left: 15px;
            }

            blockquote:after {
                content: '';
                z-index: 1;
                position: absolute;
                top: 100%;
                right: 15%;
                width: 0;
                border-top: 40px solid var(--primary-text-color);
                border-right: 30px solid transparent;
                margin-top: -15px;
            }

            .focusContent.left {
                padding-right: 5%;
                text-align: right;
            }

            .focusContent.right {
                padding-left: 5%;
            }

            .focusContent {
                font-size: 125%;
            }

            .focusContent span {
                font-size: 150%;
                background-color: var(--attention-color);
                color: white;
                border-radius: 8px;
                padding: 2px 20px;
                text-transform: capitalize;
                line-height: 1.4;

                box-decoration-break: clone;
                -webkit-box-decoration-break: clone;
            }

            .focusContent span.secondary {
                background-color: var(--primary-color);
            }

            :host(.allowAnimations) .animateIn {
                transition: .375s;
            }

            :host(.allowAnimations) .animateIn.animateInFromLeft:not(.visible) {
                opacity: 0;
                transform: translateX(-25%);
            }

            :host(.allowAnimations) .animateIn.animateInFromRight:not(.visible) {
                opacity: 0;
                transform: translateX(25%);
            }

            :host(.allowAnimations) .animateIn.animateInFromBottom:not(.visible) {
                opacity: 0;
                transform: translateY(25%);
            }
        `,
    ];

    override render() {
        let winnerData = this.winner?.data;
        let charityData = this.charity;

        return html`
            <component-loading .loading="${this.winner?.loading ?? true}">
                ${winnerData ? html`
                    <div class="gridContainer contentContainer">
                        <h1 style="margin-bottom: 0">${winnerData.winnerName} won the
                            ${this.cleanedProductName}</h1>
                        <ul class="breadcrumbs angledContainer forceMobileAngledContainer">
                            <li class="prev">
                                <a href="/previous-winners">
                                    Winners
                                </a>
                            </li>
                            <li class="active">
                                <a href="/previous-winners/${winnerData._ref?.id}">
                                    ${winnerData.winnerName}
                                </a>
                            </li>
                        </ul>

                        <div style="display: flex; flex-wrap: wrap; background-color: rgb(249, 249, 249); box-shadow: rgb(249, 249, 249) -50vw 0 0, rgb(249, 249, 249) 50vw 0 0; padding-top: 50px; padding-bottom: 50px;">
                            <p style="font-size: 125%; margin-right: 50px; min-width: 300px; flex: 1">
                                Congratulations <strong>${winnerData.winnerName}</strong>, <br>
                                the winner of the ${this.condensedProductName} <br>
                                which was drawn on
                                <strong>${RenderingHelper._dateFormat(winnerData.drawn, 'dd/MM/yyyy')}</strong>.
                                <br>
                                And all it took was 1 lucky winning ticket.
                                <br><br>

                                ${winnerData.watchLiveLink ? html`
                                    <a href="${winnerData.watchLiveLink}">
                                        Watch the draw when ${winnerData.winnerName} won their prize, and
                                        hopefully they picked up!
                                    </a>
                                ` : undefined}
                            </p>
                            <component-aspire-comps-tickets-renderer display-winner
                                                                     .items="${this.generateWinningTickets(winnerData)}"
                                                                     style="margin: auto"></component-aspire-comps-tickets-renderer>
                        </div>

                        <div style="margin-top: 100px">
                        </div>

                        <div style="--col-md: 6; display: flex; align-items: center" class="focusContent left">
                            <p class="animateIn animateInFromRight">
                                The awesome <br>
                                <span>${this.cleanedProductName}</span> <br>
                                that was won by <br>
                                <span class="secondary">${winnerData.winnerName}</span><br>
                            </p>
                        </div>
                        <component-media-view-image type="default-thumbnail" width="400" height="300"
                                                    .media="${winnerData.productImage}"
                                                    style="--col-md: 6; margin-top: auto; margin-bottom: auto"></component-media-view-image>

                        <div style="margin-top: 100px">

                        </div>

                        ${winnerData.winnerImage ? html`
                            ${winnerData.winnerQuote ? html`
                                <blockquote style="--col-md: 6;">
                                    <p style="font-style: italic; white-space: break-spaces;"
                                       class="animateIn animateInFromBottom">${winnerData.winnerQuote}</p>
                                    <div class="animateIn animateInFromBottom">
                                        - <strong>${winnerData.winnerName} &middot;
                                        ${winnerData?.location?.city},
                                        ${winnerData?.location?.county}</strong>
                                    </div>
                                </blockquote>
                                <div style="--col-md: 6;">

                                </div>
                            ` : undefined}
                            <component-media-view-image type="default-thumbnail" width="400" height="300"
                                                        .media="${winnerData.winnerImage}"
                                                        style="--col-md: 6; margin-top: auto; margin-bottom: auto;">
                            </component-media-view-image>
                            <div style="--col-md: 6; display: flex; align-items: center" class="focusContent right">
                                <p class="animateIn animateInFromLeft">
                                    All the best to <br>
                                    <span>${winnerData.winnerName}</span> <br>
                                    with their new <br>
                                    <span class="secondary">${this.cleanedProductName}</span>
                                </p>
                            </div>
                        ` : undefined}


                        <div style="margin-top: 100px">

                        </div>


                        <div style="text-align: center; background-color: rgb(249, 249, 249); box-shadow: rgb(249, 249, 249) -50vw 0px 0px, rgb(249, 249, 249) 50vw 0px 0px; padding-top: 50px; padding-bottom: 50px;">
                            <h3>
                                This could be you next week!
                            </h3>
                            <p>
                                Browse our selection of competitions and try your luck for a win.
                            </p>
                            <a href="/shop/products?utm_source=aspire&utm_medium=winnerExploreProducts&utm_content=Check out our latest competitions being drawn soon">
                                <component-button>
                                    Check out our latest competitions being drawn soon
                                </component-button>
                            </a> <br>

                            <component-trustpilot-rating
                                    style="text-align:center;margin-top:15px"></component-trustpilot-rating>
                        </div>


                        <component-loading .loading="${this.charities?.loading ?? true}">
                            ${charityData ? html`
                                <div>
                                    <div class="gridContainer row">
                                        <div style="margin-top: 100px">

                                        </div>

                                        <div style="--col-md: 6; display: flex; align-items: center"
                                             class="focusContent left">
                                            <p class="animateIn animateInFromRight">
                                                And on top of all that ${winnerData.winnerName} nominated the
                                                charity
                                                <a href="/charities-we-support/${charityData._ref?.id}">
                                                    <span>${charityData.charityName}</span>
                                                </a>
                                                to receive
                                                <span class="secondary">&pound;${charityData.amountDonated}</span>
                                                as an extra thank you from us.
                                            </p>
                                        </div>

                                        <a href="/charities-we-support/${charityData._ref?.id}"
                                           style="--col-md: 6; margin-top: auto; margin-bottom: auto; padding: 0">
                                            <component-media-view-image type="default-thumbnail" width="400"
                                                                        height="300"
                                                                        .media="${charityData.charityImage}"></component-media-view-image>
                                        </a>


                                        <div style="--col-md: 6">

                                        </div>
                                        <p style="--col-md: 6; margin-top: 15px" class="animateIn animateInFromBottom">
                                            ${charityData.description}

                                            ${charityData.charityWebsite ? html`
                                                <br>

                                                <a href="${charityData.charityWebsite}" class="colorPrimary"
                                                   style="word-break: break-all"
                                                   target="_blank">
                                                    ${charityData.charityWebsite}
                                                </a>
                                            ` : undefined}
                                        </p>
                                    </div>
                                </div>
                            ` : undefined}
                        </component-loading>


                        <div style="margin-top: 100px">

                        </div>


                        <p style="text-align: center" class="focusContent">
                            Because of amazing people like this, <br>
                            its allowed us at Aspire Competitions to donate a total of
                            &pound;${this.charityTotalDonated} to
                            ${this.charityTotalCount} charities <br> <br>
                            <a href="/charities-we-support?utm_source=aspire&utm_medium=winnerViewAllCharities&utm_content=All charities we have supported">
                                <small>
                                    <component-button>
                                        All charities we have supported
                                    </component-button>
                                </small>
                            </a>
                        </p>

                        <div style="margin-top: 100px">

                        </div>

                        <component-aspire-comps-items-section title="YOU MAY LIKE"
                                                              more-link="View all"
                                                              more-link-href="/shop/products?utm_source=aspire&utm_medium=winnerUpsaleYouMayLike&utm_content=View all">
                            ${this.upsaleProductFilters ? html`
                                <component-firestore-collection-list .path="${FIRESTORE_COLLECTION_SHOP_PRODUCTS}"
                                                                     .fields="${this.upsaleProductFields}"
                                                                     .namedQuery="${'shop::getActiveProducts'}"
                                                                     limit="3"
                                                                     .filters="${this.upsaleProductFilters}"></component-firestore-collection-list>
                            ` : undefined}

                            <div style="text-align: center; margin-top: 25px">
                                <a href="/shop/products?utm_source=aspire&utm_medium=winnerUpsaleYouMayLike&utm_content=Looking for something else? Explore the rest">
                                    <small>
                                        <component-button>
                                            Looking for something else? Explore the rest
                                        </component-button>
                                    </small>
                                </a>
                            </div>
                        </component-aspire-comps-items-section>
                    </div>
                ` : undefined}
            </component-loading>


            <div style="margin-top: 100px">

            </div>
        `;
    }

    @observe('charities', 'winner')
    async activateAnimations() {
        if (!('IntersectionObserver' in window)) return;
        await delayPromise();

        if (!this.visibilityObserver) {
            this.classList.add('allowAnimations');
            this.visibilityObserver = new IntersectionObserver((entries) => {
                for (let entry of entries) {
                    if (!entry.intersectionRatio) continue;

                    entry.target.classList.add('visible');
                    this.visibilityObserver.unobserve(entry.target);
                }
            }, {
                root: document.querySelector('#scrollArea'),
                rootMargin: '0px',
                threshold: 1.0,
            });

        } else {
            this.visibilityObserver.disconnect();
        }


        this.shadowRoot?.querySelectorAll('.animateIn:not(.visible)').forEach(_ => {
            this.visibilityObserver.observe(_);
        });
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        if (this.visibilityObserver) {
            this.visibilityObserver.disconnect();
        }
    }

    generateWinningTickets(winner: CompetitionWinnerDocument) {
        if (!winner) return [];

        return [
            {number: winner.ticketNumber, name: winner.winnerName},
        ];
    }

    //TODO remove this and place with proper firestore routing resolvers/preresolvers
    @observe('winner')
    populatePageTitle(winner?: SurrealDocument<CompetitionWinnerDocument>) {
        if (!winner?.data) return;

        document.title = [
            `${winner.data.winnerName || ''} the winner of ${winner.data.productName || ''} from ${winner.data.location?.county || ''}`,
            'Winners',
            config.title,
        ].filter(_ => _).join(' · ');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-winner': ComponentAspireCompsWinner;
    }
}