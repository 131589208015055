import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';
import {ShopProductDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-admin-wait-for-product')
class ComponentShopAdminWaitForProduct extends BunnyElement {

    @property({type: Object})
    @computed('productPath')
    get product() {
        if (!this.productPath) return undefined;

        return new SurrealDocument<ShopProductDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [this.productPath],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: String})
    productPath: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        //TODO temp hack for live queires not working
        setTimeout(() => {
            if (!this.isConnected) return;
            HistoryHelper.replaceState(location.href.replace(/wait-for-product\//, ''));
        }, 2000);
    }

    @observe('product')
    async redirectOnProductReady(product?: SurrealDocument<ShopProductDocument>) {
        if (!product?.data) return;
        if (product.data.created?.getTime() === product.data.updated?.getTime()) return;

        //TODO temp hack for live queires not working
        await delayPromise(500);
        HistoryHelper.replaceState(location.href.replace(/wait-for-product\//, ''));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-wait-for-product': ComponentShopAdminWaitForProduct;
    }
}