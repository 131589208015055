import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {loadTrackingLibrary} from '../../../firebase-analytics/local/helpers/TrackingLibraryLoaderHelper';
import {shopCall} from '../helpers/ShopHelper';
import {RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {ComponentShopOrderReservedCountdown} from './component-shop-order-reserved-countdown';
import {SHOP_STALE_DRAFT_ORDER_ROLLBACK} from '../../shared/index';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {storageBoundLocalStorage} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {ENV} from '../../../../config';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
//@ts-ignore
import checkoutcomFramesV2ShadowDomURL from '../../../../../public/js/checkoutcom-framesv2-shadowdom.js?url';
import {ComponentButton} from '../../../../components/component-button';
import {spread} from '@open-wc/lit-helpers';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';
import {
    AccountShopSavedCard__card__expire,
    AccountShopSavedCard__card__number,
    AccountShopSavedCardDocument,
    FIRESTORE_COLLECTION_ACCOUNTS,
    FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_SAVED_CARDS,
    FIRESTORE_COLLECTION_SHOP_ORDERS,
    FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_TRANSACTIONS,
    ShopOrderDocument,
} from '../../../../utils/DatabaseTypes.ts';

const ERRORS_REMAP: { [key: string]: string } = {
    'card-number': 'Please enter a valid card number',
    'expiry-date': 'Please enter a valid expiry date',
    'cvv': 'Please enter a valid cvv code',
};

export const CARD_SCHEME_IMAGES_PREFIX = '/images/cardSchemas/';
export const CARD_SCHEME_IMAGES: { [key: string]: string } = {
    'Visa': 'visa.svg',
    'American Express': 'amex.svg',
    'Mastercard': 'mastercard.svg',
    'Maestro': 'maestro.svg',
    'Discover': 'discover.svg',
    'Diners Club': 'diners.svg',
    'JCB': 'jcb.svg',
    default: 'generic.svg',
};


@customElement('component-shop-payment-checkoutcom')
class ComponentShopPaymentCheckoutcom extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    private checkoutcom: any;

    private showSpinnerTimeoutId: any;
    private showInternetWarningTimeoutId: any;

    @property({type: String})
    publicToken: string;

    @property({type: String})
    errorMessage: string = '';

    @property({type: String})
    newCardErrorMessage: string = '';

    newCardErrorMessages = new Set<string>();

    @property({type: Object})
    newCardBin: any = {};

    @storageBoundLocalStorage('shop-payment-checkoutcom-save-card', 'true', (_) => _ === 'true')
    @property({type: Boolean, notify: true})
    newCardSave: boolean = true;

    @property({type: String})
    newCardCardholderName: string;

    @property({type: Object})
    newCardBillingAddress: object;

    @property({type: Boolean, reflect: true})
    loading: boolean = false;

    @property({type: Number})
    transactionValue: number;

    @property({type: Number})
    orderNumber: number;

    @property({type: Number})
    orderId: string;

    @property({type: Number})
    orderUpdated: number;

    @property({type: Object})
    @computed('orderId')
    get order() {
        if (!this.orderId) return undefined;

        return new SurrealDocument<ShopOrderDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_ORDERS}/${this.orderId}`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: Number})
    transactionId: string;

    @property({type: String})
    customCallback: string;

    @property({type: String})
    customCallbackSignature: string;

    @property({type: Array})
    @computed('auth')
    get savedCards() {
        return new SurrealCollection<RefferableDocument & AccountShopSavedCardDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [`${FIRESTORE_COLLECTION_ACCOUNTS}/${this.auth.user?.uid}/${FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_SAVED_CARDS}`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: Object})
    googlePay: {
        gateway: string;
        merchantId: string;
        merchantName: string;
        environment: string;
    };

    @property({type: Object})
    applePay: {
        validateMerchantCustomCallback: string;
        validateMerchantCustomCallbackSignature: string;
    };

    @property({type: Number})
    orderReservedTimeRemaining: number;

    @property({type: Boolean})
    cardInputLoading: boolean = false;

    @query('#shopOrderReservedCountdown')
    shopOrderReservedCountdownElement: ComponentShopOrderReservedCountdown;

    @query('#payButton')
    payButtonElement: ComponentButton;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
            }

            :host > component-loading {
                display: none;
            }

            :host([loading]):before {
                content: '';
                background-color: rgba(255, 255, 255, .6);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
            }

            :host([loading]) > component-loading {
                display: block;
                position: fixed;
                top: 25%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
                box-shadow: 0 0 15px 50px rgba(255, 255, 255, .6);
                background-color: rgba(255, 255, 255, .6);
                border-radius: 100%;
                width: 50px;
                height: 50px;
            }

            :host([loading]) component-loading:after {
                content: 'Processing';
                position: absolute;
                bottom: 100%;
                left: 50%;
                z-index: 2;
                margin-bottom: 5px;
                transform: translateX(-50%);
            }

            component-button {
                background-color: var(--primary-color);
                width: 100%;
                color: white;
                margin: 0;
            }

            component-button[disabled] {
                background-color: rgba(0, 0, 0, .4);
                opacity: .7;
            }

            .card {
                position: relative;
                padding: 25px;
                border-radius: 8px;
                background-color: #fbfbfb;
                box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1), 0 1px 3px 0 rgba(0, 0, 0, .09), 0 1px 1px -1px rgba(0, 0, 0, .13);
                width: 340px;
                max-width: 100%;
                display: inline-block;
                overflow: hidden;
                padding-bottom: 60px;
                box-sizing: border-box;
                margin-right: 15px;
                margin-bottom: 15px;
            }

            .card .cardLogo {
                position: absolute;
                top: 25px;
                right: 25px;
                width: 50px;
            }


            .card[data-error]:not([data-error=""]):after {
                content: attr(data-error);
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                padding: 6px;
                background-color: #e56f04;
                color: white;
                text-align: center;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
            }

            .card[data-error] .buttonsContainer .payWithSaveCard {
                display: none;
            }

            .card:not([data-error]) .buttonsContainer .removeSavedCard {
                padding: 0;
                flex: 0;
            }

            .card:not([data-error]) .buttonsContainer .removeSavedCard span {
                display: none;
            }

            .card[data-error] .buttonsContainer .removeSavedCard component-icon {
                display: none;
            }

            .card .cardNumber {
                margin-top: 20%;
                margin-bottom: 5%;
            }

            .card [data-label] {
                position: relative;
            }

            .card [data-label]:before {
                content: attr(data-label);
                position: absolute;
                top: -15px;
                left: 0;
                font-size: 10px;
                opacity: .8;
            }

            .card .buttonsContainer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
            }

            .card .buttonsContainer component-button {
                border-radius: 0;
            }

            h3 {
                margin-top: 50px;
            }

            hr {
                border: 0;
                border-top: solid rgba(32, 32, 32, .1) 1px;
                height: 0;
                margin-left: -15px;
                margin-right: -15px;
            }

            .quickPayButton {
                background: #000000;
                width: 240px;
                height: 40px;
                border-radius: 4px;
                align-items: center;
                justify-content: center;
                color: white;
                display: inline-flex;
                margin-right: 15px;
                margin-bottom: 15px;
                padding: 3px 10px;
            }

            .quickPayButton img {
                height: 40px;
            }

            .cardNumber component-loading {
                position: relative;
                min-height: 20px;
                --paper-spinner: {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                };
            }
        `,
    ];

    override render() {
        let orderReservedTimes;
        let data = this.order?.data;
        if (data) {
            orderReservedTimes = this.calculateOrderReservedTimes(data, this.orderUpdated);
        }

        return html`
            <component-loading loading></component-loading>
            <div class="contentStep">
                <h2 style="margin-top: -25px; margin-left: -15px; margin-right: -15px; padding: 8px 15px; background: #43454b; color: white; display: flex; justify-content: space-between; align-items: center">
                    Order #${this.orderNumber}

                    <a href="/shop/cart?utm_source=aspire&utm_medium=shopCheckoutPayment&utm_content=Cancel">
                        <small style="font-size: 80%; display: block;">
                            <component-button
                                    style="max-width: 340px; background-color:var(--attention-color); --padding: 5px; font-weight: normal; margin-left: 5px;">
                                Cancel
                            </component-button>
                        </small>
                    </a>
                </h2>
                <p>
                    Pending payment:
                    <component-shop-price value="${this.transactionValue}"
                                          style="display: inline-block"></component-shop-price>
                </p>
                <p style="margin-bottom: 0">Please continue payment with one of the following payment methods</p>

                <component-shop-order-reserved-countdown
                        id="shopOrderReservedCountdown"
                        .startTime="${orderReservedTimes?.start}"
                        .endTime="${orderReservedTimes?.end}"
                        .timeRemaining="${this.bind.orderReservedTimeRemaining}"
                        style="margin: 15px -15px -25px -15px"></component-shop-order-reserved-countdown>
            </div>

            ${this.isAvailable('applePay', 'googlePay') ? html`
                <div class="contentStep paysSection">
                    ${this.isAvailable('applePay') ? html`
                        <component-shop-payment-apple-pay
                                currency="GBP"
                                country="GB"
                                .totalPrice="${this.transactionValue}"
                                @tokenized="${this.onApplePayTokenized}"
                                .orderId="${this.orderId}"
                                .transactionId="${this.transactionId}"
                                .validateMerchantCustomCallbackSignature="${this.applePay.validateMerchantCustomCallbackSignature}"
                                .validateMerchantCustomCallback="${this.applePay.validateMerchantCustomCallback}"
                                style="margin-bottom: 25px"></component-shop-payment-apple-pay>
                    ` : undefined}

                    ${this.isAvailable('googlePay') ? html`
                        <component-shop-payment-google-pay
                                currency="GBP"
                                country="GB"
                                .totalPrice="${this.transactionValue}"
                                @tokenized="${this.onGooglePayTokenized}"
                                .gateway="${this.googlePay.gateway}"
                                .gatewayMerchantId="${this.publicToken}"
                                .merchantName="${this.googlePay.merchantName}"
                                .merchantId="${this.googlePay.merchantId}"
                                .environment="${this.googlePay.environment}"
                        ></component-shop-payment-google-pay>
                    ` : undefined}
                </div>
            ` : undefined}

            ${this.errorMessage ? html`
                <div class="contentStep" style="background: #e56f04; color: white">
                    <h3>Transaction issue:</h3>
                    ${this.errorMessage}
                </div>
            ` : undefined}

            <div class="contentStep">
                <component-loading .loading="${this.savedCards?.loading ?? true}">
                    ${this.savedCards?.data?.length ? html`
                        <h3>Saved cards</h3>
                    ` : undefined}

                    ${this.savedCards?.data?.map(item => html`
                        <div class="card" ${spread({'data-error': this.getCardErrors(item.card)})}>
                            <img class="cardLogo" src="${this.getNewCardSchemeImage(item.card)}"
                                 alt="${item.card.scheme}">

                            <div class="cardNumber">
                                ${this.renderCardNumber(item.card.number)}
                            </div>
                            <div style="display: flex">
                                <div class="cardName" data-label="Name">
                                    ${item.card.cardholderName}
                                </div>
                                <div class="expiry" style="margin-left: auto" data-label="Expires">
                                    ${this.renderCardExpiry(item.card.expire)}
                                </div>
                            </div>
                            <div class="buttonsContainer">
                                <component-button class="payWithSaveCard"
                                                  @click="${this.paySavedCard}"
                                                  data-saved-card-id="${item.card.checkoutId}"
                                                  style="text-transform: none">
                                    Pay with Card
                                </component-button>
                                <component-button class="removeSavedCard"
                                                  style="background-color: var(--attention-color); min-width: 50px; text-transform: none"
                                                  @click="${this.removeSavedCard}"
                                                  .savedCard="${item}">
                                    <span>
                                        Remove card
                                    </span>
                                    <component-icon icon="icons:cancel"></component-icon>
                                </component-button>
                            </div>
                        </div>
                    `)}

                    ${this.savedCards?.data?.length ? html`
                        <hr>
                    ` : undefined}
                </component-loading>


                <h3>New card</h3>
                <form method="POST"
                      action="https://merchant.com/charge-card"
                      @sumbit="${this.payNewCard}">
                    <component-input-checkbox .checked="${this.bind.newCardSave}"
                                              style="display: block; margin-bottom: 10px;">
                        Save this card for upcoming purchases
                    </component-input-checkbox>
                    <div class="card" data-error="${this.newCardErrorMessage}">
                        <img class="cardLogo" src="${this.getNewCardSchemeImage(this.newCardBin)}"
                             alt="${this.newCardBin.scheme}">

                        <div class="cardNumber">
                            <component-loading .loading="${this.cardInputLoading}">
                                <div id="cardFrame" style="height: 20px"></div>
                            </component-loading>
                        </div>


                        <div style="display: flex">
                            <component-input class="cardName borderBottomOnly" label="Name"
                                             .value="${this.newCardCardholderName}"
                                             style="width: 100%; margin-right: 7px; margin-top: -13px; --input-container-border-radius: 0;"></component-input>
                        </div>

                        <div class="buttonsContainer">
                            <component-button id="payButton" disabled @click="${this.payNewCard}"
                                              style="text-transform: none">
                                Pay with Card
                            </component-button>
                        </div>
                    </div>
                </form>
            </div>
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        this.loadCheckoutcom();
    }

    disconnectedCallback() {
        super.disconnectedCallback();


        let Frames = this.checkoutcom;
        if (Frames) {
            let Events = Frames.Events;
            for (let eventName of [Events.CARD_VALIDATION_CHANGED, Events.FRAME_VALIDATION_CHANGED, Events.CARD_TOKENIZATION_FAILED, Events.CARD_TOKENIZED, Events.CARD_BIN_CHANGED]) {
                Frames.removeAllEventHandlers(eventName);
            }
        }

        this.clearCardInputLoading();
    }

    calculateOrderReservedTimes(order: ShopOrderDocument, orderUpdated: number) {
        if (!order && !orderUpdated) return undefined;
        let orderUpdatedUnix = order?.updated?.getTime() || orderUpdated;

        return {
            start: orderUpdatedUnix,
            end: orderUpdatedUnix + (SHOP_STALE_DRAFT_ORDER_ROLLBACK * 60 * 1000),
        };
    }

    async loadCheckoutcom() {
        await loadTrackingLibrary(checkoutcomFramesV2ShadowDomURL);
        this.checkoutcom = (window as any).Frames;
        delete (window as any).Frames;

        this.initCheckoutcom();
    }

    private clearCardInputLoading() {
        clearTimeout(this.showSpinnerTimeoutId);
        clearTimeout(this.showInternetWarningTimeoutId);
        this.cardInputLoading = false;
    }

    @bind()
    private onFrameReady() {
        this.clearCardInputLoading();
    }

    @bind()
    private onCardValidationChanged(e: any) {
        let Frames = this.checkoutcom;
        console.log('CARD_VALIDATION_CHANGED: %o', e);

        this.payButtonElement.disabled = !Frames.isCardValid();
    }

    @bind()
    private onFrameValidationChanged(e: any) {
        console.log('FRAME_VALIDATION_CHANGED: %o', e);

        let hasError = !e.isValid && !e.isEmpty;
        let errorMessage = ERRORS_REMAP[e.element];


        if (hasError) {
            this.newCardErrorMessages.add(errorMessage);

        } else {
            this.newCardErrorMessages.delete(errorMessage);
        }

        this.newCardErrorMessage = [...this.newCardErrorMessages][0];
    }

    @bind()
    private onCardTokenizationFailed(e: any) {
        let Frames = this.checkoutcom;
        console.log('CARD_TOKENIZATION_FAILED: %o', e);
        Frames.enableSubmitForm();
    }

    @toastProgressWrapper({
        progressMessage: 'Processing payment',
        failedMessage: 'Failed processing payment {{e}}',
        successMessage: false,
    })
    async doPay(data: any) {
        try {
            let response = await shopCall.paymentHandlerCustomCallback(
                this.orderId,
                this.transactionId,
                this.customCallbackSignature,
                this.customCallback,
                data,
            );
            showToast('');
            console.log('response', response);

            if (response.continueComponent) {
                (window as any).__shopPlaceOrderState = {
                    paymentComponent: response.continueComponent,
                    pendingTransaction: {
                        path: `${FIRESTORE_COLLECTION_SHOP_ORDERS}/${this.orderId}/${FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_TRANSACTIONS}/${this.transactionId}`,
                        id: this.transactionId,
                    },
                };
                HistoryHelper.pushState(location.href, undefined, {
                    placeOrderState: (window as any).__shopPlaceOrderState,
                });
            }

        } catch (e: any) {
            this.errorMessage = e.message;
            throw e;

        } finally {
            this.loading = false;
        }
    }

    @bind()
    private async onCardTokenized(e: any) {
        let token = e.token;
        console.log('token', token);

        await this.doPay({
            token: token,
            saveCard: this.newCardSave,
        });
    }

    @bind()
    private onCardBinChanged(e: any) {
        this.newCardBin = e;
    }

    private initCheckoutcom() {
        let Frames = this.checkoutcom;

        Frames.init(
            {
                documentRoot: this.shadowRoot,
                frameSelector: '#cardFrame',
                publicKey: this.publicToken,
                style: {
                    base: {
                        color: '#43454b',
                        extra: `0;}
                        .payment-method-logo-container{
                            display: none;
                        }
                        .field{
                            border-bottom: solid #43454b 1px!important;
                            transition: .125s;
                            font-size: 16px;
                            margin-bottom: 1px;
                        }
                        .expiry-date{
                            width: 50px!important;
                        }
                        [class$="--invalid"]{
                            color: #e56f04!important;
                            border-bottom-color: #e56f04!important;
                            box-shadow: 0px 1px 0 #e56f04!important;
                        }
                        .field:focus{
                            border-bottom-color: #8dc033!important;
                            box-shadow: 0px 1px 0 #8dc033!important;
                        }
                        #nothing{`, //pre,post text for css injection
                    },
                },
            },
        );

        this.showSpinnerTimeoutId = setTimeout(() => {
            this.cardInputLoading = true;
        }, 5000);

        this.showInternetWarningTimeoutId = setTimeout(() => {
            showToast(`We're having some trouble contacting our payment provider, try checking your internet connection`);
        }, 30000);

        let Events = Frames.Events;
        Frames.addEventHandlers({
            [Events.CARD_VALIDATION_CHANGED]: this.onCardValidationChanged,
            [Events.FRAME_VALIDATION_CHANGED]: this.onFrameValidationChanged,
            [Events.CARD_TOKENIZATION_FAILED]: this.onCardTokenizationFailed,
            [Events.CARD_TOKENIZED]: this.onCardTokenized,
            [Events.CARD_BIN_CHANGED]: this.onCardBinChanged,
            [Events.READY]: this.onFrameReady,
        });
    }

    payNewCard(e: any) {
        e.preventDefault();

        let Frames = this.checkoutcom;
        this.loading = true;
        Frames.cardholder = {
            name: this.newCardCardholderName,
            billingAddress: this.newCardBillingAddress,
            phone: null,
        };
        Frames.submitCard();
    }

    async paySavedCard(e: any) {
        this.loading = true;

        await this.doPay({
            savedCardId: (e.currentTarget as HTMLElement).dataset.savedCardId,
        });
    }

    getNewCardSchemeImage(newCardBin: any) {
        return CARD_SCHEME_IMAGES_PREFIX + (CARD_SCHEME_IMAGES[newCardBin.scheme] || CARD_SCHEME_IMAGES.default);
    }

    renderCardNumber(number: AccountShopSavedCard__card__number) {
        let cardNumber = number.bin.padEnd(12, '*') + number.last4;

        return cardNumber.replace(/(.{4})/g, '$1 ');
    }

    renderCardExpiry(expire: AccountShopSavedCard__card__expire) {
        return `${expire.month.toFixed(0).padStart(2, '0')}/${expire.year.toFixed(0).slice(2)}`;
    }

    getCardErrors(_card: any) {
        let expire = _card.expire;
        let expireDate = new Date(expire.year, expire.month, 0, 23, 59, 59);//use 0 for the date and the next month on to get the last moment of the correct month

        return expireDate.getTime() < Date.now() ? 'Expired' : undefined;
    }

    @toastProgressWrapper({
        progressMessage: 'Removing saved card',
        successMessage: 'Removed saved card',
        failedMessage: 'Failed removing saved card: {{e}}',
    })
    @confirmationDialog({
        title: 'Removing saved card',
        body: 'Are you sure you want to remove this saved card?',
        failButtonText: 'Cancel',
        successButtonText: 'Yes permanently remove',
    })
    async doRemoveSavedCard(savedCard: RefferableDocument) {
        let savedCardRef = savedCard._ref as any;

        await callableQuery('__internal::deleteDoc')(savedCardRef.surrealId);
    }

    removeSavedCard(e: any) {
        let savedCard = (e.currentTarget as any).savedCard;
        this.doRemoveSavedCard(savedCard);
    }

    async onGooglePayTokenized(e: CustomEvent) {
        let token = e.detail.token;
        console.log('token', token);


        this.loading = true;
        await this.doPay({
            googlePayToken: JSON.parse(token),
        });
    }

    async onApplePayTokenized(e: CustomEvent) {
        console.log('e.detail', e.detail);
        let token = e.detail.token;
        let completePayment = e.detail.completePayment;
        console.log('token', token);
        console.log('completePayment', completePayment);


        this.loading = true;
        try {
            await this.doPay({
                applePayToken: token.paymentData,
            });
            console.log('e.detail', e.detail);
            completePayment(true);

        } catch (e) {
            completePayment(false);
            throw e;
        }
    }

    isAvailable(...types: ('applePay' | 'googlePay')[]) {
        for (let type of types) {
            switch (type) {
                case 'applePay':
                    if (ENV === 'local') return true;

                    //DONT just return ('ApplePaySession' in window), needs to only return for true values, and continue on false
                    if ('ApplePaySession' in window) return true;
                    break;

                // case 'googlePay':
                //     return false;
            }
        }


        return false;
    }

    private doRedirectNonePendingOrder() {
        //TODO hate this, replace with knowing the cartId... or better the orderId after a rewrite.....
        showToast(
            'This order has been pending payment for too long and is no longer reserved, please place the order again',
            {heading: 'Order returned to draft'},
        );
        HistoryHelper.replaceState(location.pathname.replace('/payment', ''));
    }

    @observe('order')
    redirectOnNonePendingOrder(order?: SurrealDocument<ShopOrderDocument>) {
        if (!order?.data) return;

        if (order.data.state !== 'pending') {
            debugger;
            this.doRedirectNonePendingOrder();
        }
    }

    @observe('orderReservedTimeRemaining')
    redirectOnOrderReservedTimedout(orderReservedTimeRemaining: number) {
        if (orderReservedTimeRemaining > 0) return;
        if (!this.shopOrderReservedCountdownElement.localTimeSynced) {
            //ignore it if the local time isnt yet synced with the server
            return;
        }

        this.doRedirectNonePendingOrder();
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-payment-checkoutcom': ComponentShopPaymentCheckoutcom;
    }
}
