import {customElement, query} from 'lit/decorators.js';
import {ComponentMenusItem} from '../../../menu/local/components/component-menus-item';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {html} from 'lit';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {ComponentShopCartInternal} from './component-shop-cart-internal';
import {ShopCartDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-menus-item-shop-basket')
class ComponentMenusItemShopBasket extends ComponentMenusItem {

    @property({type: Number})
    @computed('shopCart')
    get count() {
        let count = this.shopCart?.itemTotals?.quantity || 0;
        if (count >= 100) {
            count = ':D' as any;
        }

        return count;
    };

    @property({type: Boolean})
    pendingCartUpdate: boolean = false;

    @property({type: Object})
    shopCartInternal: ComponentShopCartInternal = ComponentShopCartInternal.getInstance();

    @property({type: Object})
    shopCart: ShopCartDocument;

    @query('#count')
    countElement: HTMLElement;

    static override styles = [
        ...super.styles,
        // language=SCSS
        scss`
            #count {
                position: absolute;
                top: -5px;
                left: -9px;
                border-radius: 100%;
                width: 20px;
                height: 20px;
                line-height: 20px;
                text-align: center;
                color: white;
                background-color: var(--attention-color);
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                font-size: 12px;
                transform-origin: center center;
                transition: .075s cubic-bezier(0, 0, 0.79, 1.63);
                transform: scale(0);
            }

            #count.show {
                transition: .125s;
                transform: scale(1);
            }

            #count[data-count="0"] {
                transform: scale(0);
            }

            #count[data-pending-cart-update="1"] {
                animation: pendingCartUpdateIcon 1s infinite;
                color: transparent;
            }

            @keyframes pendingCartUpdateIcon {
                0% {
                    transform: scale(.8);
                }
                50% {
                    transform: scale(.5);
                }
                100% {
                    transform: scale(.8);
                }
            }

            @media screen and (min-width: 1025px) and (max-width: 1200px) {
                component-button {
                    --padding: 9.125px 11.2px
                }

                component-button slot {
                    display: none;
                }
            }
        `];

    override render() {
        return html`
            <a href="${this.href}">
                <component-button>
                    <span style="position:relative;">
                        ${this.icon ? html`
                            <component-icon .icon="${this.icon}" style="margin-top: -5px"></component-icon>
                        ` : undefined}
                        <div id="count"
                             data-count="${this.count}"
                             data-pending-cart-update="${this.pendingCartUpdate ? 1 : 0}">
                            ${this.count}
                        </div>
                    </span>
                    <slot></slot>
                </component-button>
            </a>
        `;
    }

    @observe('count')
    async triggerShowCount() {
        let classList = this.countElement.classList;
        classList.remove('show');

        await delayPromise(75);
        classList.add('show');
    }

    connectedCallback() {
        super.connectedCallback();

        let shopCartInternal = this.shopCartInternal;
        shopCartInternal?.addEventListener('cart-changed', this.cartChangedListener as any);
        shopCartInternal?.addEventListener('pending-cart-update-changed', this.reflectPendingCartUpdated);
        this.shopCart = shopCartInternal.cart?.data as any;
        this.pendingCartUpdate = shopCartInternal.pendingCartUpdate;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        let shopCartInternal = this.shopCartInternal;
        shopCartInternal?.removeEventListener('cart-changed', this.cartChangedListener as any);
        shopCartInternal?.removeEventListener('pending-cart-update-changed', this.reflectPendingCartUpdated);
    }

    @bind()
    private cartChangedListener(e: CustomEvent) {
        this.shopCart = e.detail.value.data;
    }

    @bind()
    private reflectPendingCartUpdated() {
        this.pendingCartUpdate = this.shopCartInternal.pendingCartUpdate;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-menus-item-shop-basket': ComponentMenusItemShopBasket;
    }
}