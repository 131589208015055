import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {downloadFile} from '../../../__internal/local/helpers/FileHelper';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {ComponentAspireCompsLivestreamOverviewItem} from './component-aspire-comps-livestream-overview-item';
import {CompetitionDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-aspire-comps-livestream-overview')
class ComponentAspireCompsLivestreamOverview extends BunnyElement {

    @property({type: Boolean})
    allowUnfinish: boolean = false;

    @property({type: Array})
    @computed('allowUnfinish')
    get fields() {
        return [
            {
                cellProperties: {
                    field: 'name',
                    type: 'text',
                },
                header: false,
                cell: {
                    component: 'component-aspire-comps-livestream-overview-item',
                    properties: {
                        allowUnfinish: this.allowUnfinish,
                    },
                },
            },
        ];
    }

    @property({type: Array})
    filters = {
        state: 'live',
    };

    @property({type: Array})
    order = ['sort', 'asc'];

    @property({type: Array})
    competitions: CompetitionDocument[] = [];

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 100;
                background: white;
                overflow: auto;
            }

            component-button {
                margin: 15px;
            }

            @media screen and (max-width: 1024px) {
                component-button {
                    display: none;
                }
            }
        `,
    ];

    override render() {
        return html`
            <component-button @click="${this.copyWinnerPostText}">Copy winners</component-button>
            <component-button @click="${this.downloadWinnersCsv}">Download winners</component-button>

            <component-firestore-collection-list id="list"
                                                 path="competitions"
                                                 .items="${this.bind.competitions}"
                                                 .defaultOrder="${this.order}"
                                                 .fields="${this.fields || []}"
                                                 .filters="${this.filters}"
                                                 .fetchMethod="${'live'}"
                                                 .contentClass="${''}"></component-firestore-collection-list>
        `;
    }

    winnerPostText() {
        let items = [...this.shadowRoot?.querySelector('#list')?.shadowRoot?.querySelectorAll('#content > *') as NodeListOf<ComponentAspireCompsLivestreamOverviewItem>];

        return items.map(_ => _.winnerPostText()).join('\n');
    }

    @toastProgressWrapper({successMessage: 'Copied'})
    copyWinnerPostText() {
        navigator.clipboard.writeText(this.winnerPostText());
    }

    @toastProgressWrapper({
        successMessage: 'Downloaded',
        progressMessage: 'Creating csv',
        failedMessage: 'Failed creating csv: {{e}}',
    })
    async downloadWinnersCsv() {
        let drawn = this.competitions[0].drawn;
        if (!drawn) return;

        let winners = await adminCall.competitionsDownloadWinners(drawn);
        downloadFile(winners.csv, `${drawn.toDateString()} winners.csv`, 'text/csv');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-livestream-overview': ComponentAspireCompsLivestreamOverview;
    }
}