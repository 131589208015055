import {customElement} from 'lit/decorators.js';
import '../../../../shared-styles';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {sharedStyles} from '../../../../shared-styles';
import {html} from 'lit';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {config} from '../../../../config';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {
    FIRESTORE_COLLECTION_MENUS,
    FIRESTORE_COLLECTION_MENUS_SUB_ITEMS,
    MenuDocument,
    MenuItemDocument,
} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-menu')
class ComponentMenu extends BunnyElement {

    @property({type: Object, notify: true})
    @computed('menuId')
    get menu() {
        if (!this.menuId) return undefined;

        let staticMenu = config.site.staticMenus[this.menuId];

        return new SurrealDocument<MenuDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_MENUS}/${this.menuId}`],
            {
                method: staticMenu ? FetchMethod.STATIC : FetchMethod.CACHE_FIRST,
                staticContent: staticMenu ? {
                    metadata: {},
                    _ref: {},
                    get data() {
                        return staticMenu.menu;
                    },
                } : undefined,
            } as any,
        );
    }

    @property({type: Object, notify: true})
    @computed('menuId')
    get menuItems() {
        let staticMenu = config.site.staticMenus[this.menuId];

        return new SurrealCollection<MenuItemDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                `${FIRESTORE_COLLECTION_MENUS}/${this.menuId}/${FIRESTORE_COLLECTION_MENUS_SUB_ITEMS}`,
                {},
            ],
            {
                method: staticMenu ? FetchMethod.STATIC : FetchMethod.CACHE_FIRST,
                staticContent: staticMenu ? {
                    metadata: {},
                    data: {
                        _ref: {},
                        metadata: {},
                        docs: staticMenu.items.map((item: any) => {
                            if (!item._ref) {
                                item._ref = {};
                            }

                            return item;
                        }),
                    },
                } : undefined,
            } as any,
        );
    }

    @property({type: String})
    menuId: string;

    @property({type: Object})
    data: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${this.menu?.data && this.menuItems?.data?.length ? createComponent(
                    this.menu.data,
                    this.data,
                    {
                        menu: this.menu.data,
                        data: this.data,
                        rawItems: this.menuItems.data,
                    },
            ) : undefined}
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-menu': ComponentMenu;
    }
}