import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {CARD_SCHEME_IMAGES, CARD_SCHEME_IMAGES_PREFIX} from './component-shop-payment-checkoutcom';
import {RefferableDocument} from '../../../__internal/shared/helpers/FirestoreHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';
import {
    AccountShopSavedCard__card__expire,
    AccountShopSavedCard__card__number,
    AccountShopSavedCardDocument,
    FIRESTORE_COLLECTION_ACCOUNTS,
    FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_SAVED_CARDS,
} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-my-account-cards')
class ComponentShopMyAccountCards extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Array})
    @computed('auth')
    get cards() {
        return new SurrealCollection<AccountShopSavedCardDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                `${FIRESTORE_COLLECTION_ACCOUNTS}/${this.auth.user?.uid}/${FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_SAVED_CARDS}`,
                {},
            ],
            {method: FetchMethod.LIVE},
        );
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button {
                width: 100%;
                color: white;
                margin: 0;
            }

            component-button[disabled] {
                background-color: rgba(0, 0, 0, .4);
                opacity: .7;
            }

            .card {
                position: relative;
                padding: 25px;
                border-radius: 8px;
                background-color: #fbfbfb;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                width: 340px;
                max-width: 100%;
                display: inline-block;
                overflow: hidden;
                padding-bottom: 60px;
                box-sizing: border-box;
                margin-right: 15px;
                margin-bottom: 15px;
            }

            .card .cardLogo {
                position: absolute;
                top: 25px;
                right: 25px;
                width: 50px;
            }

            .card .cardNumber {
                margin-top: 20%;
                margin-bottom: 5%;
            }

            .card [data-label] {
                position: relative;
            }

            .card [data-label]:before {
                content: attr(data-label);
                position: absolute;
                top: -15px;
                left: 0;
                font-size: 10px;
                opacity: .8;
            }

            .card .buttonsContainer {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                display: flex;
            }

            .card .buttonsContainer component-button {
                border-radius: 0;
            }
        `,
    ];

    override render() {
        return html`
            <p>
                Here are your saved cards on this account, if you would like to add a new card you can do this
                during checkout
            </p>


            <component-loading .loading="${this.cards?.loading ?? true}">
                ${this.cards?.data?.map(item => html`
                    <div class="card">
                        <img class="cardLogo" src="${this.getNewCardSchemeImage(item.card)}"
                             alt="${item.card.scheme}">

                        <div class="cardNumber">
                            ${this.renderCardNumber(item.card.number)}
                        </div>
                        <div style="display: flex">
                            <div class="cardName" data-label="Name">
                                ${item.card.cardholderName}
                            </div>
                            <div class="expiry" style="margin-left: auto" data-label="Expires">
                                ${this.renderCardExpiry(item.card.expire)}
                            </div>
                        </div>
                        <div class="buttonsContainer">
                            <component-button class="removeSavedCard"
                                              style="background-color: var(--attention-color); text-transform: none"
                                              @click="${(_: MouseEvent) => this.doRemoveSavedCard(item)}">
                                Remove card
                            </component-button>
                        </div>
                    </div>
                `)}
            </component-loading>
        `;
    }

    getNewCardSchemeImage(newCardBin: any) {
        return CARD_SCHEME_IMAGES_PREFIX + (CARD_SCHEME_IMAGES[newCardBin.scheme] || CARD_SCHEME_IMAGES.default);
    }

    renderCardNumber(number: AccountShopSavedCard__card__number) {
        let cardNumber = number.bin.padEnd(12, '*') + number.last4;

        return cardNumber.replace(/(.{4})/g, '$1 ');
    }

    renderCardExpiry(expire: AccountShopSavedCard__card__expire) {
        return `${expire.month.toFixed(0).padStart(2, '0')}/${expire.year.toFixed(0).slice(2)}`;
    }

    @toastProgressWrapper({
        progressMessage: 'Removing saved card',
        successMessage: 'Removed saved card',
        failedMessage: 'Failed removing saved card: {{e}}',
    })
    @confirmationDialog({
        title: 'Removing saved card',
        body: 'Are you sure you want to remove this saved card?',
        failButtonText: 'Cancel',
        successButtonText: 'Yes permanently remove',
    })
    async doRemoveSavedCard(savedCard: RefferableDocument) {
        let savedCardRef = savedCard._ref as any;

        await callableQuery('__internal::deleteDoc')(savedCardRef.surrealId);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-my-account-cards': ComponentShopMyAccountCards;
    }
}