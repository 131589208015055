import {customElement, property} from 'lit/decorators.js';
import {html} from 'lit';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {GLOBAL_GRAPPYFILL} from '../../../../aspire-app';
import {PrizeClaimingThreadDocument} from '../../../../utils/DatabaseTypes.ts';


@customElement('component-prize-claiming-sidebar-item')
class ComponentPrizeClaimingSidebarItem extends BunnyElement {

    @property({type: Object})
    item: PrizeClaimingThreadDocument;

    get unreadCount() {
        let viewerType: 'customer' | 'aspire' = this.item.owner.toString() === GLOBAL_GRAPPYFILL.account?._surrealId.toString() ? 'customer' : 'aspire';

        return ((this.item.unreadCount || {})[viewerType] as number) || 0;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                display: block;
            }

            component-button {
                display: flex;
                background: var(--primary-color);
                color: white;
                --padding: 0;
                text-align: left;

                &[data-unread-count]:not([data-unread-count="0"]):after {
                    content: attr(data-unread-count);
                    border-radius: 100%;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    font-size: 12px;
                    background: var(--attention-color);
                    color: white;
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                }
            }

            img {
                width: 100px;
            }
        `,
    ];

    override render() {
        return html`
            <a href="/prize-claiming/${this.item._ref?.id}">
                <component-button data-unread-count="${this.unreadCount}">
                    <component-media-view-image .media="${this.item.prizeImage}"
                                                type="default-thumbnail"
                                                fetch-method="cacheFirst"
                                                .width="${400}"
                                                .height="${300}"
                                                style="width: 100px; background-color: #f2f2f2; flex-shrink: 0"></component-media-view-image>
                    <div style="margin-left: 10px; width: 75%">
                        <h2 style="margin: 0; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            ${this.item.prizeName}</h2>
                        <div style="text-transform: none; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
                            ${this.item.lastMessage}
                        </div>
                    </div>
                </component-button>
            </a>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-prize-claiming-sidebar-item': ComponentPrizeClaimingSidebarItem;
    }
}