import {CACHE_DOCUMENT_DELAYS, FetchMethod, hostlessRequest, LOADING_STATE, Options, SurrealData} from './SurrealData';
import {callableLiveQuery, callableQuery} from '../helpers/SurrealHelper';
import {calculateFullDocumentRecordId} from '@lupimedia/firebase-polyfill/src/firestore/helpers/FirestoreHelper.ts';
import {DocumentReference} from '@lupimedia/firebase-polyfill/src/firestore/DocumentReference.ts';


export class SurrealDocument<T = any> extends SurrealData<T> {

    data!: T;

    static hostlessRequest(namedQuery: string, queryArgs: any[], options?: Options, streamUpdates?: (data: any) => void) {
        return hostlessRequest(this as any, namedQuery, queryArgs, options, streamUpdates);
    }

    async* fetchData(): AsyncGenerator<[LOADING_STATE, any | undefined]> {
        let queryName = this.namedQuery;
        let queryArgs = this.queryArgs;
        if (!queryName) return yield [LOADING_STATE.ABORTED, undefined];

        yield [LOADING_STATE.LOADING, undefined] as any;


        let snapshot;
        let cleanSnapshot;
        switch (this.method) {
            case FetchMethod.NETWORK_ONLY:
                snapshot = await callableQuery(queryName)(...queryArgs);
                // snapshot = await getDocFromServer(ref);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.NETWORK_FIRST:
                snapshot = await callableQuery(queryName)(...queryArgs);
                // snapshot = await getDocFromServer(ref);

                if (!snapshot.data) {
                    snapshot = await callableQuery(queryName, {source: 'cache'})(...queryArgs);
                }

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_ONLY:
                snapshot = await callableQuery(queryName, {source: 'cache'})(...queryArgs);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_FIRST:
                let itemFromCache;

                if (CACHE_DOCUMENT_DELAYS.size) {
                    itemFromCache = await callableQuery(queryName, {source: 'cache'})(...queryArgs).catch(_ => undefined);
                    if (itemFromCache) {
                        return yield [LOADING_STATE.STREAMING, itemFromCache];
                    }

                    console.time(`waiting for doc: ${queryName}-${queryArgs[0]}`);
                    await Promise.all(CACHE_DOCUMENT_DELAYS);
                    console.timeEnd(`waiting for doc: ${queryName}-${queryArgs[0]}`);
                }


                let data = await (callableQuery(queryName, {source: 'cache'})(...queryArgs).catch(_ => callableQuery(queryName)(...queryArgs)));
                let itemFromCacheDocument = (itemFromCache as any)?._document;
                let dataDocument = (data as any)?._document;
                if (itemFromCacheDocument && dataDocument && itemFromCacheDocument.version.toString() === dataDocument.version.toString()) {
                    return yield [LOADING_STATE.LOADED, itemFromCache];
                }


                return yield [LOADING_STATE.LOADED, data];

            case FetchMethod.FASTEST:
                cleanSnapshot = callableQuery(queryName)(...queryArgs);

                try {
                    snapshot = await Promise.any([
                        callableQuery(queryName, {source: 'cache'})(...queryArgs),
                        cleanSnapshot,
                    ]);

                } catch (e) {
                    snapshot = await cleanSnapshot;
                }

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.FASTEST_THEN_CLEAN:
                cleanSnapshot = callableQuery(queryName)(...queryArgs);

                try {
                    snapshot = await Promise.any([
                        callableQuery(queryName, {source: 'cache'})(...queryArgs),
                        cleanSnapshot,
                    ]);

                } catch (e) {
                    snapshot = await cleanSnapshot;
                }


                if (snapshot.metadata.fromCache) {
                    yield [LOADING_STATE.LOADED, snapshot];
                    return yield [LOADING_STATE.LOADED, await cleanSnapshot];

                } else {
                    return yield [LOADING_STATE.LOADED, snapshot];
                }

            case FetchMethod.LIVE:
                let nextData: Promise<any>;
                let nextDataCallback: (snapshot: any) => void;

                let unsubscribe = callableLiveQuery(queryName)((snapshot) => {
                    nextDataCallback(snapshot);
                }, ...queryArgs);

                let abortListener = () => {
                    unsubscribe();
                    this.abortController?.signal.removeEventListener('abort', abortListener);
                };
                this.abortController?.signal.addEventListener('abort', abortListener);


                while (1) {
                    nextData = new Promise((s) => {
                        nextDataCallback = s;
                    });

                    let snapshot = await nextData;
                    yield [LOADING_STATE.STREAMING, snapshot];
                }

                return yield [LOADING_STATE.ABORTED, undefined];

            case FetchMethod.STATIC:
                return yield [LOADING_STATE.LOADED, (this.options as any).staticContent];
        }
    }

    async receiveData(loadingState: LOADING_STATE, data: any) {
        if (loadingState === LOADING_STATE.LOADING) {
            this.loading = true;
            this.data = undefined as any;
            return;
        }

        this.loading = false;
        if (loadingState === LOADING_STATE.ABORTED) return;


        let doc = data.data;
        if (doc) {
            let ref = doc._ref;
            delete doc._ref;
            Object.defineProperties(doc, {
                _ref: {
                    value: ref,
                    configurable: true,
                },
                _metadata: {
                    value: data.metadata,
                    configurable: true,
                },
            });
        }

        this.data = doc;
    }

    async save(_data?: any, _path: string | null = null) {
        let path = _path || this.queryArgs[0];
        let data = _data || this.data;

        if (!path) return;

        if (path.endsWith('/') || path.startsWith('/') || path.indexOf('//') !== -1) {
            //dont run if theres unloaded variables
            return;
        }


        this.loading = true;
        try {
            let surrealId = calculateFullDocumentRecordId(new DocumentReference(null as any, path.split('/')));

            if (data && !data.__ignore) {
                await callableQuery('__internal::updateDoc')(surrealId, data);
            }

        } finally {
            this.loading = false;
        }
    }

}
