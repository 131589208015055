import {customElement} from 'lit/decorators.js';
import {
    ComponentFirestoreCollectionListItemCell,
} from '../../../__internal/local/components/component-firestore-collection-list-item-cell';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {Auth} from '../controllers/Auth';

@customElement('component-admin-auth-login-as')
class ComponentAdminAuthLoginAs extends ComponentFirestoreCollectionListItemCell {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    accountId: string;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <component-button @click="${this.login}">Login</component-button>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Logging in',
        successMessage: 'Logged in successfully',
        failedMessage: 'Failed logging in: {{e}}',
    })
    async login() {
        let token = await adminCall.authLoginAsUser(this.accountId);

        await this.auth.signInWithCustomToken(token);
        window.localStorage.removeItem('api-variable-shopCartId');

        HistoryHelper.redirect('/');
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-login-as': ComponentAdminAuthLoginAs;
    }
}
