import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {ComponentInputSurrealSelect} from '../../../inputs/local/components/component-input-surreal-select.ts';
import {internalAdminCall} from '../../../admin/local/helpers/AdminHelper.ts';
import {FIRESTORE_COLLECTION_SHOP_PRODUCTS, ShopOrderDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-admin-order-products')
class ComponentShopAdminOrderProducts extends BunnyElement {

    @property({type: Object})
    value: any;

    @property({type: Array})
    get fields() {
        return [
            {
                cellProperties: {
                    style: 'border-bottom: solid rgba(32, 32, 32, .5) 1px',
                },
                header: false,
                cell: {
                    component: 'div',
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 7; --col: 8',
                    field: 'name',
                },
                header: {
                    properties: {
                        textContent: 'Product',
                    },
                },
                cell: {
                    component: 'component-data-collection-list-item-cell-order-item-name',
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 1; --col: 4',
                    field: 'quantity',
                    type: 'text',
                },
                header: {
                    properties: {
                        textContent: 'Qty',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 1; --col: 4',
                    field: 'productPrice',
                    type: 'currency',
                },
                header: {
                    properties: {
                        textContent: 'Each',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 3; --col: 4',
                    field: 'subTotal',
                    type: 'currency',
                },
                header: {
                    properties: {
                        textContent: 'Total',
                    },
                },
            },
        ];
    }


    newProduct = {
        quantity: '1',
        product: null as any,
    };

    @property({type: Object})
    @computed('value')
    get order() {
        let path = this.value?._ref?.path;
        if (!path) return undefined;

        return new SurrealDocument<ShopOrderDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [path],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: Object})
    @computed('order')
    get orderItems() {
        return Object.values(this.order?.data?.items || {});
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-shop-price {
                display: inline-block;
            }
        `,
    ];

    renderPriceSummary(label: string, value?: number, hideEmpty = true) {
        if (!value && hideEmpty) return;

        return html`
            ${label} &middot;
            <component-shop-price .value="${value}"></component-shop-price>
            <br>
        `;
    }

    override render() {
        let orderPrice = this.value?.price;

        return html`
            <component-firestore-collection-list .fields="${this.fields}" .items="${this.orderItems || []}">
            </component-firestore-collection-list>
            <div class="gridContainer">
                <div style="--col-md: 7; --col: 8"></div>
                <div style="--col-md: 1; --col: 4">${this.value?.itemsTotal?.quantity}</div>
                <div style="--col-md: 1; --col: 4; text-align: right">
                </div>
                <div style="--col-md: 3; --col: 4">
                    ${this.renderPriceSummary('Sub total', orderPrice?.subTotal, false)}
                    ${this.renderPriceSummary('Paid', orderPrice?.paid, false)}
                    ${this.renderPriceSummary('Discount', orderPrice?.discount)}
                    ${this.renderPriceSummary('Refunded', orderPrice?.refunded)}
                    ${this.renderPriceSummary('Total', orderPrice?.total, false)}
                </div>

                ${['draft', 'pending'].includes(this.value?.state) ? html`
                    <div style="--col-md: 7; background-color: rgba(32, 32, 32, .1);">
                        <component-input-surreal-select id="newProductSelect"
                                                        .value="${this.bindDeep('newProduct.product')}"
                                                        .path="${FIRESTORE_COLLECTION_SHOP_PRODUCTS}"
                                                        .valueField="${'variants.0._ref'}"
                                                        .fetches="${['variants']}"
                                                        label="Product"></component-input-surreal-select>
                    </div>
                    <div style="--col-md: 2; background-color: rgba(32, 32, 32, .1);">
                        <component-input-number label="Quantity"
                                                .value="${this.bindDeep('newProduct.quantity')}"></component-input-number>
                    </div>
                    <div style="--col-md: 3; background-color: rgba(32, 32, 32, .1); color: white">
                        <component-button style="min-width: 0; width: 100%; margin: 10px"
                                          @click="${this.addProduct}">
                            Add
                        </component-button>
                    </div>
                ` : undefined}
            </div>
        `;
    }

    @toastProgressWrapper({progressMessage: 'Adding product', successMessage: 'Added product'})
    @confirmationDialog({
        body: function (this: ComponentShopAdminOrderProducts) {
            let options = (this.shadowRoot?.getElementById('newProductSelect') as ComponentInputSurrealSelect).options;
            let selectedOption = options.find((_: any) => _.value.path === this.newProduct.product.path);

            return `Are you sure you want to add ${this.newProduct.quantity} of the product ${(selectedOption as any)?.label}`;
        },
    })
    async addProduct() {
        await internalAdminCall('ShopAddToOrder', {
            orderId: this.value._ref.id,
            quantity: parseInt(this.newProduct.quantity as string),
            variantPath: this.newProduct.product.path,
        });
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-admin-order-products': ComponentShopAdminOrderProducts;
    }
}