import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';
import {CompetitionDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-aspire-comps-livestream-manage')
class ComponentAspireCompsLivestreamManage extends BunnyElement {

    @property({type: Object, notify: true})
    currentCompetition: CompetitionDocument | undefined;

    @property({type: Object, notify: true})
    nextUpCompetition: CompetitionDocument | undefined = undefined;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .tickets component-button {
                background: var(--primary-color);
                color: white;
                text-align: center;
            }
        `,
    ];

    override render() {
        return html`
            <div style="margin-bottom: 25px">
                <h1 style="display: flex">
                    Current competition: ${this.currentCompetition?.name}

                    <component-button ?disabled="${!this.hasWinners(this.currentCompetition)}"
                                      style="margin: auto; margin-right: 0; font-size: 18px"
                                      @click="${this.finishCompetition}">
                        Finish
                    </component-button>
                </h1>

                <div class="tickets">
                    <h2>Winning tickets</h2>
                    ${this.currentCompetition?.winningTickets?.map(item => html`
                        <component-button @click="${(_: MouseEvent) => this.removeTicket(item.number)}">
                            Remove<br>
                            ${item.number}
                        </component-button>
                    `)}

                    <component-button @click="${(_: MouseEvent) => this.addTicket()}"
                                      style="background-color: var(--attention-color)">
                        Add<br>
                        ticket
                    </component-button>
                </div>
            </div>


            <component-aspire-comps-livestream-up-next
                    .current="${this.bind.currentCompetition}"
                    .nextUpCompetition="${this.bind.nextUpCompetition}"></component-aspire-comps-livestream-up-next>


            <div>
                <h3>
                    Sneaky peak
                </h3>
                ${this.currentCompetition?.winningTickets?.map(item => html`
                    <div>
                        ${item.number} - ${item.name}
                    </div>
                `)}
            </div>
        `;
    }

    @toastProgressWrapper()
    async finishCompetition() {
        if (!this.currentCompetition?._ref) throw new FriendlyMessage('No competition');
        if (!confirm(`Are you sure you want to finish this comp`)) return;


        await callableQuery('__internal::updateDoc')(this.currentCompetition?._ref.surrealId, {
            finished: true,
        } as Partial<CompetitionDocument>);
    }

    @toastProgressWrapper()
    async removeTicket(number: number) {
        if (!this.currentCompetition?._ref) throw new FriendlyMessage('No competition');
        if (!confirm(`Are you sure you want to remove ${number}`)) return;


        await adminCall.competitionsManageWinner(
            this.currentCompetition._ref.id,
            'remove',
            number,
        );
    }

    @toastProgressWrapper()
    async addTicket(number?: number) {
        if (!this.currentCompetition?._ref) throw new FriendlyMessage('No competition');

        number ??= parseInt(prompt('Enter ticket number') as string, 10);
        if (!number) throw new FriendlyMessage('No ticket number was supplied');

        await adminCall.competitionsManageWinner(
            this.currentCompetition._ref.id,
            'add',
            number,
        );
    }

    hasWinners(currentCompetition?: CompetitionDocument) {
        return currentCompetition?.winningTickets?.length || 0 > 0;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-aspire-comps-livestream-manage': ComponentAspireCompsLivestreamManage;
    }
}