import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {adminCall} from '../../../admin/local/helpers/AdminHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {AccountShopContactDetailDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-admin-auth-shop-contact-details')
class ComponentAdminAuthShopContactDetails extends BunnyElement {

    @property({type: String})
    accountId: string;

    @property({type: Array})
    contactDetails: { _id: string, data: AccountShopContactDetailDocument }[];

    @property({type: Boolean})
    applyToRecentOrders = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            ${this.contactDetails?.map(item => html`
                <component-admin-auth-shop-contact-details-item
                        .item="${item}"
                        .accountId="${this.accountId}"
                ></component-admin-auth-shop-contact-details-item>
            `)}
        `;
    }

    @observe('accountId')
    async loadAccountContactDetails(accountId: string) {
        if (!accountId) return;

        this.contactDetails = await adminCall.shopGetAccountContactDetails(accountId);
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-admin-auth-shop-contact-details': ComponentAdminAuthShopContactDetails;
    }
}