import {customElement, query} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {AccountShopContactDetailDocument} from '../../../../utils/DatabaseTypes.ts';


@customElement('component-shop-customer-new-contact')
export class ComponentShopCustomerNewContact extends BunnyElement {

    @property({type: Boolean})
    enterAddressManually: boolean = true;

    @property({type: Object, notify: true})
    value: AccountShopContactDetailDocument = {
        firstName: '',
    } as any;

    @query('#form')
    formElement: HTMLFormElement;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            [data-enter-address] {
                position: relative;
            }

            [data-enter-address="0"] .manualAddress,
            [data-enter-address="1"] .autoAddress {
                position: absolute;
                pointer-events: none;
                opacity: 0;
            }

            #form {
                component-input, component-input-phone, component-input-email {
                    margin-top: 15px;
                }
            }
        `,
    ];

    override render() {
        return html`
            <form id="form" class="gridContainer row" data-enter-address="${this.enterAddressManually ? 1 : 0}"
                  style="--col-margin-scale: 0;">
                <h3>About you</h3>
                <component-input
                        style="--col: 6"
                        name="firstName"
                        label="First name"
                        always-float-label
                        required
                        autocomplete="given-name"
                        .value="${this.bindDeep('value.firstName')}"
                ></component-input>
                <component-input
                        style="--col: 6"
                        name="lastName"
                        label="Last name"
                        always-float-label
                        required
                        autocomplete="family-name"
                        .value="${this.bindDeep('value.lastName')}"
                ></component-input>
                <component-input-phone
                        name="phoneNumber"
                        type="tel"
                        label="Phone"
                        always-float-label
                        required
                        minlength="8"
                        maxlength="15"
                        autocomplete="tel"
                        .value="${this.bindDeep('value.phone')}"
                ></component-input-phone>
                <component-input-email
                        name="emailAddress"
                        type="email"
                        label="Email address"
                        always-float-label
                        required
                        autocomplete="email"
                        .value="${this.bindDeep('value.emailAddress')}"
                ></component-input-email>

                <h3 style="display: flex; margin-top: 50px">
                    Address
                        <!--                    <component-input-checkbox checked="${this.enterAddressManually}"-->
                    <!--                                    style="font-weight: normal; margin-left: auto; font-size: 16px; text-transform: none">-->
                    <!--                        Enter address manually?-->
                    <!--                    </paper-checkbox>-->
                </h3>
                <div class="gridContainer">
                    <component-input
                            style="--col: 8"
                            class="autoAddress"
                            name="country"
                            label="Country"
                            always-float-label
                            required
                            disabled
                            autocomplete="country"
                            .value="${this.bindDeep('value.country')}"
                    ></component-input>
                    <component-input
                            style="--col: 4"
                            class="autoAddress"
                            name="postcode"
                            label="Postcode"
                            always-float-label
                            required
                            autocomplete="postal-code"
                            .value="${this.bindDeep('value.zip')}"
                    ></component-input>
                </div>

                <component-input
                        class="manualAddress"
                        name="addressLine1"
                        label="Street address"
                        always-float-label
                        required
                        placeholder="House number and street name"
                        autocomplete="address-line1"
                        .value="${this.bindDeep('value.address1')}"
                ></component-input>
                <component-input
                        class="manualAddress"
                        name="addressLine2"
                        label="Street address 2 (optional)"
                        always-float-label
                        placeholder="Apartment, suite, unit, etc"
                        autocomplete="address-line2"
                        .value="${this.bindDeep('value.address2')}"
                ></component-input>
                <component-input
                        class="manualAddress"
                        name="city"
                        label="Town / City"
                        always-float-label
                        required
                        autocomplete="address-level2"
                        .value="${this.bindDeep('value.city')}"
                ></component-input>
                <component-input
                        class="manualAddress"
                        name="county"
                        label="County (optional)"
                        always-float-label
                        autocomplete="address-level1"
                        .value="${this.bindDeep('value.province')}"
                ></component-input>
                <component-input
                        class="manualAddress"
                        name="postcode"
                        label="Postcode"
                        always-float-label
                        required
                        autocomplete="postal-code"
                        .value="${this.bindDeep('value.zip')}"
                ></component-input>
                <component-input
                        class="manualAddress"
                        name="country"
                        label="Country"
                        always-float-label
                        required
                        disabled
                        autocomplete="country"
                        .value="${this.bindDeep('value.country')}"
                ></component-input>
            </form>
        `;
    }

    validate() {
        return this.formElement.reportValidity();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-customer-new-contact': ComponentShopCustomerNewContact;
    }
}