import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {PageContentComponent} from '../../../routing/local/pages/routing-page';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ShopProductVariant__saleMatrix} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-input-shop-sale-matrix')
class ComponentInputShopSaleMatrix extends BunnyElement {

    @property({type: String})
    label: string;

    @property({type: Object})
    value: ShopProductVariant__saleMatrix;

    @property({type: Object})
    data: any;

    @property({type: Object})
    rulesComponent: PageContentComponent = {
        component: 'component-input-shop-sale-matrix-rule',
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .field {
                margin-top: 0;

                & + .field {
                    margin-left: 5px;
                }
            }
        `,
    ];

    override render() {
        return html`
            <label>
                ${this.label}
            </label>

            <div style="display: flex">
                <component-input-select label="Modifier" .value="${this.bindDeep('value.modifier')}" class="field">
                    <component-input-select-item value="value">value</component-input-select-item>
                    <component-input-select-item value="reduceValue">reduceValue</component-input-select-item>
                    <component-input-select-item value="percentage">percentage</component-input-select-item>
                    <component-input-select-item value="reducePercentage">reducePercentage</component-input-select-item>
                </component-input-select>

                ${this._isPercentage(this.value?.modifier) ? html`
                    <component-input-percentage label="Value"
                                                .value="${this.bindDeep('value.value')}"
                                                class="field"></component-input-percentage>
                ` : html`
                    <component-input-number label="Value"
                                            .value="${this.bindDeep('value.value')}"
                                            class="field"></component-input-number>
                `}
            </div>

            <component-input-array label="Rules"
                                   .component="${this.rulesComponent}"
                                   .value="${this.value?.rules}"
                                   .data="${this.data}"
                                   .newItemValue="${{}}"
                                   style="margin-top: 5px"></component-input-array>
        `;
    }

    _isPercentage(modifier?: string) {
        return (modifier || '').toLowerCase().includes('percentage');
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-shop-sale-matrix': ComponentInputShopSaleMatrix;
    }
}