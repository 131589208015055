import {format, formatDistanceToNow} from 'date-fns';

export const RenderingHelper = {
    _currencyFormat(value: number) {
        return (value / 100).toFixed(2);
    },

    _dateFromNow(value?: null | Date) {
        if (!value) return 'Just now';

        return formatDistanceToNow(value, {addSuffix: true, includeSeconds: true}).replace('about', '').trim();
    },

    _dateFormat(value: null | undefined | Date, formatString: string) {
        if (!value) return 'Just now';

        return format(value, formatString);
    },

    _ucFirst(val?: string) {
        if (!val) return val;

        return val[0].toUpperCase() + val.substr(1);
    },

    __getAccountPermissions(account: any) {
        if (!account) {
            account = {};

            Object.defineProperty(account, 'permissions', {
                value: [
                    'app.loggedout',
                ],
            });
        }

        return account.permissions || [];
    },

    _accountHasPermission(account: any, permission: string) {
        let accountPermissions = RenderingHelper.__getAccountPermissions(account);

        return accountPermissions.includes(permission);
    },

    _accountHasPermissions(account: any, permissions: string[]) {
        let accountPermissions = RenderingHelper.__getAccountPermissions(account);

        return RenderingHelper._accountPermissionsHasPermissions(accountPermissions, permissions);
    },

    _accountPermissionsHasPermissions(accountPermissions: string[], permissions: string[]) {
        for (let permission of permissions) {
            if (!accountPermissions.includes(permission)) return false;
        }

        return true;
    },

    _colorFromString(val: string) {
        val = val.toLowerCase().replace(/[^a-z0-9]/, '');
        let seed = parseInt(val, 36);

        return '#' + Math.floor((Math.abs(Math.sin(seed) * 16777215)) % 16777215).toString(16);
    },
};