import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {config} from '../../../../config';

const getFirebaseMessagingServiceWorker = () => {
    return navigator.serviceWorker.register('/firebase-messaging-sw.js', {scope: '/firebase-cloud-messaging-push-scope'});
};

let firebaseAppPromise: Promise<any>;
const getFirebaseApp = () => {
    if (!firebaseAppPromise) {
        firebaseAppPromise = import('firebase/app');
    }


    return firebaseAppPromise;
};

let firebaseMessagingPromise: Promise<any>;
const getFirebaseMessaging = () => {
    if (!firebaseMessagingPromise) {
        firebaseMessagingPromise = import('firebase/messaging');
    }


    return firebaseMessagingPromise;
};

let instance: ComponentFirebaseMessaging;

@customElement('component-firebase-messaging')
export class ComponentFirebaseMessaging extends BunnyElement {

    static getInstance() {
        return instance;
    }

    @property({type: Object})
    messaging: any;

    override render() {
        return html`
        `;
    }


    connectedCallback(): void {
        super.connectedCallback();

        instance = this;
        this.init();
    }

    async init() {
        if (!await (await getFirebaseMessaging()).isSupported()) {
            console.warn('Not initing firebase messaging due to unsupported browser');
            return;
        }

        try {
            let firebaseApp = (await getFirebaseApp()).initializeApp(config.google.firebase);
            this.messaging = (await getFirebaseMessaging()).getMessaging(firebaseApp);
            this.messaging.vapidKey = config.google.firebase.vapidKey;

        } catch (e) {
            console.error('Failed initing firebase messaging', e);
        }
    }

    async getToken() {
        if (!await (await getFirebaseMessaging()).isSupported()) {
            console.warn('Not initing firebase messaging due to unsupported browser');
            return;
        }

        // prefetch the service worker cus calling getToken without a service worker fails with the push manager so requires double requesting, or handle our side
        await getFirebaseMessagingServiceWorker();

        return await (await getFirebaseMessaging()).getToken(this.messaging, {
            vapidKey: config.google.firebase.vapidKey,
        });
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firebase-messaging': ComponentFirebaseMessaging;
    }
}