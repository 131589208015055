import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper';
import {shopCall} from '../helpers/ShopHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {SurrealDocument} from '../../../__internal/local/controllers/SurrealDocument.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {ENV} from '../../../../config.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';
import {
    FIRESTORE_COLLECTION_SHOP_ORDERS,
    FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_NOTES,
    ShopOrder__billingAddress,
    ShopOrder__shippingAddress,
    ShopOrderDocument,
} from '../../../../utils/DatabaseTypes.ts';

const PAYMENT_HANDLER_NAME_REMAP: {
    [key: string]: string
} = {
    Checkoutcom: 'Card',
};

@customElement('component-shop-order-overview')
class ComponentShopOrderOverview extends BunnyElement {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    @computed('route')
    get action() {
        return this.route?.current.query?.action;
    };

    @property({type: String})
    orderId: string;

    @property({type: Object})
    @computed('orderId')
    get order() {
        if (!this.orderId) return undefined;

        return new SurrealDocument<ShopOrderDocument>(
            this,
            '__internal::loadFirestoreDocument',
            [`${FIRESTORE_COLLECTION_SHOP_ORDERS}/${this.orderId}`],
            {method: FetchMethod.LIVE},
        );
    };

    @property({type: Object})
    @computed('order')
    get orderItems() {
        return Object.values(this.order?.data?.items || {});
    };

    @storageBoundQueryString('paymentReturn')
    paymentReturn: string;

    @property({type: Array})
    get itemsFields() {
        return [
            {
                cellProperties: {
                    style: 'border-bottom: solid rgba(32, 32, 32, .5) 1px',
                },
                header: false,
                cell: {
                    component: 'div',
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 9; --col: 9',
                    field: 'name',
                },
                header: {
                    properties: {
                        textContent: 'Product',
                    },
                },
                cell: {
                    component: 'component-data-collection-list-item-cell-order-item-name',
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 1; --col: 6; text-align: right',
                    field: 'quantity',
                    type: 'text',
                },
                header: {
                    properties: {
                        textContent: 'Qty',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 2; --col: 6',
                    field: 'subTotal',
                    type: 'currency',
                },
                header: {
                    properties: {
                        textContent: 'Total',
                    },
                },
            },
        ];
    }

    @property({type: Object})
    notesFilters: any = {type: 'customer'};

    @property({type: Array})
    get notesFields() {
        return [
            {
                cellProperties: {
                    style: '--col-md: 8',
                    field: 'body',
                },
                header: {
                    properties: {
                        textContent: 'Message',
                    },
                },
            },
            {
                cellProperties: {
                    style: '--col-md: 4',
                    field: 'created',
                    type: 'datetime',
                },
                header: {
                    properties: {
                        textContent: 'Created',
                    },
                },
            },
        ];
    }

    @property({type: Object})
    orderCheckingState: {
        message: string,
        icon: string,
        color: string
    } | undefined;

    renderAddress(heading: string, address: ShopOrder__billingAddress | ShopOrder__shippingAddress | undefined, missingMessage: string, style?: string) {
        return html`
            <div style="--col-md: 6">
                <div class="contentStep contentStepContainsContact" style="${style}">
                    <h3>${heading}</h3>

                    ${address?.address1 ? html`
                        <component-shop-customer-contact-card
                                .contact="${address}"
                                active
                                disabled></component-shop-customer-contact-card>
                    ` : html`
                        <p style="text-align: center; padding: 25px; margin: 0">
                            ${missingMessage}
                        </p>
                    `}
                </div>
            </div>
        `;
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-shop-customer-contact-card {
                margin: 0;
                max-width: 100%;
                display: block;
                border-radius: 0;
                --shadow-elevation-2dp-box-shadow: none;
            }

            .contentStep h2, .contentStep h3 {
                background-color: var(--primary-text-color);
                color: white;
                margin-left: -15px;
                margin-right: -15px;
                margin-top: -25px !important;
                padding: 15px;
                text-align: center;
            }

            .contentStepContainsContact {
                padding: 0;
            }

            .contentStepContainsContact h2, .contentStepContainsContact h3 {
                margin: 0;
                margin-top: 0 !important;
            }
        `,
    ];

    override render() {
        let completed = this.order?.data?.completed;

        return html`
            ${this.orderCheckingState ? html`
                <div class="contentStep"
                     style="display: flex; font-weight: bold; background-color: ${this.orderCheckingState.color}; color: white; padding: 15px; transition: .125s">
                    <component-icon icon="${this.orderCheckingState.icon}"
                                    style="margin-right: 15px; width: 30px; height: 30px;"></component-icon>
                    <p style="margin: 0; line-height: 30px">
                        ${this.orderCheckingState.message}
                    </p>
                </div>
            ` : undefined}

            <div class="contentStep contentContainer" style="max-width: 700px; margin: auto; margin-bottom: 25px">
                <h2>
                    Want to get notified?
                </h2>
                <p>
                    Notify me about live draws starting, new comps being launched, last minute great odds alerts and
                    random one off discounts! Get in you badgers. Woo hoo
                </p>
                <a href="/account/notifications/preferences?addChannel=sms&addChannelMeta=${encodeURIComponent(this.order?.data?.contact.phone as string)}">
                    <component-button raised
                                      style="background-color: var(--primary-color); color: white; width: 100%; margin: 0">
                        Notification preferences
                    </component-button>
                </a>
            </div>

            <component-shop-order-thank-you style="margin-bottom: 50px"></component-shop-order-thank-you>

            <div class="contentStep orderInfo">
                <h2>Order #${this.order?.data?.orderNumber}</h2>
                <div>
                    <div class="orderPlaced">
                        Placed &middot; ${RenderingHelper._dateFormat(this.order?.data?.created, 'dd/MM/yyyy HH:mm')}
                    </div>
                    <div class="orderState">
                        State &middot; ${this.order?.data?.state}
                    </div>
                    ${completed ? html`
                        <div class="orderCompleted">
                            Completed &middot; ${RenderingHelper._dateFormat(completed, 'dd/MM/yyyy HH:mm')}
                        </div>
                    ` : undefined}
                </div>
            </div>

            <div class="contentStep">
                <h3>Order details</h3>

                <component-firestore-collection-list .fields="${this.itemsFields}"
                                                     .items="${this.orderItems || []}"
                                                     style="margin-left: -15px; margin-right: -15px"
                >
                </component-firestore-collection-list>
                <div class="gridContainer row"
                     style="background-color: rgba(32, 32, 32, .03); margin-bottom: -25px; padding-top: 15px">
                    <div style="--col-md: 10; --col: 6; text-align: right; font-weight: bold">
                        Subtotal
                    </div>
                    <component-shop-price style="--col-md: 2; --col: 6;" value="${this.order?.data?.price.subTotal}">
                    </component-shop-price>

                    ${this.order?.data?.price.discount ? html`
                        <div style="--col-md: 10; --col: 6; text-align: right; font-weight: bold">
                            Discount
                        </div>
                        <component-shop-price style="--col-md: 2; --col: 6; text-indent: -3px"
                                              .value="${this.order?.data.price.discount}">
                        </component-shop-price>
                    ` : undefined}

                    ${this.order?.data?.price.shipping ? html`
                        <div style="--col-md: 10; --col: 6; text-align: right; font-weight: bold">
                            Shipping
                        </div>
                        <component-shop-price style="--col-md: 2; --col: 6;"
                                              .value="${this.order.data.price.shipping}">
                        </component-shop-price>
                    ` : undefined}

                    <div style="--col-md: 10; --col: 6; text-align: right; font-weight: bold">
                        Payment method
                    </div>
                    <div style="--col-md: 2; --col: 6;">
                        ${this._remapPaymentHandlerName(this.order?.data?.paymentHandler)}
                    </div>

                    <div style="--col-md: 10; --col: 6; text-align: right; font-weight: bold">
                        Total
                    </div>
                    <component-shop-price style="--col-md: 2; --col: 6;"
                                          .value="${this.order?.data?.price.total}">
                    </component-shop-price>
                </div>
            </div>
            <div class="gridContainer row">
                ${this.renderAddress(
                        'Billing contact',
                        this.order?.data?.billingAddress,
                        'Not yet supplied',
                )}

                ${this.renderAddress(
                        'Shipping contact',
                        this.order?.data?.shippingAddress,
                        'Not required',
                        this.order?.data?.requiresShipping ? '' : 'opacity: .6;',
                )}
            </div>

            <div class="contentStep">
                <h3>Notes</h3>

                ${this.orderId ? html`
                    <component-firestore-collection-list
                            .path="${FIRESTORE_COLLECTION_SHOP_ORDERS}/${this.orderId}/${FIRESTORE_COLLECTION_SHOP_ORDERS_SUB_NOTES}"
                            .fields="${this.notesFields}"
                            .fetchMethod="${'live'}"
                            .filters="${this.notesFilters}"
                            .order="${[['created', 'asc']]}">
                    </component-firestore-collection-list>
                ` : ''}
            </div>


            <component-dialog .opened="${this.action === 'cancel'}">
                <h2 slot="heading">Are you sure?</h2>
                <p>
                    You are about to cancel this order, are you sure you want to do this as it will be permanently
                    closed
                </p>

                <component-button slot="footer" @click="${this.closeOrder}"
                                  style="background-color: var(--attention-color)">
                    Yes, Im sure
                </component-button>
                <a slot="footer" href="/account/shop/order-history">
                    <component-button>Cancel</component-button>
                </a>
            </component-dialog>
        `;
    }

    _encodeURIComponent(value: any) {
        return encodeURIComponent(value);
    }

    @toastProgressWrapper({
        progressMessage: 'Closing order',
        successMessage: 'Order closed',
        failedMessage: 'Failed closing order: {{e}}',
    })
    async closeOrder() {
        await shopCall.cancelDraftOrder(this.orderId);

        HistoryHelper.replaceState(`/account/shop/order-history`);
    }

    @observe('order', 'orderItems', 'paymentReturn')
    async trackPurchaseComplete(order: SurrealDocument<ShopOrderDocument> | undefined, orderItems: any[], paymentReturn: string) {
        if (paymentReturn !== 'success' || !order?.data || !orderItems?.length) return;

        let sessionTrackingKey = `__shopOrderTrackComplete-${order.data._ref?.id}`;
        if (sessionStorage[sessionTrackingKey]) return;
        sessionStorage[sessionTrackingKey] = true;

        track('shopPurchase', {
            order: order.data,
            orderId: order.data._ref?.id as string,
            items: orderItems.map(_ => ({
                id: _.product.id,
                quantity: _.quantity,
                name: _.name,
                categories: _.productCategory,
                productPrice: _.productPrice / 100,
                subTotal: _.subTotal / 100,
                discount: _.discount / 100,
                wasOnSale: _.wasOnSale,
            })),
            value: order.data.price.total / 100,
            currency: 'GBP',
        });


        if ((order.data.meta as any).facebookLanding) {
            //if theres fb meta on the order then try a fb conversion
            await shopCall.fbConversion(this.orderId, location.href);
        }
    }

    @observe('order')
    async checkOrderState(order?: SurrealDocument<ShopOrderDocument>) {
        let state = order?.data?.state;

        if (this.orderCheckingState) {
            if (state === 'completed') {
                this.orderCheckingState = {
                    icon: 'icons:done',
                    message: 'Order complete',
                    color: 'var(--primary-color)',
                };
            }

            return;
        }

        if (state === 'pending') {
            this.orderCheckingState = {
                icon: 'icons:hourglass-empty',
                message: 'Checking order state',
                color: 'var(--attention-color)',
            };


            let serverOrder = await shopCall.attemptOrderCompletion(this.orderId, this.route.current.query);
            if (ENV === 'local') {
                if (serverOrder.order?.state === 'pending') {
                    await delayPromise(500);
                    serverOrder = await shopCall.attemptOrderCompletion(this.orderId, this.route.current.query);

                    if (serverOrder.order?.state === 'pending') {
                        await delayPromise(500);
                        serverOrder = await shopCall.attemptOrderCompletion(this.orderId, this.route.current.query);

                        if (serverOrder.order?.state === 'pending') {
                            await delayPromise(500);
                            serverOrder = await shopCall.attemptOrderCompletion(this.orderId, this.route.current.query);
                        }
                    }
                }
            }

            if (serverOrder.order?.state === 'pending') {
                this.orderCheckingState = {
                    icon: 'icons:payment',
                    message: 'Order pending payment',
                    color: 'var(--attention-color)',
                };
            }
        }
    }

    _remapPaymentHandlerName(paymentHandler?: string) {
        if (!paymentHandler) return paymentHandler;

        return PAYMENT_HANDLER_NAME_REMAP[paymentHandler] || paymentHandler;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-order-overview': ComponentShopOrderOverview;
    }
}