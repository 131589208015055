import {customElement} from 'lit/decorators.js';
import {ComponentDataCollectionList} from './component-data-collection-list';
import {property} from '../helpers/decorators/PropertyDecoratorHelper';
import {observe} from '../helpers/decorators/ObserveDecoratorHelper';
import {SurrealCollection} from '../controllers/SurrealCollection';
import {SurrealData} from '../controllers/SurrealData';
import {html} from 'lit';
import {computed} from '../helpers/decorators/ComputedDecotratorHelper';
import {REGISTERED_SCROLL_POSITIONS} from '../../../routing/local/pages/routing-page.ts';
import {Route} from '../../../routing/local/controllers/Route.ts';


@customElement('component-firestore-collection-list')
export class ComponentFirestoreCollectionList extends ComponentDataCollectionList {

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: Object})
    @computed('path', 'namedQuery', 'fetches', 'aggs', 'filters', 'controlFilters', 'order', 'defaultOrder', 'limit', 'start')
    get _internalItems() {
        if (!this.path) return;


        let queryWhere = [];
        let filters = {...this.filters, ...this.controlFilters};
        for (let key in filters) {
            let filter = filters[key];
            if (Array.isArray(filter)) {
                queryWhere.push({fieldPath: key, opStr: filter[0], value: filter[1]});

            } else {
                queryWhere.push({fieldPath: key, opStr: '==', value: filter});
            }
        }


        let order = this.order;
        let defaultOrder = this.defaultOrder;
        let queryOrder = [];
        for (let key in order) {
            queryOrder.push({fieldPath: order[key][0], directionStr: order[key][1]});
        }

        if (!order.length && defaultOrder) {
            if (defaultOrder.length && typeof defaultOrder[0] === 'string') {
                defaultOrder = [defaultOrder as any];
            }

            for (let key in defaultOrder) {
                queryOrder.push({fieldPath: defaultOrder[key][0], directionStr: defaultOrder[key][1]});
            }
        }


        return new SurrealCollection(
            this,
            this.namedQuery,
            [
                this.path,
                {
                    fetch: this.fetches,
                    aggs: this.aggs,
                    limit: this.limit,
                    start: this.start,
                    orderBy: queryOrder,
                    where: queryWhere,
                },
            ],
            {
                method: SurrealData.resolveFetchMethod(this.fetchMethod),
            },
        );
    }

    @property({type: String})
    namedQuery = '__internal::loadFirestoreCollection';

    @property({type: String})
    path: string;

    @property({type: Array})
    aggs: string[];

    @property({type: Number, notify: true})
    start: number = 0;

    @property({type: Array})
    fetches: string[];

    @property({type: String})
    fetchMethod: string = 'networkFirst';

    @property({type: Object})
    queryDb: any | null;

    // language=HTML
    static get dataProvider() {
        return html`
            <!--            <firestore-document db="{{queryDb}}"></firestore-document>-->
            <!--            <firestore-document path="{{path}}" data="{{items}}"-->
            <!--                                query-extend-function="{{queryExtendFiltered}}"-->
            <!--                                fetch-method="{{fetchMethod}}"-->
            <!--                                loading="{{loadingItems}}"></firestore-document>-->
        `;
    }

    connectedCallback() {
        super.connectedCallback();

        REGISTERED_SCROLL_POSITIONS['collection-list'] = this;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        delete REGISTERED_SCROLL_POSITIONS['collection-list'];
    }

    @observe('_internalItems')
    reflect_internalItemsIntoItems(_internalItems: SurrealCollection) {
        if (!_internalItems) return;

        if (_internalItems.data) {
            this.items = _internalItems.data;
        }

        this.loadingItems = _internalItems.loading;
    }

    @observe('filters', 'limit', 'controlFilters')
    resetStartOnQueryChanged() {
        if (!this._internalItems) return;

        this.start = 0;
    }

    @observe('start')
    scrollToTopOnPageChange() {
        if (!this._internalItems) return; // if not set then its initial load rather than page change

        let position = this.content.getBoundingClientRect();
        window.scrollTo(0, window.scrollY + position.y - 75);
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-firestore-collection-list': ComponentFirestoreCollectionList;
    }
}