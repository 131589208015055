import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../../../auth/local/controllers/Auth';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection.ts';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData.ts';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {
    AccountShopContactDetailDocument,
    FIRESTORE_COLLECTION_ACCOUNTS,
    FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_CONTACT_DETAILS,
} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-my-account-addresses')
class ComponentShopMyAccountAddresses extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: Array})
    @computed('auth')
    get contacts() {
        return new SurrealCollection<AccountShopContactDetailDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                `${FIRESTORE_COLLECTION_ACCOUNTS}/${this.auth.user?.uid}/${FIRESTORE_COLLECTION_ACCOUNTS_SUB_SHOP_CONTACT_DETAILS}`,
                {},
            ],
            {method: FetchMethod.LIVE},
        );
    };

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
        `,
    ];

    override render() {
        return html`
            <p>
                Here are your saved addresses on this account, if you would like to add a new address you can do this
                during checkout
            </p>


            <component-loading .loading="${this.contacts?.loading ?? true}">
                <component-shop-customer-contact-card-collection .contacts="${this.contacts?.data}"
                                                                 .viewingOnly="${true}">
                </component-shop-customer-contact-card-collection>
            </component-loading>

        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-my-account-addresses': ComponentShopMyAccountAddresses;
    }
}