import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {Auth} from '../controllers/Auth';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper';
import {FriendlyMessage} from '../../../__internal/shared/helpers/ExceptionHelper';
import {loadUser} from '../../../__internal/local/helpers/SurrealHelper.ts';

@customElement('component-account-manage-auth')
class ComponentAccountManageAuth extends BunnyElement {

    @property({notify: true})
    auth = Auth.getInstance(this);

    @property({type: String})
    title: string = 'Link with';

    @property({type: Object})
    @computed('auth')
    get user() {
        return this.auth.getInternalUser();
    }

    @property({type: Array})
    @computed('user')
    get authMethods() {
        return [
            {
                id: 'google',
                visible: true,
            },
            {
                id: 'facebook',
                visible: true,
            },
            {
                id: 'password',
                visible: false,
            },
        ].map(authMethod => ({
            ...authMethod,
            active: (this.user && !!this.user.authMethods.find(_ => _.type === authMethod.id)) as boolean,
        }));
    }

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-button {
                overflow: hidden;
                margin-top: 15px;
                display: flex;
                align-items: center;
            }

            component-button[data-active="1"] {
                background-color: var(--primary-text-color);
            }

            component-button component-icon {
                background-color: white;
                padding: 15px;
                margin: -0.7em -0.57em;
                margin-right: 15px;
            }
        `,
    ];

    override render() {
        return html`
            <div class="contentStep">
                ${this.title ? html`
                    <h2 style="margin-bottom: 0">${this.title}</h2>
                ` : undefined}
                <p style="margin-bottom: 25px; margin-top: 0">
                    Link with external platforms to allow you to sign in with
                </p>


                ${this.authMethods?.filter(_ => _.visible)
                        ?.map(item => html`
                            <component-button @click="${(_: MouseEvent) => this.toggleLink(item.id, item.active)}"
                                              data-active="${item.active ? 1 : 0}">
                                <component-icon icon="app:${item.id}"></component-icon>
                                ${item.id}

                                ${item.active ? 'connected' : 'connect'}
                            </component-button>
                        `)}
            </div>
        `;
    }

    @toastProgressWrapper({
        progressMessage: 'Connecting auth',
        successMessage: 'Connected auth',
        failedMessage: 'Failed connecting auth: {{e}}',
    })
    async connectAuth(method: string) {
        await this.auth.linkWithPopup(method);
    }

    @confirmationDialog({
        title: 'Are you sure you want to disconnect',
        successButtonText: 'Disconnect',
        failButtonText: 'Cancel',
        body: function (method: string) {
            return `Disconnecting ${method} will mean you are no longer able to login with ${method}`;
        },
    })
    @toastProgressWrapper({
        progressMessage: 'Disconnecting auth',
        successMessage: 'Disconnected auth',
        failedMessage: 'Failed disconnecting auth: {{e}}',
    })
    async disconnectAuth(method: string) {
        let activeAuthMethods = this.authMethods.filter(_ => _.active).length;
        if (activeAuthMethods <= 1) throw new FriendlyMessage(`You cant disconnect ${method} yet as this is your only auth method, please connect one of the others first`);

        let authId = this.user?.authMethods.find(_ => _.type == method)?.id;
        if (!authId) throw new Error(`Unknown auth found for method ${method}`);

        await this.auth.unlink(authId);
    }

    async toggleLink(method: string, active: boolean) {
        if (active) {
            await this.disconnectAuth(method);

        } else {
            await this.connectAuth(method);
        }


        await loadUser();
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-account-manage-auth': ComponentAccountManageAuth;
    }
}