import {customElement} from 'lit/decorators.js';
import {registerEditorElement, registerEditorElementProperty} from '../../../routing/local/helpers/DecoratorHelper';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper';
import {MediaReferenceField} from '../../shared/helpers/FirebaseHelper';
import {ComponentMediaView} from './component-media-view';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';

const isSafari = () => {
    let userAgent = navigator.userAgent;

    return userAgent.includes('Safari') && !userAgent.includes('Chrome');
};

@customElement('component-media-view-video')
@registerEditorElement({
    key: 'component-media-view-video',
    name: 'Media video',
    defaultProperties: {
        width: 620,
        height: 600,
        type: 'original',
        autoPlay: 0,
    },
})
class ComponentMediaViewVideo extends ComponentMediaView {

    @property({type: Object})
    @registerEditorElementProperty({
        properties: {
            label: 'Media',
            type: 'Video',
            accept: 'video/*',
        },
        component: 'component-input-media-file',
    })
    media?: MediaReferenceField;

    @property({type: Number})
    @registerEditorElementProperty({
        properties: {
            label: 'Auto play (ms)',
            placeholder: '-1 to disable',
            type: 'number',
        },
        component: 'component-input-number',
    })
    autoPlay: number;

    @property({type: Array})
    renderedSources: { media: string, src: string, type: string }[] = [];

    override render() {
        return html`
            <video class="full"
                   poster="${this.generatePoster(this.sources)}"
                   preload="none"
                   muted
                   disableRemotePlayback
                   x-webkit-airplay="deny"
                   playsinline>
                ${this.renderedSources.map(item => html`
                    <source src="${item.src}"
                            type="${item.type}">
                `)}
            </video>

            ${super.render()}
        `;
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        for (let mediaMatcher of this.mediaMatchers) {
            mediaMatcher.onchange = null;
        }
        this.mediaMatchers = [];
    }

    generatePoster(sources: { media: string, src: string, type: string }[]) {
        return sources.find(_ => _.type.startsWith('image/'))?.src || '';
    }

    @((observe as any)('src'))
    populateSourcesFromSrc(src: string) {
        if (!src) return;

        this.sources = [
            {src: src, media: 'all', type: 'video/mp4'},
        ];
    }

    @observe('autoPlay', 'renderedSources')
    async runAutoPlay(autoPlay: number, renderedSources: { media: string, src: string, type: string }[]) {
        if (autoPlay < 0) return;
        if (!renderedSources.length) return;

        await delayPromise();
        let video = this.shadowRoot?.querySelector<HTMLVideoElement>('video');
        if (!video) return;


        video.load();
        await delayPromise(autoPlay);

        try {
            await video.play();
        } catch (e: any) {
            if (e.name !== 'NotAllowedError') {
                throw e;
            }
        }
    }

    private toggleRenderedSource(source: { media: string, src: string, type: string }, enabled: boolean) {
        if (enabled) {
            this.renderedSources.push(source);

        } else {
            let foundIndex = this.renderedSources.findIndex(_ => _.media === source.media && _.src === source.src && _.type === source.type);
            if (foundIndex >= 0) {
                this.renderedSources.splice(foundIndex, 1);
            }
        }


        this.requestUpdate('renderedSources');
    }

    private mediaMatchers: MediaQueryList[] = [];

    @observe('sources')
    generateRenderedSources(sources: { media: string, src: string, type: string }[]) {
        if (isSafari()) {
            //TODO check what versions of safari arnt crap, as it supports webm but wont stream it
            sources = sources.filter(_ => _.type !== 'video/webm');
        }


        for (let mediaMatcher of this.mediaMatchers) {
            mediaMatcher.onchange = null;
        }
        this.mediaMatchers = [];
        this.renderedSources = [];


        for (let source of sources) {
            let mediaMatcher = matchMedia(source.media);
            this.mediaMatchers.push(mediaMatcher);

            mediaMatcher.onchange = (e) => {
                this.toggleRenderedSource(source, e.matches);
            };
            this.toggleRenderedSource(source, mediaMatcher.matches);
        }
    }

    @observe('renderedSources')
    loadDimensions(renderedSources: any) {
        let firstSource = renderedSources?.[0];
        if (!firstSource) return;


        if (firstSource.width || firstSource.height) {
            this.ratio = undefined;
        }

        if (firstSource.width) {
            this.width = firstSource.width;
        }

        if (firstSource.height) {
            this.height = firstSource.height;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-media-view-video': ComponentMediaViewVideo;
    }
}
