import {CACHE_DOCUMENT_DELAYS, FetchMethod, hostlessRequest, LOADING_STATE, Options, SurrealData} from './SurrealData';
import {callableLiveQuery, callableQuery} from '../helpers/SurrealHelper';
import {RecordId} from 'surrealdb';
import {ReffableDocument} from '../../../../utils/DatabaseTypes.ts';

interface DataCollectionType {
    _ref: { id: string; path: string; surrealId: RecordId },
    _metadata: {
        hasPendingWrites: boolean,
        fromCache: boolean
    },
    _aggs?: Record<string, any>,
}

export class SurrealCollection<T extends ReffableDocument = any> extends SurrealData<T> {

    data!: T[] & DataCollectionType;

    static hostlessRequest(path: string, namedQuery: string, queryArgs: any[], options?: Options, streamUpdates?: (data: any) => void) {
        return hostlessRequest(this as any, namedQuery, queryArgs, options, streamUpdates);
    }

    async* fetchData(): AsyncGenerator<[LOADING_STATE, any | undefined]> {
        let queryName = this.namedQuery;
        let queryArgs = this.queryArgs;
        if (!queryName) return yield [LOADING_STATE.ABORTED, undefined];

        yield [LOADING_STATE.LOADING, undefined] as any;


        let cleanSnapshot;
        let snapshot;
        switch (this.method) {
            case FetchMethod.NETWORK_ONLY:
                snapshot = await callableQuery(queryName)(...queryArgs);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.NETWORK_FIRST:
                snapshot = await callableQuery(queryName)(...queryArgs);

                if (!snapshot.data) {
                    snapshot = await callableQuery(queryName, {source: 'cache'})(...queryArgs);
                }

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_ONLY:
                snapshot = await callableQuery(queryName, {source: 'cache'})(...queryArgs);

                return yield [LOADING_STATE.LOADED, snapshot];

            case FetchMethod.CACHE_FIRST:
                let itemsFromCache;

                if (CACHE_DOCUMENT_DELAYS.size) {
                    itemsFromCache = await callableQuery(queryName, {source: 'cache'})(...queryArgs).catch(_ => undefined);
                    if (itemsFromCache?.data?.docs?.length) {
                        return yield [LOADING_STATE.STREAMING, itemsFromCache];
                    }

                    console.time(`waiting for collection`);
                    await Promise.all(CACHE_DOCUMENT_DELAYS);
                    console.timeEnd(`waiting for collection`);
                }


                let data = await callableQuery(queryName, {source: 'cache'})(...queryArgs);

                if (!data.data.docs.length) {
                    data = await callableQuery(queryName)(...queryArgs);
                }
                if (itemsFromCache && data && itemsFromCache.data.docs.map((_: any) => _._document.version.toString()).join(',') === data.data.docs.map((_: any) => _._document.version.toString()).join(',')) {
                    return yield [LOADING_STATE.LOADED, itemsFromCache];
                }


                return yield [LOADING_STATE.LOADED, data];

            case FetchMethod.FASTEST:
                cleanSnapshot = callableQuery(queryName)(...queryArgs);

                try {
                    snapshot = await Promise.any([
                        callableQuery(queryName, {source: 'cache'})(...queryArgs),
                        cleanSnapshot,
                    ]);

                } catch (e) {
                    snapshot = await cleanSnapshot;
                }


                if (snapshot.metadata.fromCache && snapshot.data.docs.length) {
                    return yield [LOADING_STATE.LOADED, snapshot];

                } else {
                    return yield [LOADING_STATE.LOADED, await cleanSnapshot];
                }

            case FetchMethod.FASTEST_THEN_CLEAN:
                cleanSnapshot = callableQuery(queryName)(...queryArgs);

                try {
                    snapshot = await Promise.any([
                        callableQuery(queryName, {source: 'cache'})(...queryArgs),
                        cleanSnapshot,
                    ]);

                } catch (e) {
                    snapshot = await cleanSnapshot;
                }


                if (snapshot.metadata.fromCache) {
                    yield [LOADING_STATE.LOADED, snapshot];
                    return yield [LOADING_STATE.LOADED, await cleanSnapshot];

                } else {
                    return yield [LOADING_STATE.LOADED, snapshot];
                }

            case FetchMethod.LIVE:
                let nextData: Promise<any>;
                let nextDataCallback: (snapshot: any) => void;


                let unsubscribe = callableLiveQuery(queryName)((snapshot) => {
                    nextDataCallback(snapshot);
                }, ...queryArgs);

                let abortListener = () => {
                    unsubscribe();
                    this.abortController?.signal.removeEventListener('abort', abortListener);
                };
                this.abortController?.signal.addEventListener('abort', abortListener);


                while (1) {
                    nextData = new Promise((s) => {
                        nextDataCallback = s;
                    });

                    let snapshot = await nextData;
                    yield [LOADING_STATE.STREAMING, snapshot];
                }

                return yield [LOADING_STATE.ABORTED, undefined];

            case FetchMethod.STATIC:
                return yield [LOADING_STATE.LOADED, (this.options as any).staticContent];
        }
    }

    async receiveData(loadingState: LOADING_STATE, data: any) {
        if (loadingState === LOADING_STATE.LOADING) {
            this.loading = true;
            let docs: T[] = [];
            Object.defineProperties(docs, {
                _ref: {
                    value: undefined,
                },
                _metadata: {
                    value: {
                        fromCache: true,
                        hasPendingWrites: false,
                    },
                },
            });
            this.data = docs as any;
            return;
        }

        this.loading = false;
        if (loadingState === LOADING_STATE.ABORTED) return;


        let docs = data.data.docs.map((doc: T) => {
            let ref = doc._ref;
            delete doc._ref;

            Object.defineProperties(doc, {
                _ref: {
                    value: ref,
                    configurable: true,
                },
                _metadata: {
                    value: data.metadata,
                    configurable: true,
                },
            });

            return doc;
        });

        let ref = data.data._ref;
        delete data.data._ref;
        Object.defineProperties(docs, {
            _ref: {
                value: ref,
            },
            _aggs: {
                value: data.data.aggs,
            },
            _metadata: {
                value: data.metadata,
            },
        });
        this.data = docs as any;
    }

}
