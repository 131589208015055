import {customElement} from 'lit/decorators.js';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {ComponentAnalyticsDashboardPanel} from './component-analytics-dashboard-panel.ts';
import {quickDotGet} from '../../../__internal/shared/helpers/DotHelper.ts';

@customElement('component-analytics-dashboard-panel-table')
class ComponentAnalyticsDashboardPanelTable extends ComponentAnalyticsDashboardPanel {

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            .gridContainer > * {
                --col: 1;
            }
        `,
    ];

    renderPanel() {
        let data = this.data as any[];
        let fields = Object.keys(data[0] || {})
            .map((_: string) => ({field: _, heading: _}));

        return html`
            <div class="gridContainer" style="--grid-columns: ${fields.length}">
                ${fields.map(_ => html`
                    <div style="font-weight: bold">${_.heading}</div>
                `)}
                ${data.map(row => fields.map(_ => html`
                    <div>${quickDotGet(_.field, row)}</div>
                `))}
            </div>
        `;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-analytics-dashboard-panel-table': ComponentAnalyticsDashboardPanelTable;
    }
}