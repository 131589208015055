import {JSONParse, JSONStringify} from '../../shared/helpers/DataHelper';

export const JSONParseLocal = (text: string, reviver?: (this: any, key: string, value: any) => any) => {
    return JSONParse(text, (key, value) => {
        if (reviver) {
            value = reviver(key, value);
        }


        if (value && typeof value === 'object') {
            if (value.path && value.id) {
                debugger;
            }
        }


        return value;
    });
};

if (localStorage['testMode']) {
    (window as any).__JSONParseLocal = JSONParseLocal;
    (window as any).__JSONStringify = JSONStringify;
}
