import {RecordId, Surreal, surrealql as _surrealql} from 'surrealdb';
import {config} from '../../../../config';
import {Firestore} from '@lupimedia/firebase-polyfill/src/firestore/Firestore.ts';
import {calculateDocumentPath} from '@lupimedia/firebase-polyfill/src/firestore/helpers/FirestoreHelper.ts';
import {ReffableDocument} from '../../../../utils/DatabaseTypes.ts';
import {recursiveNukeReinject} from '../../shared/helpers/DataHelper.ts';

export type User =
    {
        id: RecordId,
        emailAddress: string,
        firstName: string,
        lastName: string,
        created: Date,
        permissions: string[],
        authMethods: { id: RecordId, type: string }[],
    };

export const LOCALSTORAGE_AUTH_TOKEN = 'authToken';
export const DATEBASE_CONFIG = config.database;
export let storage = sessionStorage[LOCALSTORAGE_AUTH_TOKEN] ? sessionStorage : localStorage;
let activeUser: User | null = null;

export const surrealApp = {
    db: new Surreal(),
};


let reconnectionAttempts = 0;
const _surrealConnectInternal = async () => {
    await surrealApp.db.connect(DATEBASE_CONFIG.url, {
        namespace: DATEBASE_CONFIG.namespace,
        database: DATEBASE_CONFIG.database,
        versionCheck: false,
        async prepare(db) {
            if (((db as any).connection as any)?.auth) return;

            let authToken = storage[LOCALSTORAGE_AUTH_TOKEN];
            if (authToken) {
                await signInWithToken(authToken);

            } else {
                setUser(null);
            }
        },
    });
};
let surrealConnectTimeoutId: number;
const surrealConnect = (delay = 0) => {
    clearTimeout(surrealConnectTimeoutId);

    const doConnect = async () => {
        reconnectionAttempts++;

        try {
            await _surrealConnectInternal();

        } catch (e) {
            console.info('[Surreal] Failed connecting, reconnecting', reconnectionAttempts);
            await surrealConnect(Math.min(1000, reconnectionAttempts * 100));
        }
    };

    if (delay) {
        surrealConnectTimeoutId = window.setTimeout(doConnect, delay);

    } else {
        doConnect();
    }
};
const watchStorageForAuthChange = () => {
    window.addEventListener('storage', async (e) => {
        if (e.key !== LOCALSTORAGE_AUTH_TOKEN) return;

        let authToken = storage[LOCALSTORAGE_AUTH_TOKEN];
        if (authToken) {
            await signInWithToken(authToken);

        } else {
            signOut();
        }
    });
};

surrealConnect();
watchStorageForAuthChange();
surrealApp.db.emitter.subscribe('connected', () => {
    reconnectionAttempts = 0;
});
surrealApp.db.emitter.subscribe('disconnected', () => {
    console.info('[Surreal] Disconnected, reconnecting', reconnectionAttempts);
    surrealConnect(Math.min(1000, reconnectionAttempts * 100));
});
surrealApp.db.emitter.subscribe('error', (e) => {
    console.error('[Surreal] error', e);
});
Firestore.init(surrealApp.db);


export const getActiveAuthToken = (): string | undefined => {
    return storage[LOCALSTORAGE_AUTH_TOKEN];
};

let authStateChangedListeners = new Set<(user: User | null) => void>();
export const onAuthStateChanged = (listener: (user: User | null) => void) => {
    authStateChangedListeners.add(listener);
};
export const offAuthStateChanged = (listener: (user: User | null) => void) => {
    authStateChangedListeners.delete(listener);
};

export const loadUser = async (): Promise<User> => {
    setUser((await surrealApp.db.query('RETURN fn::client::auth::getAuthInfo()'))[0] as User);

    return activeUser as User;
};

export const refreshAuthToken = async () => {
    // let markLastActiveResponse = surrealApp.db.query(`UPDATE $auth SET lastActive = time ::now(), lastActiveIp = $session.ip RETURN NONE`);
    let shouldRefreshSession = (await surrealApp.db.query('RETURN fn::client::auth::shouldRefreshSession()'))[0];
    if (shouldRefreshSession) {
        await surrealApp.db.authenticate(storage[LOCALSTORAGE_AUTH_TOKEN]);
    }
};

const setUser = (user: User | null) => {
    activeUser = user;

    for (let authStateChangedListener of authStateChangedListeners) {
        authStateChangedListener(user);
    }
};

export const signInWithToken = async (authToken: string) => {
    try {
        await surrealApp.db.authenticate(authToken);
        requestAnimationFrame(async () => {
            await loadUser();
        });

    } catch (e) {
        //TODO maybe dont nuke the token for all reasons???
        delete storage[LOCALSTORAGE_AUTH_TOKEN];
        console.error('Failed reauthing, running logged out', e, e.message);
        setUser(null);
    }
};

export const signupWithUsernameAndPassword = async (email: string, password: string, extraArgs: any) => {
    let authToken = await surrealApp.db.signup({
        namespace: DATEBASE_CONFIG.namespace,
        database: DATEBASE_CONFIG.database,
        access: DATEBASE_CONFIG.scope,
        variables: {
            emailAddress: email.toLowerCase(),
            password: password,
            deviceId: localStorage.__deviceId,
            ...extraArgs,
        },
    });
    storage[LOCALSTORAGE_AUTH_TOKEN] = authToken;

    return await loadUser();
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
    return reauthenticateWithCredential({
        access: DATEBASE_CONFIG.scope,
        emailAddress: email.toLowerCase(),
        password: password,
    });
};

export const signInWithCustomToken = async (authId: string, authToken: string) => {
    return reauthenticateWithCredential({
        access: `${DATEBASE_CONFIG.scope}_token`,
        authId: authId,
        authToken: authToken,
    });
};

export const signInWithAuthProvider = async (provider: string, authData: any) => {
    return reauthenticateWithCredential({
        access: `${DATEBASE_CONFIG.scope}_${provider}`,
        authData: authData,
    });
};

export const linkWithAuthProvider = async (provider: string, authData: any) => {
    await callableQuery(`auth::provider::${provider}::link`)(authData);
};

export const reauthenticateWithCredential = async (credential: {
    access: string,
    [key: string]: any
}): Promise<User> => {
    let authToken = await surrealApp.db.signin({
        namespace: DATEBASE_CONFIG.namespace,
        database: DATEBASE_CONFIG.database,
        access: credential.access,
        variables: {
            ...credential,
            deviceId: localStorage.__deviceId,
        },
    });
    storage[LOCALSTORAGE_AUTH_TOKEN] = authToken;

    return await loadUser();
};

export const setAuthPersistence = (state: 'local' | 'session') => {
    let activeToken = sessionStorage[LOCALSTORAGE_AUTH_TOKEN] || localStorage[LOCALSTORAGE_AUTH_TOKEN];

    if (state === 'local') {
        if (activeToken) {
            localStorage[LOCALSTORAGE_AUTH_TOKEN] = activeToken;

        } else {
            delete localStorage[LOCALSTORAGE_AUTH_TOKEN];
        }

        delete sessionStorage[LOCALSTORAGE_AUTH_TOKEN];
        storage = localStorage;

    } else if (state === 'session') {
        if (activeToken) {
            sessionStorage[LOCALSTORAGE_AUTH_TOKEN] = activeToken;

        } else {
            delete sessionStorage[LOCALSTORAGE_AUTH_TOKEN];
        }

        delete localStorage[LOCALSTORAGE_AUTH_TOKEN];
        storage = sessionStorage;
    }
};

export const signOut = () => {
    surrealApp.db.invalidate();

    if (storage[LOCALSTORAGE_AUTH_TOKEN]) {
        delete storage[LOCALSTORAGE_AUTH_TOKEN];
    }

    setUser(null);
};


export const surrealql = _surrealql;


const NAMED_QUERY_ARG = 'arg';
type NamedQueryOptionsSource = 'server' | 'cache';
type NamedQueryOptions = {
    source: NamedQueryOptionsSource
};

const deepReffableize = (object: any) => {
    if (object && object.id && object.__path && object.__id) {
        object._ref = {
            path: object.__path,
            id: object.__id,
            surrealId: object.id,
        };

        delete object.__path;
        delete object.__id;
        delete object.id;
    }

    for (let key in object) {
        if (key === '_ref') continue;

        if (typeof object[key] === 'object') {
            deepReffableize(object[key]);
        }
    }
};

export const callableQuery = (queryName: string, options?: NamedQueryOptions): (...args: any[]) => Promise<{
    metadata: { fromCache: boolean, source: 'server' | 'cache' },
    data: any
}> => {
    options ??= {} as NamedQueryOptions;
    options.source ??= 'server';


    return async (...args: any[]) => {
        try {
            if (queryName === '__internal::loadFirestoreDocument' || queryName === '__internal::loadFirestoreCollection') {
                if (args[0] instanceof RecordId) {
                    args[0] = calculateDocumentPath(args[0]);
                }
            }

            if (options?.source === 'cache') {
                console.warn('Cache not yet implimented, assuming raw fetch for ');
                if (queryName === '__internal::loadFirestoreCollection' || queryName === 'aspireCompsCompetitions::winnersMap') {
                    return {
                        metadata: {
                            source: options?.source as NamedQueryOptionsSource,
                            fromCache: true,
                        },
                        data: {docs: []},
                    };

                } else {
                    throw new Error(`Cache not yet implimented, assuming raw fetch for ${queryName}`);
                }
            }

            let queryArgs = Object.fromEntries(args.map((_, i) => [`${NAMED_QUERY_ARG}${i}`, _]));
            let query = `RETURN fn::client::${queryName}(${args.map((_, i) => `$${NAMED_QUERY_ARG}${i}`).join(', ')});`;

            let queryResponse = await surrealApp.db.query(query, queryArgs);
            deepReffableize(queryResponse);

            return {
                metadata: {
                    source: options?.source as NamedQueryOptionsSource,
                    fromCache: (options?.source as NamedQueryOptionsSource) === 'cache',
                },
                data: queryResponse[0] as any,
            };

        } catch (e) {
            console.error('Failed running callable query', e, args);
            throw e;
        }
    };
};

export const callableLiveQuery = (queryName: string, options?: NamedQueryOptions) => {
    options ??= {} as NamedQueryOptions;
    options.source ??= 'server';

    let liveQueryCallable = callableQuery(`${queryName}::LIVE`);

    return (callback: (data: {
        metadata: { source: NamedQueryOptionsSource, fromCache: boolean },
        data: any
    }) => void, ...args: any[]) => {
        let liveUUID: any | undefined;
        let subscribeLiveCallback: (action: any, rawResult: any) => Promise<any>;

        const loadLiveData = async () => {
            try {
                let liveQuery = await liveQueryCallable(...args);
                let rawQuery: string = liveQuery.data.join(';\n');
                let liveResponse = await surrealApp.db.query(rawQuery);


                let lastData = liveResponse[1] as any[] | any;
                deepReffableize(lastData);
                const flushLastData = () => {
                    recursiveNukeReinject(lastData);

                    callback({
                        metadata: {
                            source: options?.source as NamedQueryOptionsSource,
                            fromCache: options?.source === 'cache',
                        },
                        data: lastData,
                    });
                };
                let isSingleItem = !lastData?.docs;
                flushLastData();

                liveUUID = liveResponse[0] as any;
                subscribeLiveCallback = async (action: any, rawResult: any) => {
                    if (action === 'CLOSE') return;


                    let result = rawResult as ReffableDocument;
                    deepReffableize(result);

                    if (isSingleItem) {
                        if (action === 'DELETE') {
                            lastData = undefined;

                        } else {
                            lastData = result;
                        }

                    } else {
                        let lastDataMulti = (lastData as { docs: ReffableDocument[] });

                        if (action === 'UPDATE') {
                            let lastItemIndex = lastDataMulti.docs.findIndex(_ => _._ref?.id && _._ref.id === result._ref?.id);
                            if (lastItemIndex >= 0) {
                                lastDataMulti.docs[lastItemIndex] = result;

                            } else {
                                lastDataMulti.docs.push(result);
                            }

                        } else if (action === 'CREATE') {
                            lastDataMulti.docs.push(result);

                        } else if (action === 'DELETE') {
                            let deleteIndex = lastDataMulti.docs.findIndex(_ => _._ref?.id && _._ref.id === result._ref?.id);
                            if (deleteIndex >= 0) {
                                lastDataMulti.docs.splice(deleteIndex, 1);
                            }
                        }
                    }


                    flushLastData();
                };
                await surrealApp.db.subscribeLive(liveUUID, subscribeLiveCallback);

            } catch (e) {
                console.error('Failed running callable live query', e, args);
                throw e;
            }
        };
        const killLiveData = async () => {
            if (!liveUUID) return;

            await surrealApp.db.unSubscribeLive(liveUUID, subscribeLiveCallback);
            await surrealApp.db.kill(liveUUID);
            liveUUID = undefined;
        };
        const onAuthChanged = async (user: any) => {
            //TODO WORKAROUND FOR https://github.com/surrealdb/surrealdb/security/advisories/GHSA-2xrp-m9c6-75rj
            await killLiveData();
            await loadLiveData();
        };

        loadLiveData();
        surrealApp.db.emitter.subscribe('connected', loadLiveData);
        onAuthStateChanged(onAuthChanged);

        return async () => {
            surrealApp.db.emitter.unSubscribe('connected', loadLiveData);
            offAuthStateChanged(onAuthChanged);

            await killLiveData();
        };
    };
};