import {customElement} from 'lit/decorators.js';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {RenderingHelper} from '../../../__internal/local/helpers/RenderingHelper';
import {Route} from '../../../routing/local/controllers/Route';
import {Auth} from '../../../auth/local/controllers/Auth';
import {MenuItemDocument} from '../../../../utils/DatabaseTypes.ts';

enum MENU_TYPE {
    SUBMENU = 'submenu',
    MENU = 'menu',
}

@customElement('component-menus-group')
class ComponentMenusGroup extends BunnyElement {

    auth = Auth.getInstance(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    itemsContainerClassList: string = '';

    @property({type: Array})
    rawItems: MenuItemDocument[] = [];

    @property({type: Array})
    @computed('rawItems', '__menuItem')
    get items() {
        let parent = this.__menuItem?._ref?.surrealId ?? null;

        return this.rawItems.filter(_ => (_.parent?.toString() ?? null) === (parent?.toString() ?? null))
            .sort((a, b) => (a.order || 0) - (b.order || 0));
    };

    @property({type: Object})
    menu: any;

    @property({type: Object})
    __menuItem: MenuItemDocument | null = null;

    @property({type: String})
    href: string | null = null;

    @property({type: String, reflect: true})
    textContent: string = '';

    @property({type: Object})
    data: any;

    @property({type: Boolean, reflect: true})
    @computed('subMenuOpen', 'menuType')
    get isSubMenuOpen() {
        return this.menuType === MENU_TYPE.MENU || this.subMenuOpen;
    }

    set isSubMenuOpen(_value: boolean) {
        //ignore, just to have a setter for the attribute
    };

    @property({type: String, reflect: true})
    @computed('textContent')
    get menuType() {
        if (this.textContent) {
            return MENU_TYPE.SUBMENU;
        }


        return MENU_TYPE.MENU;
    };

    set menuType(_value: string) {
        //ignore, just to have a setter for the attribute
    };

    @property({type: Boolean})
    subMenuOpen: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                position: relative;
                /*    @todo look into removing relative as the shadow root is effecting it*/
            }

            #items {
                display: none;
            }

            component-button, component-button-toggle {
                display: block;
                margin: 0;
                min-width: 0;
                background: none;
                color: inherit;
                box-shadow: none;
            }

            :host([is-sub-menu-open]) #items {
                display: flex;
                flex-direction: var(--menus-group-items-flex-direction);

                > * {
                    text-align: left;
                }
            }

            :host([menu-type="submenu"]) #items {
                position: absolute;
                top: 100%;
                background: white;
                box-shadow: 0 0 3px rgba(32, 32, 32, .3);
                flex-direction: column;
                z-index: 1;

                width: 200px;
                width: intrinsic; /* Safari/WebKit uses a non-standard name */
                width: -moz-max-content; /* Firefox/Gecko */
                width: -webkit-max-content; /* Chrome */
                width: max-content;
            }

            #items > * {
                transition: box-shadow .125s;
            }

            #items > :hover {
                box-shadow: inset 0px -1px 0 var(--primary-color);
            }

            #items > [active] {
                box-shadow: inset 0px -3px 0 var(--attention-color);
            }
        `];

    override render() {
        return html`
            ${this.href ? html`
                <a href="${this.href}">
                    <component-button>
                        <slot></slot>
                    </component-button>
                </a>
            ` : undefined}

            ${this.textContent ? html`
                <component-button-toggle .active="${this.bind.subMenuOpen}">
                    ${this.textContent}
                </component-button-toggle>
            ` : undefined}

            <div id="items" class="${this.itemsContainerClassList}">
                ${this.items?.map(item => {
                    let permissions = item.permissions || [];
                    if (!RenderingHelper._accountHasPermissions(this.auth.user, permissions)) return;

                    return createComponent(item, this.data, {
                        menu: this.menu,
                        // account: account,
                        __menuItem: item,
                        rawItems: this.rawItems,
                        data: this.data,
                    });
                })}
            </div>
        `;
    }

    @observe('route')
    closeNavOnRouteChange(_route: Route) {
        this.subMenuOpen = false;
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-menus-group': ComponentMenusGroup;
    }
}