import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import './component-login-alt';
import {getRegisterMeta} from '../helpers/AccountHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {html} from 'lit';
import {Route} from '../../../routing/local/controllers/Route';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {storageBoundQueryString} from '../../../__internal/local/helpers/decorators/StorageBoundDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {toastProgressWrapper} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper';
import {Auth} from '../controllers/Auth';
import HistoryHelper from '../../../__internal/local/helpers/HistoryHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {UnshadowStyles} from '../../../__internal/local/controllers/UnshadowStyles';
import {FormSubmitEvent} from '../../../inputs/local/components/component-native-form';
import {showToast} from '../../../__internal/local/helpers/ToastHelper';
import {track} from '../../../firebase-analytics/local/helpers/TrackingHelper.ts';
import {delayPromise} from '../../../__internal/local/helpers/PromiseHelper.ts';

const SESSIONSTORAGE_KEY = '__redirectToSignupOnFirstVisit';

@customElement('component-login')
class ComponentLogin extends BunnyElement {
    //@ts-ignore
    private unshadowStyles = new UnshadowStyles(this);

    @property({type: Object, notify: true})
    auth = Auth.getInstance(this);

    @property({notify: true})
    route = Route.getInstance(this);

    @property({type: String})
    @storageBoundQueryString('email')
    emailAddress: string = '';

    @property({type: String})
    returnUrl: string = '/';

    @property({type: String})
    signupUrl: string = '/signup';

    private attempts = 0;

    // @property({type: Object})
    // @globalProvider('account')
    // account: any;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            component-login {
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding: 0 15px 0 15px;
                overflow: hidden;

                .formHeader {
                    text-align: center;
                    margin-bottom: 25px;
                    background-color: var(--primary-color);
                    color: white;
                    padding: 10px 15px;
                    margin-left: -15px;
                    margin-right: -15px;
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                }

                .formHeader > * {
                    margin: 0;
                }

                component-login-alt {
                    position: relative;
                    margin-top: 35px;
                    margin-left: -15px;
                    margin-right: -15px;
                    padding: 35px 5px 15px 5px;
                    border-top: solid rgba(64, 64, 64, .3) 1px;
                    background-color: var(--primary-text-color);
                    text-align: center;
                    display: flex;
                    justify-content: center;
                }

                component-login-alt:after {
                    content: 'Or login with';
                    display: inline-block;
                    padding: 3px 15px;
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: white;
                    box-shadow: var(--shadow-elevation-2dp-box-shadow);
                }
            }
        `,
    ];

    override render() {
        return html`
            <div class="formHeader">
                <h1>
                    Login
                </h1>
                <p>Existing customers</p>
            </div>
            ${this.signupUrl ? html`
                <div style="text-align: right; margin-top: -20px">
                    <a href="${this.renderUrlEmail(this.signupUrl, 'email', this.emailAddress)}"
                       style="color: var(--attention-color); font-size: 14px">
                        No account? Register
                    </a>
                </div>
            ` : undefined}

            <component-native-form @submit="${this.sendCallback}" style="--loading-inset: -500px">
                <component-input-email
                        required
                        name="email"
                        inputmode="email"
                        autocomplete="email"
                        label="Email address"
                        autofocus
                        .value="${this.bind.emailAddress}"></component-input-email>
                <component-input-password
                        required
                        name="password"
                        autocomplete="current-password"
                        label="Password"></component-input-password>

                <button tabindex="-1" style="opacity: 0; width: 0; height: 0; border: none; background: none">
                    Submit
                </button>

                <div style="display: flex; margin-top: 10px; margin-bottom: 50px">
                    <component-input-checkbox name="rememberMe" checked>Stay logged in</component-input-checkbox>
                    <a href="${this.renderUrlEmail('/login/recover-account?', 'email', this.emailAddress)}"
                       style="color: var(--primary-color); margin-left: auto">
                        Forgot password?
                    </a>
                </div>
                <div slot="submitButton">
                    <component-button id="authLogin" raised
                                      style="display: block; background-color: var(--attention-color); color: white; margin-left: -15px; margin-right: -15px; text-align: center; border-radius: 0">
                        Login
                    </component-button>

                    ${this.signupUrl ? html`
                        <div style="text-align: right; margin-bottom: 50px; margin-top: 10px; font-size: 18px">
                            <a href="${this.renderUrlEmail(this.signupUrl, 'email', this.emailAddress)}"
                               style="color: var(--primary-color);">
                                No account? Register
                            </a>
                        </div>
                    ` : undefined}
                </div>
            </component-native-form>

            <component-login-alt></component-login-alt>
        `;
    }

    createRenderRoot() {
        return this;
    }

    connectedCallback() {
        super.connectedCallback();

        this.__redirectToSignupOnFirstReferralVisit();
    }

    @observe('auth')
    async _accountChanged(auth: Auth) {
        if (auth.user) {
            //wait a frame for the routing to properly populate
            await delayPromise();

            HistoryHelper.pushState(this.returnUrl);
        }
    }

    @toastProgressWrapper({
        progressMessage: 'Logging in',
        successMessage: 'Logged in successfully',
        failedMessage: 'Failed logging in: {{e}}',
    })
    @bind()
    async sendCallback(e: FormSubmitEvent) {
        this.attempts++;

        let finalResponse = await e.detail.setResponse(async () => {
            let formData = e.detail.data;

            try {
                let rememberMe = formData.rememberMe === 'on';
                await this.auth.setStatePersistence(rememberMe ? 'local' : 'session');

            } catch (e: any) {
                // @see https://lupimedia.atlassian.net/browse/AC-3683 Unable to login on firefox porn mode
                if (!e.message.includes('mutation')) throw e;
            }


            let user = await this.auth.signInWithEmailAndPassword(
                formData.email as string,
                formData.password as string,
            );


            track('accountLogin', {
                path: location.href.replace(location.origin, ''),
                method: 'password',
                attempts: this.attempts,
            });

            if (user.firstName) {
                return `Welcome back ${user.firstName}`;

            } else {
                return 'Successfully logged in';
            }
        });

        if (finalResponse instanceof Error) {
            throw finalResponse;

        } else {
            setTimeout(() => {
                showToast(finalResponse, {autoDismiss: 2000});
            });
        }
    }

    @observe('route')
    loadReturnUrlFromUrl(route: Route) {
        let returnUrl = route.current.query.returnUrl;
        if (!returnUrl) return;

        //validate that the url can only start with /(base) and not //(off origin)
        if (!(returnUrl[0] === '/' && returnUrl[1] !== '/')) return;

        this.returnUrl = returnUrl;
    }

    renderUrlEmail(url: string, field: string, emailAddress: string) {
        if (emailAddress) {
            url += `${url.includes('?') ? '&' : '?'}${field}=${encodeURIComponent(emailAddress)}`;
        }


        return url;
    }

    private __redirectToSignupOnFirstReferralVisit() {
        let meta = getRegisterMeta();

        if (meta.referer && !sessionStorage[SESSIONSTORAGE_KEY]) {
            sessionStorage[SESSIONSTORAGE_KEY] = 1;
            HistoryHelper.replaceState(this.renderUrlEmail(this.signupUrl, 'email', this.emailAddress));
        }
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-login': ComponentLogin;
    }
}