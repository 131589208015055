import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {MediaReferenceField} from '../../shared/helpers/FirebaseHelper';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {FIRESTORE_COLLECTION_MEDIA, MediaDocument} from '../../../../utils/DatabaseTypes.ts';
import {DocumentReference} from '@lupimedia/firebase-polyfill/src/firestore/DocumentReference.ts';

@customElement('component-input-media-library')
class ComponentInputMediaLibrary extends BunnyElement {

    @property({type: Object, notify: true})
    value: MediaReferenceField;

    @property({type: Array, notify: true})
    values: MediaReferenceField[];

    @property({type: Boolean})
    multiple: boolean = false;

    @property({type: String})
    label: string;

    @property({type: Array})
    items: MediaDocument[];

    @property({type: Array})
    fields = [
        {
            cellProperties: {
                field: 'name',
                type: 'text',
            },
            header: false,
            cell: {
                component: 'component-input-media-library-list-item',
                properties: {
                    style: '--col: 3',
                },
            },
        },
    ];

    @property({type: Array})
    filters = {type: 'Image'};

    @property({type: Boolean})
    select: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                padding: 8px 0;
            }

            .label {
                font-family: var(--paper-font-caption_-_font-family);
                -webkit-font-smoothing: var(--paper-font-caption_-_-webkit-font-smoothing);
                white-space: var(--paper-font-caption_-_white-space);
                overflow: var(--paper-font-caption_-_overflow);
                text-overflow: var(--paper-font-caption_-_text-overflow);
                font-size: var(--paper-font-caption_-_font-size);
                font-weight: var(--paper-font-caption_-_font-weight);
                letter-spacing: var(--paper-font-caption_-_letter-spacing);
                line-height: var(--paper-font-caption_-_line-height);
                margin-bottom: 5px;
            }

            #dialog {
                --width: 100vw;
            }
        `,
    ];

    override render() {
        return html`
            ${this.label ? html`
                <div class="label">
                    ${this.label}
                </div>
            ` : undefined}

            <component-button-toggle .active="${this.bind.select}">
                From library
            </component-button-toggle>

            <component-dialog .opened="${this.bind.select}" id="dialog">
                <h3 slot="heading">
                    Pick media from library
                </h3>
                <div style="overflow: auto; padding: 0">
                    ${this.select ? html`
                        <component-firestore-collection-list .path="${FIRESTORE_COLLECTION_MEDIA}"
                                                             .fields="${this.fields}"
                                                             .filters="${this.filters}"></component-firestore-collection-list>
                    ` : undefined}
                </div>
            </component-dialog>
        `;
    }

    @listen('select-media', 'dialog')
    mediaSelected(e: Event) {
        let media = (e as CustomEvent<{ media: MediaDocument }>).detail.media;

        let mediaReferenceField: MediaReferenceField = {
            media: media._ref as DocumentReference,
            type: media.type,
        };

        if (this.multiple && this.values) {
            this.values.push(mediaReferenceField);
            this.requestUpdate('values');
            this.requestUpdate('value');

        } else {
            this.values = [mediaReferenceField];
        }


        this.select = false;
    }

    @observe('value')
    mapValueToValues(value: any) {
        if (Array.isArray(value)) {
            this.values = value;

        } else if (value && typeof value === 'object') {
            this.values = [value];

        } else {
            this.values = [];
        }
    }

    @observe('values')
    mapValuesToValue(values: any) {
        let newValue = undefined;
        if (this.multiple) {
            newValue = values;

        } else {
            newValue = values[0];
        }

        if (newValue !== this.value) {
            this.value = newValue;
        }
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-media-library': ComponentInputMediaLibrary;
    }
}