import {customElement} from 'lit/decorators.js';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {confirmationDialog} from '../../../__internal/local/helpers/decorators/ConfirmationDialogDecoratorHelper.ts';
import {
    toastProgressWrapper,
} from '../../../__internal/local/helpers/decorators/ToastProgressWrapperDecoratorHelper.ts';
import {callableQuery} from '../../../__internal/local/helpers/SurrealHelper.ts';
import {AccountShopContactDetailDocument} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-shop-customer-contact-card')
export class ComponentShopCustomerContactCard extends BunnyElement {

    @property({type: Object})
    contact: AccountShopContactDetailDocument | null = null;

    @property({type: Boolean, reflect: true})
    active: boolean = false;

    @property({type: Boolean, reflect: true})
    disabled: boolean = false;

    @property({type: Boolean})
    viewingOnly: boolean = false;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host {
                max-width: 400px;
                min-width: 300px;
                box-shadow: var(--shadow-elevation-2dp-box-shadow);
                padding: 0;
                display: inline-flex;
                margin: 15px;
                flex: 1;
                flex-direction: column;
                border-radius: 8px;
                overflow: hidden;
            }

            :host > * {
                padding: 10px;
            }

            .contactName {
                background: var(--primary-color);
                color: white;
                font-weight: bold;
                font-size: 18px;
                font-style: normal;
                position: relative;
                display: flex;
            }

            .contactNotification {
                display: flex;
                border-bottom: solid rgba(32, 32, 32, .1) 1px;
            }

            .contactNotification > * {
                flex: 1;
            }

            .contactNotification component-icon {
                vertical-align: bottom;
            }

            .contactAction {
                background: var(--primary-text-color--lighter);
                color: white;
                font-style: normal;
                text-align: center;
                margin: 0;
                margin-top: auto;
                border-radius: 0;
                width: 100%;
            }

            .contactAction:not([disabled]) {
                background-color: var(--attention-color);
            }

            :host([disabled]) .contactAction,
            :host([disabled]) .contactName component-button {
                display: none;
            }
        `,
    ];

    private renderContactAddress(addressFields: (string | undefined)[]) {
        addressFields = addressFields.filter(_ => _);

        return addressFields.map(addressField => html`
            <div>${addressField}</div>`);
    }

    override render() {
        let contact = this.contact;
        return html`
            ${contact ? html`
                <div class="contactName" style="overflow: hidden">
                    <span>
                        ${contact.firstName}
                        ${contact.lastName}
                    </span>

                    <component-button @click="${this.deleteContact}"
                                      style="--padding: 10px; min-width: 0; margin: auto; margin-right: 0; margin-top: -10px; margin-bottom: -10px; margin-right: -10px; background: var(--attention-color)">
                        <component-icon icon="icons:delete-forever"></component-icon>
                    </component-button>
                </div>
                <div class="contactNotification" on-click="selectContact">
                    <span>
                        <component-icon icon="communication:contact-phone"></component-icon>
                        &nbsp;${contact.phone}
                    </span>
                    <span>
                        <component-icon icon="communication:contact-mail"></component-icon>
                        &nbsp;${contact.emailAddress}
                    </span>
                </div>

                <address class="contactAddress" @click="${this.selectContact}">
                    ${this.renderContactAddress([
                        contact.name,
                        contact.address1,
                        contact.address2,
                        contact.company,
                        contact.city,
                        contact.province,
                        contact.zip,
                        contact.country,
                    ])}
                </address>
            ` : html`
                <div class="contactAddress"
                     style="text-align: center; flex: 1; display: flex; align-items: center; justify-content: center; flex-direction: column; padding-top: 25px; padding-bottom: 25px;"
                     @click="${this.selectContact}">
                    <component-icon icon="icons:add-circle" style="width: 40px; height: 40px"></component-icon>
                    <br>
                    Add new contact details
                </div>
            `}


            ${!this.viewingOnly ? html`
                <component-button class="contactAction" ?disabled="${this.active}" @click="${this.selectContact}"
                                  style="--padding: 10px; padding: 0">
                    ${this.contact ? html`
                        ${this.active ? 'Details selected' : 'Select details'}
                    ` : html`
                        Add new details
                    `}
                </component-button>
            ` : undefined}
        `;
    }

    selectContact() {
        this.dispatchEvent(new CustomEvent('select-contact', {
            bubbles: true,
            composed: true,
            detail: {
                contact: this.contact,
            },
        }));
    }

    @toastProgressWrapper({
        progressMessage: 'Removing saved address',
        successMessage: 'Removed saved address',
        failedMessage: 'Failed removing saved address: {{e}}',
    })
    @confirmationDialog({
        title: 'Removing saved address',
        body: 'Are you sure you want to remove this saved address?',
        failButtonText: 'Cancel',
        successButtonText: 'Yes permanently remove',
    })
    async deleteContact() {
        await callableQuery('__internal::deleteDoc')(this.contact?._ref?.surrealId);

        this.dispatchEvent(new CustomEvent('delete-contact', {
            bubbles: true,
            composed: true,
            detail: {
                contact: this.contact,
            },
        }));
    }
}


declare global {
    interface HTMLElementTagNameMap {
        'component-shop-customer-contact-card': ComponentShopCustomerContactCard;
    }
}