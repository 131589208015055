import {customElement} from 'lit/decorators.js';
import {createComponent} from '../../../routing/local/helpers/DomHelper';
import {
    FORM_ELEMENT_DEFAULT_VALUE_PROPERTY,
    FORM_ELEMENT_DEFAULT_VALUE_TREATMENT,
    FORM_ELEMENT_VALUE_TREATMENTS,
} from '../../shared';
import {BunnyElement} from '../../../__internal/local/components/bunny-element';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {sharedStyles} from '../../../../shared-styles';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {html} from 'lit';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper';
import {bind} from '../../../__internal/local/helpers/decorators/BindDecoratorHelper';
import {computed} from '../../../__internal/local/helpers/decorators/ComputedDecotratorHelper';
import {SurrealCollection} from '../../../__internal/local/controllers/SurrealCollection';
import {FetchMethod} from '../../../__internal/local/controllers/SurrealData';
import {
    FIRESTORE_COLLECTION_FORMS_SUB_ELEMENTS,
    FormDocument,
    FormElementDocument,
} from '../../../../utils/DatabaseTypes.ts';

@customElement('component-forms-group')
export class ComponentFormsGroup extends BunnyElement {
    @property({type: Object})
    _pageData: object;

    @property({type: Array, notify: true})
    @computed('form', '__formElement')
    get internalElements() {
        if (!this.form) return;

        return new SurrealCollection<FormElementDocument>(
            this,
            '__internal::loadFirestoreCollection',
            [
                `${this.form._ref?.path}/${FIRESTORE_COLLECTION_FORMS_SUB_ELEMENTS}`,
                {
                    where: [{
                        fieldPath: 'parent',
                        opStr: '==',
                        value: this.__formElement ? `forms_elements:${this.__formElement._ref?.id}` : undefined,
                    }],
                    orderBy: [{fieldPath: 'order', directionStr: 'asc'}],
                },
            ],
            {
                method: FetchMethod.FASTEST_THEN_CLEAN,
            },
        );
    }

    @property({type: Array})
    elements: FormElementDocument[];

    @property({type: Object})
    form: FormDocument;

    @property({type: Object, notify: true})
    value: any = {};

    @property({type: Object})
    __formElement: FormElementDocument | null = null;

    @property({type: String})
    label: string;

    @property({type: String})
    elementsContainerClassList: string = '';

    @property({type: String})
    elementsContainerStyle: string = '';

    @property({type: Object})
    data: any;

    _elementsReady: Promise<void>;
    _elementsReadyComplete: () => void;


    _valuePropertyUpdateMap: {
        [key: string]: any
    } = {};

    @property({type: String})
    defaultElementRegion: string = 'elements';

    private __ignoreValueSetterForElement: HTMLElement | undefined;

    static override styles = [
        sharedStyles,
        // language=SCSS
        scss`
            :host(.fieldset) label {
                font-family: var(--paper-font-caption_-_font-family);
                font-size: var(--paper-font-caption_-_font-size);
                font-weight: var(--paper-font-caption_-_font-weight);
                letter-spacing: var(--paper-font-caption_-_letter-spacing);
                line-height: var(--paper-font-caption_-_line-height);
                color: var(--paper-input-container-color, var(--secondary-text-color));
            }

            :host(.fieldset) .elements {
                background-color: rgba(64, 64, 64, .05);
                padding: 15px;
                margin-top: 5px;
                border-bottom: 1px solid var(--paper-input-container-color, var(--secondary-text-color));
            }
        `,
    ];

    // language=HTML
    baseRender() {
        return html`
            ${this.label ? html`
                <label for="elements">${this.label}</label>
            ` : undefined}
        `;
    }

    // language=HTML
    override render() {
        let elementTemplates = this.generateElementTemplates(this.elements, this.value, this.data);

        return html`
            ${this.baseRender()}

            <div id="elements" class="elements ${this.elementsContainerClassList}"
                 style="${this.elementsContainerStyle}">
                ${elementTemplates.elements}
            </div>
        `;
    }

    connectedCallback(): void {
        this._elementsReady = new Promise<void>((s) => {
            this._elementsReadyComplete = s;
        });

        super.connectedCallback();
    }

    private _handleManagedForm(elements: FormElementDocument[]) {
        let newElements = elements.filter(_ => _.created && this.form.created && _.created >= this.form.created);

        if ((elements as any)._metadata) {
            Object.defineProperty(newElements, '_metadata', {value: (elements as any)._metadata});
        }

        if ((elements as any)._ref) {
            Object.defineProperty(newElements, '_ref', {value: (elements as any)._ref});
        }


        return newElements;
    }

    generateElementTemplates(elements: FormElementDocument[], value: any, data: any) {
        let placementRegions: Record<string, any[]> = {};
        if (!elements) return placementRegions;


        if ((this.form as any)?._managedForm) {
            elements = this._handleManagedForm(elements);
        }

        let valuePropertyUpdateMap: {
            [key: string]: any
        } = {};
        this._valuePropertyUpdateMap = valuePropertyUpdateMap;
        // let trackValueProperty = (key: string, nodeProperty: string, node: HTMLElement) => {
        //     (valuePropertyUpdateMap[key] = (valuePropertyUpdateMap[key] || [])).push({
        //         node: node,
        //         nodeProperty: nodeProperty,
        //     });
        // };

        for (let element of elements) {
            element.properties ??= {};
            element.events ??= [];

            let valueProperty = element.valueProperty || FORM_ELEMENT_DEFAULT_VALUE_PROPERTY;
            let valueTreatment = element.valueTreatment || FORM_ELEMENT_DEFAULT_VALUE_TREATMENT;
            let extendedValueMap = element.extendedValueMap || {};
            element.properties.__valueProperty = valueProperty;
            element.properties.__valueTreatment = valueTreatment;
            if (element.name) {
                let elementValue = (value || {})[element.name] || undefined;

                element.properties[valueProperty] = elementValue;
                delete element.properties[`_reinject_${valueProperty}`];
                if (!element.events.find(_ => _[`${valueProperty}-changed`] === this.onValueChanged)) {
                    element.events.push({[`${valueProperty}-changed`]: this.onValueChanged});
                }

                // trackValueProperty(`value.${element.name}`, valueProperty, nodeTemplate);

            } else {
                // trackValueProperty('value', valueProperty, nodeTemplate);
                element.properties[valueProperty] = value;
                if (!element.events.find(_ => _[`${valueProperty}-changed`] === this.onValueChanged)) {
                    element.events.push({[`${valueProperty}-changed`]: this.onValueChanged});
                }
            }

            for (let key in extendedValueMap) {
                let dataKey = extendedValueMap[key];
                // trackValueProperty(`value.${dataKey}`, key, nodeTemplate);
                element.properties[key] = (value || {})[dataKey];
            }


            let nodeTemplate: any = createComponent(element, data, {
                form: this.form,
                // value: null,//TODO why is this needed?
                __formGroup: this,
                __formElement: element,
                data: data,
                id: element.key,
            });


            let placementRegion = placementRegions[element.placementRegion || this.defaultElementRegion] ??= [];
            placementRegion.push(nodeTemplate);
        }


        if (elements.length) {
            this._elementsReadyComplete();
        }


        return placementRegions;
    }

    @observe('value')
    onValuesChanged(change: any) {
        if (!change) return;
        if (!change.base || !change.path) return;

        let changeKey = change.path;


        let propertyUpdates = this._valuePropertyUpdateMap[changeKey];
        if (!propertyUpdates) return;


        for (let valuePropertyUpdate of propertyUpdates) {
            if (valuePropertyUpdate.node === this.__ignoreValueSetterForElement) continue;

            valuePropertyUpdate.node[valuePropertyUpdate.nodeProperty] = change.value;
        }
    }

    _processTreatedValue(value: any, treatment: FORM_ELEMENT_VALUE_TREATMENTS, target: HTMLElement): any {
        switch (treatment) {
            case FORM_ELEMENT_VALUE_TREATMENTS.RAW:
                return value;

            case FORM_ELEMENT_VALUE_TREATMENTS.INT:
                return parseInt(value, 10) || 0;

            case FORM_ELEMENT_VALUE_TREATMENTS.FLOAT:
                return parseFloat(value);

            case FORM_ELEMENT_VALUE_TREATMENTS.BOOL:
                return value === true || value === 'true' || value === '1' || value === 1;

            case FORM_ELEMENT_VALUE_TREATMENTS.INTERNAL:
                debugger;
                if (!(target as any).processTreatedValue) {
                    console.error('[Form] attempted to process internal treated value but no processTreatedValue defined on', target);
                    return value;
                }

                return (target as any).__processTreatedValue(value);
        }
    }

    @bind()
    onValueChanged(e: Event) {
        let target: any = e.target;
        let valueProperty = target.__valueProperty;
        let valueTreatment = target.__valueTreatment;
        let value = target[valueProperty];
        let name = target.__formElement.name;


        if (value === undefined) {
            value = null;
        }


        value = this._processTreatedValue(value, valueTreatment, target);

        try {
            this.__ignoreValueSetterForElement = e.currentTarget as HTMLElement;

            if (name) {
                if (this.value) {
                    this.value[name] = value;
                    if (`_reinject_${name}` in this.value) {
                        delete this.value[`_reinject_${name}`];
                    }
                    this.requestUpdate('value');
                    // this.value = {
                    //     ...this.value,
                    //     [name]: value,
                    // }
                    //TODO some way to notify that its changed a sub property thats not the shit way of a whole set

                } else {
                    this.value = {
                        [name]: value,
                    };
                }

            } else {
                this.requestUpdate('value');
            }

        } finally {
            this.__ignoreValueSetterForElement = undefined;
        }
    }

    getElementComponent(id: string) {
        return this.shadowRoot?.querySelector(`.element #${id}`);
    }

    async getElementComponentAsync(id: string) {
        await this._elementsReady;

        return this.getElementComponent(id);
    }

    validate() {
        let valid = true;

        let element: any;
        for (element of this.shadowRoot?.querySelectorAll('.elements > *') || []) {
            if (!element.validate) continue;


            if (!element.validate()) {
                valid = false;
            }
        }

        return valid;
    }

    @observe('internalElements')
    reflectInternalElementsIntoElements(internalElements: SurrealCollection<FormElementDocument>) {
        if (!internalElements?.data) return;

        this.elements = internalElements.data;
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-forms-group': ComponentFormsGroup;
    }
}