import {customElement} from 'lit/decorators.js';
import {property} from '../../../__internal/local/helpers/decorators/PropertyDecoratorHelper';
import {scss} from '../../../__internal/local/helpers/StyleHelper';
import {ComponentInputTextarea} from '../../../inputs/local/components/component-input-textarea.ts';
import {JSONStringify} from '../../../__internal/shared/helpers/DataHelper.ts';
import {JSONParseLocal} from '../../../__internal/local/helpers/DataHelper.ts';
import {listen} from '../../../__internal/local/helpers/decorators/ListenDecoratorHelper.ts';
import {html} from 'lit/static-html.js';
import {observe} from '../../../__internal/local/helpers/decorators/ObserveDecoratorHelper.ts';

@customElement('component-input-json')
class ComponentInputJson extends ComponentInputTextarea {

    @property({type: Boolean})
    asString: boolean = true;

    static override styles = [
        ...ComponentInputTextarea.styles,
        // language=SCSS
        scss`
            #errorMessage {
                color: red;
            }
        `,
    ];

    convertValueToNative(value: any) {
        if (value === '') value = undefined;//TODO look into why the value comes in as a empty string even when its overridden

        return this.asString ? value : JSONStringify(value, undefined, 4);
    }

    convertNativeToValue(native: any) {
        try {
            let parsed = JSONParseLocal(native);
            this.invalid = false;
            return this.asString ? native : parsed;

        } catch (e: any) {
            this.errorMessage = e.message;
            this.invalid = true;
            return null;
        }
    }

    override render() {
        return html`
            ${super.render()}
            ${this.invalid ? html`<p id="errorMessage">${this.errorMessage}</p>` : ''}
        `;
    }

    protected onInput() {
        //dont use the value just for validation
        this.convertNativeToValue((this.nativeInput as any)[this.valueAttribute]);
    }

    @listen('blur', 'nativeInput')
    onBlur() {
        let value = this.convertNativeToValue((this.nativeInput as any)[this.valueAttribute]);

        if (!this.invalid) {
            this.value = value;
        }
    }

    @observe('value')
    applyDefaultValueOnUndefined(_value: any) {
        //overwride the applyDefaultValueOnUndefined from component-input to do nothing since undefined is allowed
    }

}


declare global {
    interface HTMLElementTagNameMap {
        'component-input-json': ComponentInputJson;
    }
}