import {ShopOrderDocument} from '../../../../utils/DatabaseTypes.ts';

export interface BaseEventParams {
    __eventTs?: number;
}


interface AppPerformanceEventParams extends BaseEventParams {
    path: string;
    performanceEntries: any[];
}

interface AppErrorEventParams extends BaseEventParams {
    path: string;
    errorReport: any;
    extendedInfo: any;
}

interface PageViewEventParams extends BaseEventParams {
    path: string;
    title: string;
}

interface ViewContentEventParams extends BaseEventParams {
    id: string;
    name: string;
    value?: number;
    categories?: string[];
    currency?: string;
    image: string;
    meta?: Record<string, any>;
    type: 'product';
    path: string;
}

interface ShopAddToCartEventParams extends BaseEventParams {
    id: string;
    name: string;
    quantity: number;
    value: number;
    categories?: string[];
    currency?: string;
    image: string;
    meta?: Record<string, any>;
    path: string;
}

interface ShopStartCheckoutEventParams extends BaseEventParams {
    currency?: string;
    value?: number;
    orderId: string;
    items: {
        name: string;
        id: string;
        quantity: number;
        categories?: string[];
        productPrice: number;
        subTotal: number;
        discount: number;
        wasOnSale: boolean;
    }[];
}

interface ShopPurchaseEventParams extends BaseEventParams {
    order: ShopOrderDocument;
    orderId: string;
    currency?: string;
    value?: number;
    items: {
        name: string;
        id: string;
        quantity: number;
        productPrice: number;
        discount?: number;
        subTotal: number;
        categories?: string[];
        wasOnSale: boolean;
    }[];
}

interface AccountRegisterEventParams extends BaseEventParams {
    method: 'password' | 'google' | 'facebook';
    path: string;
    attempts: number;
}

interface AccountLoginEventParams extends BaseEventParams {
    method: 'password' | 'google' | 'facebook';
    path: string;
    attempts: number;
}

interface InteractableEventParams extends BaseEventParams {
    type: string;
    content: string;
    contentName: string;
    contentImage: string;
    contentValue: number;
    contentMeta?: Record<string, any>;
    instanceId: number;
    tags: string[];
    duration?: number;
    path: string;
    context: {
        parentTextContent: string;
        parentSelector: string[];
        parent: {
            width: number;
            height: number;
            left: number;
            top: number;
        };
        screen: {
            width: number;
            height: number;
            portrait: number;
        };
    };
}

interface InteractableClickEventParams extends InteractableEventParams {
    clickInfo: {
        altKey: number;
        ctrlKey: number;
        shiftKey: number;
        metaKey: number;
        button: number;
        x: number;
        y: number;
        //TODO track click position on element
        //TODO track click position on window
    },
}

interface PwaPromptEventParams extends BaseEventParams {
    platform: 'standard' | 'ios';
    state: 'available' | 'prompted' | 'accepted' | 'dismissed';
}

interface ToastShownEventParams extends BaseEventParams {
    content: string;
    path: string;
}


export interface TypeParams {
    appPerformance: AppPerformanceEventParams;
    appError: AppErrorEventParams;
    pageView: PageViewEventParams;
    viewContent: ViewContentEventParams;
    shopAddToCart: ShopAddToCartEventParams;
    shopStartCheckout: ShopStartCheckoutEventParams;
    shopPurchase: ShopPurchaseEventParams;
    accountRegister: AccountRegisterEventParams;
    accountLogin: AccountLoginEventParams;
    interactableView: InteractableEventParams;
    interactableClick: InteractableClickEventParams;
    pwaPrompt: PwaPromptEventParams;
    toastShown: ToastShownEventParams;
}

export type EventType = keyof TypeParams;


export const track = <T extends EventType>(event: T, params: TypeParams[T]) => {
    window.dispatchEvent(new CustomEvent('track-event', {
        composed: true,
        bubbles: true,
        detail: {
            event: event,
            params: params,
        },
    }));
};
